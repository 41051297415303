import {SET_VARIANTS} from "../constants/variantConstant"

export const setVariantsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_VARIANTS:
      return {
        variants: action.payload
      }

    default:
      return state
  }
}
