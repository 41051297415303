import React from "react"
import "./RadioButton.scss"

const RadioButton = ({label, name, value, checked, onClick}) => {
  return (
    <div id="t1-radio-button">
      <label className="radio-button">
        <input
          type="radio"
          // name={name}
          value={value}
          readOnly={true}
          checked={checked}
        />
        <span className="checkmark" onClick={onClick}></span>
        {label}
      </label>
    </div>
  )
}

export default RadioButton
