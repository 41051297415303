import axios from "axios"
import {toastMessages} from "./toastMessages"
import {
  GET_MY_ORDER_FAIL,
  GET_MY_ORDER_REQUEST,
  GET_MY_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS
} from "../constants/spsOrderGetConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const getOrders = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORDER_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const {data} = await axios.get(`${base_url}/sps-orders${filters}`, config)

    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_ORDER_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}

export const getMyOrders = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_MY_ORDER_REQUEST
    })

    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }

    const {data} = await axios.get(
      `${base_url}/sps-orders/mine${filters}`,
      config
    )

    dispatch({
      type: GET_MY_ORDER_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_MY_ORDER_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}

export const getMyManifest = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORDER_REQUEST
    })

    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }

    const {data} = await axios.get(
      `${base_url}/sps-orders/manage-my-manifest${filters}`,
      config
    )

    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_ORDER_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}
