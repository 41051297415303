import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {PRODUCT_BUILD} from "../../constants/spsProductConstant"
import "./SPSCreateProduct.scss"

export const SPSCreateProductBasics = () => {
  const dispatch = useDispatch()

  const spsProductBuild = useSelector((state) => state.spsProductBuild)
  const {product_details} = spsProductBuild

  useEffect(() => {
    dispatch({type: PRODUCT_BUILD, payload: {}})
  }, [dispatch])

  const handleBasics = (para1, para2, parameterValue) => {
    let newProductDetails = JSON.parse(JSON.stringify(product_details || {}))
    if (!newProductDetails[para1] && para2) {
      newProductDetails[para1] = {}
    }

    if (para2) newProductDetails[para1][para2] = parameterValue
    else newProductDetails[para1] = parameterValue

    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
  }

  const handleMoreImages = (imageLink) => {
    let newProductDetails = JSON.parse(JSON.stringify(product_details || {}))
    if (!newProductDetails["otherImages"]) {
      newProductDetails["otherImages"] = []
    }
    if (imageLink !== "") newProductDetails.otherImages.push(imageLink)

    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
  }

  const handleImageAction = (index, action) => {
    let newProductDetails = product_details
    let imagesList = product_details.otherImages

    if (action === "delete") imagesList.splice(index, 1)

    if (action === "left") {
      let firstIndex = index - 1
      let secondIndex = index

      let firstElement = imagesList.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = imagesList.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      imagesList.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      imagesList.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    if (action === "right") {
      let firstIndex = index
      let secondIndex = index + 1

      let firstElement = imagesList.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = imagesList.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      imagesList.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      imagesList.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    newProductDetails["otherImages"] = imagesList
    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
  }

  return (
    <div style={{}}>
      <h4>Basics</h4>
      <div className="section">
        <Form.Control
          placeholder="Store Name"
          className="frm"
          type="text"
          defaultValue={product_details?.store || ""}
          onChange={(e) => handleBasics("store", "", e.target.value)}
        ></Form.Control>
      </div>
      <div className="section">
        <Form.Control
          placeholder="En Product Name"
          className="frm"
          type="text"
          defaultValue={product_details?.name?.en || ""}
          onChange={(e) => handleBasics("name", "en", e.target.value)}
        ></Form.Control>
        <Form.Control
          placeholder="Ar Product Name"
          className="frm"
          type="text"
          defaultValue={product_details?.name?.ar || ""}
          onChange={(e) => handleBasics("name", "ar", e.target.value)}
        ></Form.Control>
      </div>
      <div className="section">
        <Form.Control
          placeholder="EGP"
          className="frm"
          type="number"
          defaultValue={product_details?.price?.EGP || ""}
          onChange={(e) => handleBasics("price", "EGP", Number(e.target.value))}
        ></Form.Control>
        <Form.Control
          placeholder="AED"
          className="frm"
          type="number"
          defaultValue={product_details?.price?.AED || ""}
          onChange={(e) => handleBasics("price", "AED", Number(e.target.value))}
        ></Form.Control>
        <Form.Control
          placeholder="Discount %"
          className="frm"
          type="number"
          defaultValue={product_details?.discount || ""}
          onChange={(e) => handleBasics("discount", "", Number(e.target.value))}
        ></Form.Control>
      </div>
      <div className="section">
        <Form.Control
          placeholder="Product Base Cost"
          className="frm"
          type="number"
          defaultValue={product_details?.PBC || ""}
          onChange={(e) => handleBasics("PBC", "", Number(e.target.value))}
        ></Form.Control>
        <Form.Control
          placeholder="Material Base Cost"
          className="frm"
          type="number"
          defaultValue={product_details?.MBC || ""}
          onChange={(e) => handleBasics("MBC", "", Number(e.target.value))}
        ></Form.Control>
      </div>
      {/*main image Section */}
      <div className="section">
        <Form.Control
          placeholder="Main Image"
          className="frm"
          type="text"
          defaultValue={product_details?.mainImage || ""}
          onChange={(e) => handleBasics("mainImage", "", e.target.value)}
        ></Form.Control>
        <img
          src={product_details?.mainImage}
          style={{margin: "5px", maxWidth: "50px"}}
        />
      </div>
      {/* End of Video Section */}

      {/*main image Section */}
      <div className="section">
        <Form.Control
          placeholder="Video Link"
          className="frm"
          type="text"
          defaultValue={product_details?.video || ""}
          onChange={(e) => handleBasics("video", "", e.target.value)}
        ></Form.Control>
      </div>
      {/* End of Video Section */}

      {/* Add More Image Section */}
      <div className="section">
        <Form.Control
          placeholder="More Image Add"
          className="frm"
          type="text"
          defaultValue=""
          onChange={(e) => handleMoreImages(e.target.value)}
        ></Form.Control>
      </div>
      {/* End Add More Image Section */}

      {/* More Images List Section */}
      <div className="section">
        {product_details?.otherImages?.map((image, index) => (
          <div className="image" key={index}>
            <img src={image} style={{margin: "5px", maxWidth: "50px"}} />
            <div style={{display: "flex"}}>
              {index !== 0 && (
                <i
                  className="fas fa-arrow-left ico"
                  onClick={() => handleImageAction(index, "left")}
                ></i>
              )}
              {index + 1 !== product_details?.otherImages?.length && (
                <i
                  className="fas fa-arrow-right ico"
                  onClick={() => handleImageAction(index, "right")}
                ></i>
              )}
              <i
                className="fas fa-trash-alt ico"
                onClick={() => handleImageAction(index, "delete")}
              ></i>
            </div>
          </div>
        ))}
      </div>
      {/* End of More Images List Section */}
      <hr className="line" />
    </div>
  )
}
