export const MCQ_CREATE_REQUEST = "MCQ_CREATE_REQUEST"
export const MCQ_CREATE_SUCCESS = "MCQ_CREATE_SUCCESS"
export const MCQ_CREATE_FAIL = "MCQ_CREATE_FAIL"

export const MCQ_GET_REQUEST = "MCQ_GET_REQUEST"
export const MCQ_GET_SUCCESS = "MCQ_GET_SUCCESS"
export const MCQ_GET_FAIL = "MCQ_GET_FAIL"

export const MCQ_UPDATE_REQUEST = "MCQ_UPDATE_REQUEST"
export const MCQ_UPDATE_SUCCESS = "MCQ_UPDATE_SUCCESS"
export const MCQ_UPDATE_FAIL = "MCQ_UPDATE_FAIL"

export const MCQ_DELETE_REQUEST = "MCQ_DELETE_REQUEST"
export const MCQ_DELETE_SUCCESS = "MCQ_DELETE_SUCCESS"
export const MCQ_DELETE_FAIL = "MCQ_DELETE_FAIL"
