import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import TimeChart from "../components/TimeChart"
import {getAnalysis} from "../actions/spsAnalysisActions"
import Loader from "../components/Loader"
import "./Analysis.scss"
import {Card} from "react-bootstrap"

const AnalysisScreen = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAnalysis(``))
  }, [dispatch])

  const analysisGet = useSelector((state) => state.analysisGet)
  const {analysisData, loading} = analysisGet

  return (
    <div className="main-content">
      <h1>Last 4 Months</h1>
      <div style={{display: "flex"}}>
        {analysisData?.closedMonths.map((month, index) => (
          <Card
            key={index}
            style={{
              width: "100%",
              padding: "0",
              margin: "3px",
              alignItems: "center"
            }}
          >
            <p style={{textAlign: "center", margin: "0"}}>
              {month._id.month}-{month._id.year}
            </p>
            <hr
              style={{
                padding: "0",
                margin: "2px",
                border: "1px red solid",
                width: "90%"
              }}
            ></hr>
            <p style={{textAlign: "center", margin: "0", fontSize: "20px"}}>
              {Math.round(month.totalAds)} Ads
            </p>
            <p style={{textAlign: "center", margin: "0", fontSize: "25px"}}>
              {month.soldItems}
            </p>
            {/* <p style={{textAlign: "center", margin: "0"}}>
              {month.totalNetItems} NetItems
            </p>
            <p style={{textAlign: "center", margin: "0"}}>
              {month.totalOrders} VOU & Fre-Ship
            </p>
            <p style={{textAlign: "center", margin: "0"}}>
              {month.totalReturnedLoss} RET-LOSS
            </p> */}
            <p style={{textAlign: "center", margin: "0", fontSize: "20px"}}>
              {Math.round(month.difference)} Prof.
            </p>
          </Card>
        ))}
      </div>
      <h1>Current</h1>
      <div xs={12}>
        {loading ? <Loader /> : <TimeChart data={analysisData} />}
      </div>
    </div>
  )
}

export default AnalysisScreen
