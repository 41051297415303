import React from "react"
import {useState} from "react"
import {Button, Card, Form} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {toastMessages} from "../actions/toastMessages"
import {validMobileRegex} from "../myRegexp"
import {useDispatch} from "react-redux"
import {login} from "../actions/userActions"

const Login = ({history}) => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const [mobile, setMobile] = useState("")
  const [password, setPassword] = useState("")

  const handleCheckout = () => {
    let pass = true

    if (!validMobileRegex.test(mobile) && pass == true) {
      pass = false
      dispatch(toastMessages(0, t("Wrong_Mobile")))
    }

    if (pass) {
      dispatch(login({mobile: mobile}, password, ""))
    }
  }
  return (
    <div className="p-0 m-0" style={{width: "100%"}}>
      <Card style={{marginTop: "5px", padding: "5px"}}>
        <Form.Label
          htmlFor="mobile"
          style={{direction: t("Dir"), textAlign: "start"}}
        >
          {t("Mobile_Number")}{" "}
          <span style={{color: "red"}}>* {t("Required")}</span>
        </Form.Label>
        <Form.Control
          style={{
            border: "1px solid grey",
            direction: t("Dir"),
            textAlign: "start"
          }}
          type="tel"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        ></Form.Control>

        <Form.Label
          htmlFor="password"
          style={{direction: t("Dir"), textAlign: "start"}}
        >
          {t("Password")} <span style={{color: "red"}}>* {t("Required")}</span>
        </Form.Label>
        <Form.Control
          style={{
            border: "1px solid grey",
            direction: t("Dir"),
            textAlign: "start"
          }}
          type="text"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></Form.Control>
        <Button
          disabled={mobile === "" || password === ""}
          className="rounded"
          variant="outline-info"
          style={{width: "100%"}}
          onClick={() => handleCheckout()}
        >
          {t("Login")}
        </Button>
      </Card>
    </div>
  )
}

export default Login
