import axios from "axios"
import {
  SLREPORT_FAIL,
  SLREPORT_REQUEST,
  SLREPORT_SUCCESS
} from "../constants/SLReportConstants"
import {toastMessages} from "./toastMessages"

export const getSLReports =
  (serviceId, reports) => async (dispatch, getState) => {
    let links = {}
    try {
      dispatch({
        type: SLREPORT_REQUEST
      })

      const config = {
        headers: {
          "Content-type": "application/json"
        }
      }
      const {data} = await axios.get(
        `http://localhost:3041/api/reports`,
        config
      )
      reports["one_month_data"] = data
      console.log(reports)
      dispatch({
        type: SLREPORT_SUCCESS,
        payload: reports
      })
    } catch (error) {
      toastMessages(0, "Connection Error")
      dispatch({
        type: SLREPORT_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
      })
    }
  }
