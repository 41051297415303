import React, {useState, useEffect, useRef} from "react"

import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import axios from "axios"

import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

import "./HomeScreen.scss"
import reviewsData from "../assets/reviews.json"
import reviewImages from "../assets/reviews2.json"
import siteMeta from "../assets/siteMeta.json"
import productDetails from "./productDetails.json"
import Reviews from "../components/homeReviews/Reviews"

import logo from "../assets/logo.png"
import thumbUp from "../assets/thumb-up.png"
import EGYPTFlag from "../assets/Flag-of-Egypt.png"
import UAEFlag from "../assets/Flag-of-Emirates.png"

import truck from "../assets/truck.png"
import {createItem} from "../actions/spsItemActions"
import {
  generateCartId,
  getCartItems,
  updateCart
} from "../actions/spsCartActions"
import Loader from "../components/Loader"
import Footer from "../components/Footer"
import {ORDER_STATUS} from "../constants/spsOrderStatusConstant"
import OfferCard from "./OfferCard"

import {MdDirectionsBike} from "react-icons/md"
import {IoIosRefresh} from "react-icons/io"

import ReactPlayer from "react-player"
import {SET_, SET_VARIANTS} from "../constants/variantConstant"
import {Box, CardHeader} from "@mui/material"

function HomeScreen2({history}) {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const [muted, setMuted] = useState(true)
  const [isVisible, setIsVisible] = useState(true)
  const addToCartRef = useRef(null)

  const checkVisibility = () => {
    if (addToCartRef.current) {
      const rect = addToCartRef.current.getBoundingClientRect()
      const isInView =
        rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left <
          (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight)
      setIsVisible(!isInView)
      console.log(isInView)
    }
  }

  // const [isVisible, setIsVisible] = useState(true)
  const videoRef = useRef(null)

  const toggleSound = () => {
    const video = videoRef.current
    video.muted = !video.muted
    setMuted(!muted)
  }

  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "EGP"
  )

  const handleCurrency = (currency) => {
    localStorage.setItem("currency", currency)
    setCurrency(currency)
  }

  const spsCartItemsGet = useSelector((state) => state.spsCartItemsGet)
  const {items, loading} = spsCartItemsGet

  const setVariants = useSelector((state) => state.setVariants)
  const {variants} = setVariants

  const [selectedProduct, setSelectedProduct] = useState(productDetails[0])
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      let cartId = (await localStorage.getItem("cartId")) || 0

      if (!cartId) {
        let trackDetails = {}
        trackDetails["userAgent"] = navigator?.userAgent || null
        // setUserAgent(navigator.userAgent)
        try {
          const response = await axios.get(
            "https://api64.ipify.org?format=json"
          )
          trackDetails["ipAddress"] = response.data.ip
          // Check if _fbc cookie exists, if not, set it to null
          const fbcCookie = await getCookie("_fbc")
          trackDetails["fbcCookieValue"] =
            typeof fbcCookie === "string" ? fbcCookie : null

          // Similar check for _fbp cookie
          const fbpCookie = await getCookie("_fbp")
          trackDetails["fbpCookieValue"] =
            typeof fbpCookie === "string" ? fbpCookie : null
          dispatch(generateCartId(trackDetails))
        } catch (error) {
          console.error("Error fetching data:", error)
        }
      }
    }

    fetchData()

    // setTimeout(() => toggleSound(), 5000)
  }, [])

  async function getCookie(name) {
    const cookies = document.cookie.split(";")
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=")
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue)
      }
    }
    return null
  }

  useEffect(() => {
    dispatch(getCartItems())

    let updateVariants = variants || {} // Initialize updateVariants to an empty object if variants is falsy

    for (let i = 0; i < productDetails.length; i++) {
      const productId = productDetails[i]._id

      // Ensure updateVariants[productId] exists, if not, initialize it
      if (!updateVariants[productId]) {
        updateVariants[productId] = {
          color: 0,
          hardness: 0
        }
      }

      // Update color and hardness properties
      updateVariants[productId].color = 0
      updateVariants[productId].hardness = 0
    }
    dispatch({
      type: SET_VARIANTS,
      payload: updateVariants
    })
    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     // setIsVisible(entry.isIntersecting)
    //     if (entry.isIntersecting) {
    //       videoRef.current.play()
    //     } else {
    //       videoRef.current.pause()
    //     }
    //   },
    //   {threshold: 0.5}
    // )
    // observer.observe(videoRef.current)

    // return () => observer.disconnect()
  }, [dispatch])

  const addToCartHandler = (path, productId, productIndex) => {
    dispatch(
      createItem({
        productId: productId,
        variant: {
          color: {
            name: productDetails[productIndex]?.variants?.colors[
              variants[productId].color
            ].name,
            image:
              productDetails[productIndex].variants.colors[
                variants[productId].color
              ].image
          },
          hardness: {
            name: productDetails[productIndex]?.variants?.hardness[
              variants[productId].hardness
            ].name
          }
        },
        quantity: 1
      })
    )
    history.push({pathname: path})
    dispatch({
      type: ORDER_STATUS,
      payload: "cart"
    })
  }

  const cartClickHandler = (path) => {
    history.push({pathname: path})
    dispatch({
      type: ORDER_STATUS,
      payload: "cart"
    })
  }

  const handleSelectedColor = (productId, index) => {
    // Create a copy of the variants array
    let updateVariants = {...variants}

    // Create a copy of the variant object to modify
    let updatedVariant = {...updateVariants[productId]}

    // Update the hardness property of the copied variant object
    updatedVariant.color = index

    // Update the copy of variants array with the modified variant object
    updateVariants[productId] = updatedVariant

    // Dispatch the action with the updated variants array
    dispatch({
      type: SET_VARIANTS,
      payload: updateVariants
    })
  }

  const handleSelectedHardness = (productId, index) => {
    // Create a copy of the variants array
    let updateVariants = {...variants}

    // Create a copy of the variant object to modify
    let updatedVariant = {...updateVariants[productId]}

    // Update the hardness property of the copied variant object
    updatedVariant.hardness = index

    // Update the copy of variants array with the modified variant object
    updateVariants[productId] = updatedVariant

    // Dispatch the action with the updated variants array
    dispatch({
      type: SET_VARIANTS,
      payload: updateVariants
    })
  }
  return (
    <div>
      <ErrorModal />
      <SuccessModal />
      <div id="home-screen">
        <div className="logo-lang">
          <Row
            className="p-0 m-0 justify-content-between"
            style={{backgroundColor: "orange", color: "white", width: "100%"}}
          >
            <Col
              className="p-0 mr-1"
              style={{
                justifyContent: "left",
                maxWidth: "25%"
              }}
            >
              <div
                className="country"
                style={{
                  border: currency === "AED" ? "1px solid var(--Primary)" : "0"
                }}
                // onClick={() => handleCurrency("AED")}
              >
                <img
                  src={UAEFlag}
                  style={{height: "2.5vw", margin: "3px", maxHeight: "25px"}}
                />
                {/* <p style={{padding: "0", margin: "0"}}>{t("UAE")}</p> */}
              </div>
              <div className="countries">
                <div
                  className="country"
                  style={{
                    border:
                      currency === "EGP" ? "1px solid var(--Primary)" : "0"
                  }}
                  onClick={() => handleCurrency("EGP")}
                >
                  <img
                    src={EGYPTFlag}
                    style={{height: "2.5vw", margin: "3px", maxHeight: "25px"}}
                  />
                  {/* <p style={{padding: "0", margin: "0"}}>{t("EGYPT")}</p> */}
                </div>
              </div>
            </Col>
            <Col style={{direction: t("Dir")}}>
              <Row style={{fontSize: "0.9rem", color: "var(--Primary)"}}>
                {siteMeta.freeShippingFromItemX === 1 ? (
                  t("Free_Shipping")
                ) : (
                  <div className="deal" style={{fontSize: "0.65rem"}}>
                    {/* {t("Offer")} */}
                    {t("Buy_X_Get_Free_Shipping").replaceAll(
                      "[X]",
                      t(siteMeta.freeShippingFromItemX)
                    )}
                  </div>
                )}
              </Row>
              {/* <Row style={{fontSize: "0.6rem", color: "white"}}>
                {siteMeta?.coupon_value > 0 &&
                  t("First_Order_Deal").replaceAll(
                    "[coupon_value]",
                    siteMeta.coupon_value
                  )}
              </Row> */}
              {/* <Row style={{fontSize: "0.5rem", color: "white", padding: "3px"}}>
                {t("Made_In")}
              </Row> */}
            </Col>
            <Col
              className="p-0 mr-1"
              style={{
                justifyContent: "right",
                maxWidth: "25%"
              }}
            >
              {i18n.language === "ar" && (
                <div
                  className="lang-button"
                  onClick={() => {
                    localStorage.setItem("lang", "en")
                    localStorage.setItem("dir", "ltr")
                    updateCart({lang: "en"})
                    i18n.changeLanguage("en")
                  }}
                >
                  English
                </div>
              )}
              {i18n.language === "en" && (
                <div
                  className="lang-button"
                  onClick={() => {
                    localStorage.setItem("lang", "ar")
                    localStorage.setItem("dir", "rtl")
                    updateCart({lang: "ar"})
                    i18n.changeLanguage("ar")
                  }}
                >
                  عربي
                </div>
              )}
            </Col>
          </Row>
          <div
            style={{
              padding: "0",
              margin: "0",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                width: "50%",
                padding: "10px"
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "30%",
                  minWidth: "100px"
                }}
              />
            </div>
            <div
              style={{width: "50%", textAlign: "end", padding: "0 10px"}}
              onClick={() => cartClickHandler("/checkout")}
            >
              <div className="cart-container">
                <div className={`cart-icon ${loading ? "rotate" : ""}`}>🛒</div>
                {!loading && (
                  <div className="badge">
                    {items?.reduce((acc, item) => acc + item.quantity, 0) || 0}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "100px 0 0 0"
          }}
        >
          <Row className="margin-top"></Row>
          <div className="container" style={{direction: t("Dir")}}>
            <Row>
              <p
                style={{
                  color: "var(--Primary)",
                  fontSize: "2.5rem"
                }}
              >
                {selectedProduct.catchy1.title[t("lang")]}
              </p>
            </Row>
            <div style={{backgroundColor: "grey", width: "95%"}}>
              <ReactPlayer
                url="https://youtu.be/D-T1AwtYocE"
                playing={true}
                controls={false}
                muted={false} // Mute the video
                width="100%"
                // height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      controls: 0, // Hide YouTube controls
                      modestbranding: 1,
                      fs: 0,
                      iv_load_policy: 3,
                      showinfo: 0,
                      rel: 0,
                      autoplay: 1
                    }
                  }
                }}
              />
            </div>
            {/* {selectedProduct?.video && (
              <div style={{position: "relative", textAlign: "center"}}>
                <video
                  src={selectedProduct.video}
                  ref={videoRef}
                  autoPlay
                  muted
                  loop={true}
                  style={{maxWidth: "1000px", width: "100%", padding: "5px"}}
                />
                <i
                  className={muted ? `fas fa-volume-mute` : `fas fa-volume-up`}
                  style={{
                    color: "white",
                    fontSize: "24px",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    toggleSound()
                  }}
                />
              </div>
            )} */}
            {/* <Row
              className="p-0 m-0"
              style={{textAlign: "center", borderRadius: "10%"}}
            >
              <img
                src={selectedProduct.catchy1.image}
                style={{width: "100%"}}
              />
            </Row> */}
            <Row>
              <p
                style={{
                  textAlign: "start",
                  marginTop: "5px",
                  fontSize: "1.5rem"
                }}
              >
                <span
                  style={{
                    color: "var(--Orange-color)",
                    fontSize: "1.8rem"
                  }}
                >
                  {selectedProduct.catchy1.Product_Description1[t("lang")]}
                </span>{" "}
                {selectedProduct.catchy1.Product_Description2[t("lang")]}
              </p>
            </Row>
            <Row>
              <img
                src="https://i.postimg.cc/nhBJ82yW/Why-From-Edited.gif"
                style={{width: "100%", borderRadius: "5%"}}
              />
            </Row>
            <div className="offers-section">
              {productDetails.map((product, productIndex) => (
                <Card
                  key={productIndex}
                  className="card"
                  style={{paddingBottom: "1rem", paddingTop: "1rem"}}
                >
                  <h1 style={{fontSize: "2.8rem", color: "var(--Primary)"}}>
                    {product.offerName[t("lang")]}
                  </h1>
                  {product?.OfferNote?.hint[t("lang")] && (
                    <div
                      className="offer-note"
                      style={{
                        border: "1px dashed green",
                        margin: "10px",
                        padding: "5px",
                        borderRadius: "10px"
                      }}
                    >
                      <h2
                        style={{
                          color: "var(--Orange-color)"
                        }}
                      >
                        {product?.OfferNote?.hint[t("lang")]}{" "}
                        <span>
                          {" "}
                          <img
                            src={product.OfferNote.image}
                            style={{width: "3.2rem", borderRadius: "5%"}}
                          />
                        </span>
                      </h2>
                    </div>
                  )}
                  <div style={{padding: "1.5rem 0"}}>
                    <img
                      src={
                        variants && variants[product?._id]
                          ? product.variants.colors[
                              variants[product?._id]?.color
                            ].image
                          : product.variants.colors[0].image
                      }
                      style={{height: "14rem", borderRadius: "5%"}}
                    />
                  </div>
                  <h3> {t("Select_Your_Color")}</h3>
                  <Row style={{alignItems: "center"}} className="p-0 m-0">
                    {product.variants.colors.map(
                      (color, index) =>
                        color.active && (
                          <Col
                            className={
                              (
                                variants
                                  ? variants[product?._id].color === index
                                  : 0
                              )
                                ? "module card"
                                : ""
                            }
                            key={index}
                            style={{
                              border: (
                                variants
                                  ? variants[product?._id].color === index
                                  : 0
                              )
                                ? "1px solid var(--Orange-color)"
                                : "1px solid var(--Secondary)",
                              borderRadius: "5px",
                              margin: "6px",
                              height: "100%",
                              maxWidth: "25%"
                            }}
                          >
                            <img
                              onClick={() =>
                                handleSelectedColor(product?._id, index)
                              }
                              src={color.image}
                              style={{
                                width: "100%",
                                // border: "1px ",
                                borderRadius: "5%"
                              }}
                            />
                          </Col>
                        )
                    )}
                  </Row>
                  <Box margin={1}></Box>
                  <h3> {t("Select_Your_Hardness")}</h3>
                  <Row style={{alignItems: "center"}} className="p-0 m-0">
                    {product.variants?.hardness?.map((type, index) => (
                      <Col
                        className={
                          (
                            variants
                              ? variants[product?._id].hardness === index
                              : 0
                          )
                            ? "module card"
                            : ""
                        }
                        key={index}
                        style={{
                          border: (
                            variants
                              ? variants[product?._id]?.hardness === index
                              : 0
                          )
                            ? "1px solid var(--Orange-color)"
                            : "1px solid var(--Secondary)",
                          borderRadius: "5px",
                          margin: "6px",
                          height: "100%",
                          maxWidth: "40%"
                        }}
                      >
                        <div
                          onClick={() =>
                            handleSelectedHardness(product?._id, index)
                          }
                          // style={{width: "100%"}}
                        >
                          <h3>{type?.name[t("lang")]}</h3>
                          <p style={{fontSize: "0.8em"}}>
                            {type?.description[t("lang")]}
                          </p>
                        </div>
                        {/* <img
                          
                          style={{
                            width: "100%",
                            // border: "1px ",
                            borderRadius: "5%"
                          }}
                        /> */}
                      </Col>
                    ))}
                  </Row>
                  <Box margin={1}></Box>
                  <Row className="p-0 m-0">
                    <Col sm={12}>
                      <h4 style={{color: "var(--Primary)"}}>
                        {product.discount * 100}% {t("Off")}
                        {/* +{" "}
                      {t("Free_Cover")} */}
                      </h4>
                      <h1 style={{color: "green"}}>
                        {t(currency)} {product.price[currency]}
                        <span style={{color: "red", fontSize: "20px"}}>
                          {" "}
                          <del>
                            {t(currency)}
                            {(
                              product.price[currency] *
                              (1 + product.discount)
                            ).toFixed(0)}
                          </del>
                        </span>
                      </h1>
                    </Col>
                    <Col sm={12}>
                      <Button
                        ref={addToCartRef}
                        id="initCheckoutButton"
                        className="rounded"
                        style={{
                          width: "100%",
                          backgroundColor: "var(--Primary)"
                        }}
                        onClick={() =>
                          addToCartHandler(
                            "/checkout",
                            product._id,
                            productIndex
                          )
                        }
                      >
                        <div className="deal">{t("Buy_Now")}</div>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              ))}
            </div>

            <p style={{color: "var(--Primary)", fontSize: "1.5rem"}}>
              {selectedProduct.catchy2.title[t("lang")]}
            </p>

            <p style={{textAlign: "start"}}>
              <span
                style={{
                  color: "var(--Orange-color)",
                  fontSize: "1rem"
                }}
              >
                {selectedProduct?.catchy2?.Product_Description1[t("lang")]}
              </span>
              {selectedProduct?.catchy2?.Product_Description2[t("lang")]}
            </p>

            <img
              src={selectedProduct?.catchy2?.image}
              style={{width: "100%"}}
            />

            <h5>{t("Customer_Reviews")} </h5>
            <div style={{display: "grid"}}>
              {reviewImages?.map((reviewImage, index) => (
                <img key={index} src={reviewImage} style={{width: "100%"}} />
              ))}
            </div>
            {/* {reviewsData?.length > 0 && (
              <Reviews
                reviews={reviewsData.reverse()}
                title={t("Customer_Reviews")}
              />
            )} */}

            <p
              style={{
                color: "var(--Primary)",
                fontSize: "1.5rem",
                marginTop: "1.3rem"
              }}
            >
              {/* {t("More_Details")} */}
              {t("Product_Features")}
            </p>

            {/* <Row
              className="p-0 m-0"
              style={{
                alignItems: "center"
              }}
            >
              <Col className="p-0 m-0" md={6}>
                <img
                  src={selectedProduct.bulletPoints.image}
                  style={{width: "100%"}}
                />
              </Col>
              <Col
                style={{
                  fontSize: "1.1rem",
                  direction: t("Dir"),
                  textAlign: "start"
                }}
                className="p-0 m-0"
                md={6}
              >
                <ul style={{margin: "0", padding: "30px"}}>
                  {selectedProduct?.bulletPoints?.list[t("lang")]?.map(
                    (bulletPoint, index) => (
                      <li key={index}>{bulletPoint}</li>
                    )
                  )}
                </ul>
              </Col>
            </Row> */}

            {/* <p
              style={{
                color: "var(--Primary)",
                fontSize: "1.15rem"
              }}
            >
              {selectedProduct?.name[t("lang")]}
            </p> */}

            {selectedProduct?.moreDetails?.map((record, index) => (
              <Row
                key={index}
                className="p-0 m-0"
                style={{alignItems: "center"}}
              >
                <Col className="p-1 m-0" md={6}>
                  {record?.images?.map((image, index) => (
                    <img key={index} src={image} style={{width: "50%"}} />
                  ))}
                </Col>
                <Col
                  className="p-1 m-0"
                  md={record?.images?.length ? 6 : 12}
                  style={{textAlign: "start"}}
                >
                  <p
                    style={{
                      color: "var(--Secondary)",
                      fontSize: "1.5rem"
                    }}
                  >
                    {record?.title ? record?.title[t("lang")] : ""}
                  </p>
                  {record?.paragraphs
                    ? record?.paragraphs[t("lang")]?.map((paragraph, index) => (
                        <p
                          style={{
                            color: "var(--Primary)",
                            fontSize: "1.1rem"
                          }}
                          key={index}
                        >
                          {paragraph}
                        </p>
                      ))
                    : ""}
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default HomeScreen2
