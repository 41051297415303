import React from "react"
import {useTranslation} from "react-i18next"

import siteMeta from "../assets/siteMeta.json"
import returnAndRefundPolicy from "../assets/returnAndRefundPolicy.json"

const ReturnPolicy = () => {
  const [t, i18n] = useTranslation()

  const replaceLinks = (text) => {
    const regex = /\[([^\]]+)\]/g // Regular expression to find [xyz]-like patterns
    const parts = [] // Array to hold strings and JSX elements

    let lastIndex = 0 // Index up to which the original text has been processed

    // Iterate over all [xyz]-like patterns in the text
    let match
    while ((match = regex.exec(text)) !== null) {
      // Add the text before the pattern to the array
      parts.push(text.slice(lastIndex, match.index))

      // Create a JSX element for the link and add it to the array
      const variable = match[1]
      parts.push(
        <a
          key={match.index}
          href={`/#/${variable.replaceAll("_", "-").toLowerCase()}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{color: "orange"}}
        >
          {t(variable)}
        </a>
      )

      // Update lastIndex to the end of the match
      lastIndex = regex.lastIndex
    }

    // Add any remaining text after the last pattern to the array
    parts.push(text.slice(lastIndex))

    return parts // Return the array
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        margin: "3rem 0",
        padding: "0 3rem",
        width: "100%"
      }}
    >
      <h1>
        {t("Shipping_Policy")} {t("For")}
        {siteMeta.siteName[t("lang")]}
      </h1>

      <p>
        {t("Last Updated")}: {returnAndRefundPolicy.lastUpdate}
      </p>
      {/* <div className="row" style={{direction: t("Dir"), textAlign: "start"}}>
        {returnAndRefundPolicy.terms[t("lang")].map((term, index) => (
          <div key={index}>
            <h3>{term.title}</h3>

            {term.paragraphs.map((value, index) => (
              <p key={index}>
                {replaceLinks(
                  value
                    .replaceAll("[SiteName]", siteMeta.siteName[t("lang")])
                    .replaceAll("[Website]", siteMeta.website)
                )}
              </p>
            ))}

            <ul>
              {term.list.map((point, index) => (
                <li key={index}>
                  {replaceLinks(
                    point
                      .replaceAll("[SiteName]", siteMeta.siteName[t("lang")])
                      .replaceAll("[Website]", siteMeta.website)
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div> */}
    </div>
  )
}

export default ReturnPolicy
