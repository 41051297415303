import {SLIDE_ROTATION_UPDATE} from "../constants/slideRotationConstants"

export const slideRotationReducer = (state = {}, action) => {
  switch (action.type) {
    case SLIDE_ROTATION_UPDATE:
      return {
        current: action.payload
      }

    default:
      return state
  }
}
