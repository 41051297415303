import {
  MCQ_CREATE_FAIL,
  MCQ_CREATE_REQUEST,
  MCQ_CREATE_SUCCESS,
  MCQ_DELETE_FAIL,
  MCQ_DELETE_REQUEST,
  MCQ_DELETE_SUCCESS,
  MCQ_GET_FAIL,
  MCQ_GET_REQUEST,
  MCQ_GET_SUCCESS,
  MCQ_UPDATE_FAIL,
  MCQ_UPDATE_REQUEST,
  MCQ_UPDATE_SUCCESS
} from "../constants/MCQConstants"

export const MCQCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MCQ_CREATE_REQUEST:
      return {...state, loading: true}

    case MCQ_CREATE_SUCCESS:
      return {...state, loading: false, data: action.payload}

    case MCQ_CREATE_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}

export const MCQGetReducer = (state = {}, action) => {
  switch (action.type) {
    case MCQ_GET_REQUEST:
      return {...state, loading: true}

    case MCQ_GET_SUCCESS:
      return {loading: false, MCQs: action.payload}

    case MCQ_GET_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}

export const MCQUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MCQ_UPDATE_REQUEST:
      return {...state, loading: true}

    case MCQ_UPDATE_SUCCESS:
      return {...state, loading: false, data: action.payload}

    case MCQ_UPDATE_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}

export const MCQDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MCQ_DELETE_REQUEST:
      return {...state, loading: true, deleteIndex: action.index}

    case MCQ_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case MCQ_DELETE_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}
