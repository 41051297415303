import {
  CREATE_ITEM_FAIL,
  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  DECREMENT_ITEM_FAIL,
  DECREMENT_ITEM_REQUEST,
  DECREMENT_ITEM_SUCCESS,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  INCREMENT_ITEM_FAIL,
  INCREMENT_ITEM_REQUEST,
  INCREMENT_ITEM_SUCCESS
} from "../constants/spsItemConstant"

export const spsItemCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ITEM_REQUEST:
      return {loading: true}

    case CREATE_ITEM_SUCCESS:
      return {loading: false}

    case CREATE_ITEM_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsItemIncrementReducer = (state = {}, action) => {
  switch (action.type) {
    case INCREMENT_ITEM_REQUEST:
      return {loading: true}

    case INCREMENT_ITEM_SUCCESS:
      return {loading: false}

    case INCREMENT_ITEM_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsItemDecrementReducer = (state = {}, action) => {
  switch (action.type) {
    case DECREMENT_ITEM_REQUEST:
      return {loading: true}

    case DECREMENT_ITEM_SUCCESS:
      return {loading: false}

    case DECREMENT_ITEM_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsItemDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ITEM_REQUEST:
      return {loading: true}

    case DELETE_ITEM_SUCCESS:
      return {loading: false}

    case DELETE_ITEM_FAIL:
      return {loading: false}

    default:
      return state
  }
}
