import React from "react"
import {useLocation} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import Loader from "../components/Loader"
import {getMyManifest, getOrders} from "../actions/spsOrderGetActions"
import {useEffect} from "react"
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap"
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {HiOutlineMinusCircle, HiOutlinePlusCircle} from "react-icons/hi"
import {FaWhatsappSquare, FaSms} from "react-icons/fa"
import "./ManageMyManifest.scss"
import {
  addComment,
  delivered,
  toPick,
  updateOrder
} from "../actions/spsOrderUpdateActions"
import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

import tabs from "../assets/courier tabs.json"

const ManageMyManifest = () => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const manifestId = searchParams.get("manifestId")

  const [fullName, setFullName] = useState("")
  const [mobile, setMobile] = useState("")
  const [anotherMobile, setAnotherMobile] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("")
  const [floor, setFloor] = useState("")
  const [flat, setFlat] = useState("")
  const [notes, setNotes] = useState("")
  const [quantity, setQuantity] = useState(0)

  const [status, setStatus] = useState("")

  const [modalStatus, setModalStatus] = useState(false)
  const [deliveredModalStatus, setDeliveredModalStatus] = useState(false)
  const [toPickModalStatus, setToPickModalStatus] = useState(false)
  const [currentOrder, setCurrentOrder] = useState("")
  const [orderIndex, setOrderIndex] = useState("")

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [selectedCourier, setSelectedCourier] = useState("")
  useEffect(() => {
    dispatch(
      getMyManifest(`?status=${selectedTab.filter}&manifestId=${manifestId}`)
    )
  }, [dispatch])

  const spsOrderGet = useSelector((state) => state.spsOrderGet)
  const {loading, orders} = spsOrderGet

  const handleModal = (order, index) => {
    setOrderIndex(index)
    setCurrentOrder(order)
    setModalStatus(true)
    setNotes("")
  }

  const handleDeliveredModal = (order, index) => {
    setOrderIndex(index)
    setCurrentOrder(order)
    setDeliveredModalStatus(true)
  }

  const handleToPickModal = (order, index) => {
    setOrderIndex(index)
    setCurrentOrder(order)
    setToPickModalStatus(true)
  }

  const manageQuantity = (plusOrMinus) => {
    let currentQuentity = Number(quantity)
    if (plusOrMinus == "+") {
      currentQuentity++
    }
    if (plusOrMinus == "-")
      if (quantity != 1) {
        currentQuentity--
      }
    localStorage.setItem("quantity", currentQuentity)
    setQuantity(currentQuentity)
  }

  const handleFilter = (filter) => {
    setSelectedTab(filter)
    dispatch(getMyManifest(`?status=${filter.filter}&manifestId=${manifestId}`))
  }

  const handleAddNote = (currentOrder, orderIndex, note) => {
    dispatch(
      addComment(
        currentOrder._id,
        {note: note, shipperId: manifestId},
        orderIndex,
        orders
      )
    )
    setModalStatus(false)
  }

  const handleDelivered = (currentOrder, orderIndex) => {
    dispatch(delivered(currentOrder._id, orderIndex, orders))
    setDeliveredModalStatus(false)
  }

  const handleToPick = (currentOrder, orderIndex) => {
    dispatch(toPick(currentOrder._id, orderIndex, orders))
    setToPickModalStatus(false)
  }

  const handleCallsCounter = (orderId, orderIndex) => {
    dispatch(updateOrder(orderId, {callsCounter: 1}, orderIndex, orders))
  }

  const handleWhatsAppClick = (
    orderId,
    orderIndex,
    clientName,
    mobile,
    callsCounter
  ) => {
    const url = `https://api.whatsapp.com/send?phone=+2${mobile}&text=${encodeURIComponent(
      `اهلا ${clientName}, معاك مندوب الشحن لتوصيل المخده الطبيه بين الرجلين ممكن ارسال الوكيشن لسهولة الوصول و المعاد المناسب لحضرتك للاستلام`
    )}`
    window.open(url, "_blank")
  }

  const handleMessageClick = (
    orderId,
    orderIndex,
    clientName,
    mobile,
    callsCounter
  ) => {
    const url = `sms:${mobile}?body=${encodeURIComponent(
      `اهلا ${clientName}, معاك مندوب الشحن لتوصيل المخده الطبيه بين الرجلين ممكن ارسال الوكيشن لسهولة الوصول و المعاد المناسب لحضرتك للاستلام`
    )}`
    window.open(url, "_blank")
  }
  return (
    <div id="sps-manage-to-ship">
      <ErrorModal />
      <SuccessModal />
      <Modal
        show={modalStatus}
        onHide={() => setModalStatus(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Card style={{marginTop: "5px", padding: "5px"}}>
            <Form.Label
              htmlFor="notes"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Notes")}
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              as="textarea" // Use the textarea element
              rows={2} // Specify the number of rows to display
              type="text"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            ></Form.Control>

            <Button
              className="p-1 mt-1 rounded"
              onClick={() => handleAddNote(currentOrder, orderIndex, notes)}
            >
              أضافة
            </Button>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        show={deliveredModalStatus}
        onHide={() => setDeliveredModalStatus(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Card style={{marginTop: "5px", padding: "5px"}}>
            <div
              className="m-0 p-5"
              style={{
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "red"
              }}
            >{`هل قمت بتوصيل أوردر ${currentOrder.fullName}`}</div>

            <Button
              className="p-1 mt-1 rounded"
              onClick={() => handleDelivered(currentOrder, orderIndex)}
            >
              تمام تم التوصيل
            </Button>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        show={toPickModalStatus}
        onHide={() => setToPickModalStatus(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Card style={{marginTop: "5px", padding: "5px"}}>
            <div
              className="m-0 p-5"
              style={{
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "red"
              }}
            >{`هل قمت بالتأكيد مع ${currentOrder.fullName}`}</div>

            <Button
              className="p-1 mt-1 rounded"
              onClick={() => handleToPick(currentOrder, orderIndex)}
            >
              تمام تم التأكيد
            </Button>
          </Card>
        </Modal.Body>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <Row className="p-0 m-0">
          <div className="status-bar">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className="status-tag"
                onClick={() => handleFilter(tab)}
                style={{
                  backgroundColor:
                    selectedTab.name === tab.name ? "goldenrod" : "white"
                }}
              >
                {tab.name}
              </div>
            ))}
          </div>
          {orders?.map((order, index) => (
            <Col
              key={index}
              className="p-0 m-0"
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
            >
              <div
                className="card"
                style={{
                  padding: "5px",
                  margin: "3px",
                  backgroundColor:
                    orders[index]?.mobile === orders[index + 1]?.mobile
                      ? "red"
                      : ""
                }}
              >
                <Row className="p-0 m-0">
                  <Col
                    className="p-0 m-0"
                    xs={12}
                    style={{
                      fontSize: "10px",
                      color: "orange",
                      backgroundColor: "cyan"
                    }}
                  >
                    {order?.shipperNotes?.length > 0
                      ? order?.shipperNotes[order?.shipperNotes?.length - 1]
                          .note
                      : order.notes}
                  </Col>

                  <Col className="p-0 m-0" xs={6} style={{fontSize: "12px"}}>
                    {order.fullName}
                  </Col>
                  <Col xs={6} style={{fontSize: "12px"}}>
                    {order.region}
                  </Col>

                  <Col xs={12} style={{fontSize: "10px"}}>
                    {order.address}
                  </Col>
                  <hr
                    style={{
                      color: "green",
                      height: "1px",
                      backgroundColor: "green",
                      padding: "0",
                      marginTop: "10px"
                    }}
                  />
                  <Col className="p-0 m-0" xs={6} style={{fontSize: "12px"}}>
                    {order.mobile}
                  </Col>
                  <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                    <FaWhatsappSquare
                      onClick={() =>
                        handleWhatsAppClick(
                          order._id,
                          index,
                          order.fullName,
                          order.mobile,
                          order.callsCounter
                        )
                      }
                    />
                  </Col>
                  <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                    <FaSms
                      className="bi bi-telephone-fill"
                      onClick={() =>
                        handleMessageClick(
                          order._id,
                          index,
                          order.fullName,
                          order.mobile,
                          order.callsCounter
                        )
                      }
                    />
                  </Col>
                  <Col className="p-0 m-0" xs={2}>
                    <a href={`tel:${order.mobile}`}>
                      <i
                        className="bi bi-telephone-fill"
                        style={{
                          fontSize: "14px",
                          color: "green",
                          verticalAlign: "middle"
                        }}
                      ></i>
                    </a>
                  </Col>
                  {order?.anotherMobile ? (
                    <>
                      <hr
                        style={{
                          color: "white",
                          height: "3px",
                          padding: "0",
                          marginTop: "0"
                        }}
                      />
                      <Col
                        className="p-0 m-0"
                        xs={6}
                        style={{fontSize: "12px"}}
                      >
                        {order.anotherMobile}
                      </Col>
                      <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                        <FaWhatsappSquare
                          onClick={() =>
                            handleWhatsAppClick(
                              order._id,
                              index,
                              order.fullName,
                              order.anotherMobile,
                              order.callsCounter
                            )
                          }
                        />
                      </Col>
                      <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                        <FaSms
                          className="bi bi-telephone-fill"
                          onClick={() =>
                            handleMessageClick(
                              order._id,
                              index,
                              order.fullName,
                              order.anotherMobile,
                              order.callsCounter
                            )
                          }
                        />
                      </Col>
                      <Col className="p-0 m-0" xs={2}>
                        <a href={`tel:${order.anotherMobile}`}>
                          <i
                            className="bi bi-telephone-fill"
                            style={{
                              fontSize: "14px",
                              color: "green",
                              verticalAlign: "middle"
                            }}
                          ></i>
                        </a>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <hr
                    style={{
                      color: "green",
                      height: "1px",
                      backgroundColor: "green",
                      padding: "0",
                      marginTop: "10px"
                    }}
                  />

                  <Col
                    xs={3}
                    style={{
                      fontSize: order?.quantity > 1 ? "18px" : "12px",
                      backgroundColor: order?.quantity > 1 ? "orange" : "",
                      color: order?.quantity > 1 ? "white" : "",
                      borderRadius: "10px"
                    }}
                  >
                    {order.quantity}
                  </Col>

                  <Col xs={3} style={{fontSize: "12px"}}>
                    {order?.items?.reduce(
                      (acc, item) => acc + item.quantity * item.price,
                      0
                    ) +
                      (order.shippingFee - (order?.shippingFeeDiscount || 0)) -
                      (order?.couponValue || 0)}
                  </Col>

                  {selectedTab.filter === "TOSHIP" && (
                    <Row className="p-0 m-2">
                      <Col className="p-0 m-0" xs={3}>
                        <Button
                          variant="success"
                          className="p-1 m-0 rounded"
                          onClick={() => handleToPickModal(order, index)}
                        >
                          تمام
                        </Button>
                      </Col>
                      <Col className="p-0 m-0" xs={3}>
                        <Button
                          variant="danger"
                          className="p-1 m-0 rounded"
                          onClick={() => handleAddNote(order, index, "مش بيرد")}
                        >
                          مش بيرد
                        </Button>
                      </Col>
                      <Col className="p-0 m-0" xs={6}>
                        <Button
                          variant="info"
                          className="p-1 m-0 rounded"
                          onClick={() => handleModal(order, index)}
                        >
                          اضافة ملحوظة
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {selectedTab.filter === "TOPICK" && (
                    <Row className="p-0 m-2">
                      <Col className="p-0 m-0">
                        <Button variant="success" className="p-1 m-0 rounded">
                          NA تأجيل استلام المخزن
                        </Button>
                      </Col>

                      <Col className="p-0 m-0">
                        <Button
                          variant="info"
                          className="p-1 m-0 rounded"
                          onClick={() => handleModal(order, index)}
                        >
                          اضافة ملحوظة
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {selectedTab.filter === "SHIPPED" && (
                    <Row className="p-0 m-2">
                      <Col className="p-0 m-0" xs={4}>
                        <Button
                          style={{fontSize: "8px"}}
                          variant="success"
                          className="p-1 m-0 rounded"
                          onClick={() => handleDeliveredModal(order, index)}
                        >
                          تم التوصيل
                        </Button>
                      </Col>
                      <Col className="p-0 m-0" xs={4}>
                        <Button
                          style={{fontSize: "8px"}}
                          variant="danger"
                          className="p-1 m-0 rounded"
                          onClick={() =>
                            handleAddNote(order, index, "رفض الاستلام")
                          }
                        >
                          رفض الاستلام
                        </Button>
                      </Col>
                      <Col className="p-0 m-0" xs={4}>
                        <Button
                          style={{fontSize: "8px"}}
                          variant="info"
                          className="p-1 m-0 rounded"
                          onClick={() => handleModal(order, index)}
                        >
                          اضافة ملحوظة
                        </Button>
                      </Col>
                    </Row>
                  )}

                  <Col className="p-0 m-0" xs={6} style={{fontSize: "8px"}}>
                    {order.createdAt}
                  </Col>
                  <Col className="p-0 m-0" xs={6} style={{fontSize: "8px"}}>
                    {order._id}
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}

export default ManageMyManifest
