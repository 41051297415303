import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch, useSelector} from "react-redux"
import Loader from "../Loader"
import {Card} from "react-bootstrap"
import "./SPSPreparation.scss"
const SPSPreparation = () => {
  const [t, i18n] = useTranslation()

  const spsMyOrderGet = useSelector((state) => state.spsMyOrderGet)
  const {loading, myOrders} = spsMyOrderGet

  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "EGP"
  )

  const spsOrderStatus = useSelector((state) => state.spsOrderStatus)
  const {status} = spsOrderStatus

  return (
    <div id="SPS-prep">
      {loading ? (
        <Loader />
      ) : myOrders?.length > 0 ? (
        myOrders.map((order, index) => (
          <Card
            key={index}
            style={{
              boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.5)",
              margin: "10px 0",
              padding: "5px",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div className="title">{t("Status")}</div>
            <p className="para" style={{fontSize: "0.9em", color: "black"}}>
              {t(order.status)}
            </p>
            <p className="para">{t(`${order.status}_Description`)}</p>

            <hr className="line" />

            <div className="title">{t("Will_Ship_To")}</div>
            <p className="para">{order.fullName}</p>
            <p className="para">
              {order.address} {order.region} {order.city}
            </p>
            <p className="para">
              {t("Main_Mobile")}: {order.mobile}
            </p>
            {order.anotherMobile && (
              <p className="para">
                {t("Another_Mobile")}: {order.anotherMobile}
              </p>
            )}

            <hr className="line" />

            <div className="title">{t("Total_To_Pay")}</div>
            <p
              style={{
                color: "black",
                margin: "0",
                fontSize: "15px",
                padding: "0"
              }}
            >
              {t(currency)}{" "}
              <span>
                {order?.items?.reduce(
                  (acc, item) => acc + item.quantity * item.price,
                  0
                ) -
                  (order?.couponValue || 0) +
                  (order?.shippingFee || 0) -
                  (order?.shippingFeeDiscount || 0)}
              </span>
            </p>

            <hr className="line" />

            <p className="title">{t("Items_You_Receive")}</p>
            {order.items.map((item, index) => (
              <div key={`${order._id}_${index}`} style={{textAlign: "center"}}>
                <div
                  style={{
                    direction: t("Dir"),
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={
                      item?.variant?.color
                        ? item.variant.color.image
                        : item.image
                    }
                    style={{
                      width: "30px",
                      height: "30px"
                    }}
                  />
                  <div style={{padding: "0 20px"}}>
                    <p
                      style={{
                        margin: "0",
                        padding: "0",
                        fontSize: "12px",
                        textAlign: "start"
                      }}
                    >
                      {item.name[t("lang")]}
                    </p>
                    <p
                      style={{
                        margin: "0",
                        padding: "0",
                        fontSize: "12px",
                        textAlign: "start"
                      }}
                    >
                      {t("Quantity")}: {item.quantity}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            {/* <hr className="line" /> */}
            {/* <a
              href={`https://api.whatsapp.com/send?phone=+2${
                siteMeta.mobile
              }&text=${encodeURIComponent(
                `محتاج أستفسر عن اوردر برقم تليفون ${order.mobile} \n I have an inquiry about the order associated with mobile number ${order.mobile}.`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              // className={`btn btn-success ${isValidMobile ? "" : "disabled"}`}
              style={{
                // padding: "10px",
                color: "green",
                fontSize: "12px",
                textAlign: "center"
                // borderRadius: "5px"
              }}
            >
              {t("Send_Request")} <i className="fa fa-whatsapp"></i>
            </a> */}
          </Card>
        ))
      ) : (
        <div style={{textAlign: "center", marginTop: "100px"}}>
          <h4>{t("No_Items_In")}</h4>
          {/* <p
            style={{
              // textDecorationLine: "underline",
              fontSize: "1.2em",
              color: "var(--Primary)"
            }}
          >
            {t("Check_In_Shipped")}
          </p> */}
        </div>
      )}
    </div>
  )
}

export default SPSPreparation
