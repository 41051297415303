import React from "react"
import "./Reviews.scss"
import Stars from "../ratingStars/Stars"
import "../../styles.scss"
import profile from "../../assets/profile.png"

const Reviews = ({reviews, title}) => {
  return (
    <div id="t1-home-reviews">
      <p style={{color: "var(--Primary)", fontSize: "1.5rem"}}>{title}</p>
      <div className="t1-scroll-container">
        <div className="cards-container">
          {reviews.map((review, index) => (
            <div className="card" key={index}>
              <div
                style={{
                  position: "absolute",
                  // bottom: "20px",
                  left: 0,
                  right: 0,
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  // textShadow:"1px 1px 0px #fff, -1px -1px 0px #fff, 1px -1px 0px #fff, -1px 1px 0px #fff",
                  // WebkitTextStroke: "0.5px #000",
                  // textStroke: "0.5px #000",
                  padding: "10px",
                  height: "100%"
                }}
              >
                <div
                  className="user"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center"
                  }}
                >
                  <div className="user-image">
                    <img
                      src={profile}
                      style={{width: "50px", height: "50px"}}
                    />
                  </div>
                  <div className="username">
                    {review.name}
                    <div
                      style={{
                        margin: "0",
                        padding: "0"
                      }}
                    >
                      <Stars rating={review.rating} />
                      <div
                        style={{
                          margin: "0",
                          padding: "0",
                          fontSize: "9px"
                          // backgroundColor: "grey"
                          // textAlign: "right"
                          // alignItems: "flex-end",
                          // justifyContent: "flex-end"
                        }}
                      >
                        {review?.date}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    color: "var(--Primary)"
                  }}
                >
                  {review?.title}
                </div>
                <div className="review" style={{textAlign: "left"}}>
                  {review?.review?.slice(0, 550)}
                  {review?.review?.length > 550 ? "..." : ""}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Reviews
