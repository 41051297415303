export const SERVICE_BOOK_CREATE_REQUEST = "SERVICE_BOOK_CREATE_REQUEST"
export const SERVICE_BOOK_CREATE_SUCCESS = "SERVICE_BOOK_CREATE_SUCCESS"
export const SERVICE_BOOK_CREATE_FAIL = "SERVICE_BOOK_CREATE_FAIL"

export const SERVICE_BOOK_GET_REQUEST = "SERVICE_BOOK_GET_REQUEST"
export const SERVICE_BOOK_GET_SUCCESS = "SERVICE_BOOK_GET_SUCCESS"
export const SERVICE_BOOK_GET_FAIL = "SERVICE_BOOK_GET_FAIL"

export const SERVICE_BOOK_UPDATE_REQUEST = "SERVICE_BOOK_UPDATE_REQUEST"
export const SERVICE_BOOK_UPDATE_SUCCESS = "SERVICE_BOOK_UPDATE_SUCCESS"
export const SERVICE_BOOK_UPDATE_FAIL = "SERVICE_BOOK_UPDATE_FAIL"

export const SERVICE_BOOK_DELETE_REQUEST = "SERVICE_BOOK_DELETE_REQUEST"
export const SERVICE_BOOK_DELETE_SUCCESS = "SERVICE_BOOK_DELETE_SUCCESS"
export const SERVICE_BOOK_DELETE_FAIL = "SERVICE_BOOK_DELETE_FAIL"
