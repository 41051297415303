import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {PRODUCT_BUILD} from "../../constants/spsProductConstant"
import "./SPSCreateProduct.scss"
import {getProducts} from "../../actions/spsProductsGetActions"
import Loader from "../Loader"
import {HiOutlinePlusCircle, HiOutlineMinusCircle} from "react-icons/hi"
import {createProduct} from "../../actions/spsProductsCreateActions"
import {updateProduct} from "../../actions/spsProductsUpdateActions"

const options = ["reeshsleep", "aramadorn", "madomarche"]

const SPSStoreProductsList = () => {
  const dispatch = useDispatch()

  const spsProductGet = useSelector((state) => state.spsProductGet)
  const {store_product_list, loading} = spsProductGet

  const spsProductBuild = useSelector((state) => state.spsProductBuild)
  const {product_details} = spsProductBuild

  useEffect(() => {}, [dispatch])

  const [selectedOption, setSelectedOption] = useState("")
  const [selectedProductIndex, setSelectedProductIndex] = useState("")

  const handleSelectStore = (event) => {
    const selectedValue = event.target.value
    dispatch(getProducts(`?store=${selectedValue}`))
    setSelectedOption(selectedValue)
  }

  const handleSelectProduct = (productData, index) => {
    setSelectedProductIndex(index)
    dispatch({type: PRODUCT_BUILD, payload: productData})
  }

  const CreateUpdateHandler = (index) => {
    if (product_details?._id || 0)
      dispatch(updateProduct(store_product_list, index, product_details))
    else if (product_details)
      dispatch(createProduct(store_product_list, product_details))
  }

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        width: "100%",
        backgroundColor: "#333",
        // color: "#fff",
        margin: "0",
        padding: "10px"
      }}
    >
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>
          <label htmlFor="dropdown">Select The Store: </label>
          <select
            id="dropdown"
            onChange={handleSelectStore}
            value={selectedOption}
          >
            <option value="">Select Store</option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <Button
          size="md"
          style={{borderRadius: "5px", padding: "5px"}}
          onClick={() => CreateUpdateHandler(selectedProductIndex)}
        >
          {product_details?._id ? `Update: ${product_details._id}` : "Create"}
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        store_product_list?.length > 0 && (
          <div style={{display: "flex", justifyContent: "start"}}>
            {store_product_list.map((product, index) => (
              <img
                key={index}
                src={product?.mainImage}
                style={{
                  margin: "5px",
                  padding: "5px",
                  maxWidth: "50px",
                  border: ` solid ${
                    index === selectedProductIndex ? "green 3px" : "grey 1px"
                  }`,
                  borderRadius: "5px"
                }}
                onClick={() => handleSelectProduct(product, index)}
              />
            ))}
            <div
              style={{
                margin: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "5px",
                verticalAlign: "center"
              }}
              onClick={() => handleSelectProduct({}, 0)}
            >
              <HiOutlinePlusCircle
                style={{color: "var(--green-color)", fontSize: "20px"}}
              />
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default SPSStoreProductsList
