import React, {useState, useEffect} from "react"
import {Button, Card, Col, Row} from "react-bootstrap"
import "./Investments.scss"
import investmentsList from "./Investments.json"
import {useDispatch, useSelector} from "react-redux"
import {calculateInvestments} from "../actions/investmentActions"
import Loader from "../components/Loader"

const Investments2 = () => {
  const dispatch = useDispatch()

  const invests = useSelector((state) => state.invests)
  const {investments, loading} = invests

  useEffect(() => {
    dispatch(calculateInvestments(investmentsList))
  }, [dispatch])

  return loading ? (
    <Loader />
  ) : (
    <div style={{padding: "0", margin: "0", width: "100%"}}>
      {investments?.map((investment, key) => (
        <Row
          key={key}
          style={{
            // padding: "0",
            margin: "10px",
            // display: "flex",
            border: "1px solid grey",
            borderRadius: "5px",
            width: "100%"
          }}
        >
          <Col className="m-0 p-0" xs={1}>
            {investment.describe}
            <Row>{investment.total}</Row>
          </Col>
          <Col>
            <Col style={{alignContent: "space-between"}}>
              {investment.paymentPlans.map((paymentPlan, index) => (
                <Row
                  key={index}
                  style={{
                    border: "1px red solid",
                    borderRadius: "5px",
                    justifyContent: "start",
                    padding: "5px",
                    margin: "2px"
                  }}
                >
                  <Col xs={1}>
                    <Row>{paymentPlan.DP}%</Row>
                    <Row>{paymentPlan.numberOfYears}Y</Row>
                  </Col>
                  <Col xs={1}>
                    <Row>Year</Row>
                    <Row>Month</Row>
                    <Row>DP</Row>
                    <Row>Instal.</Row>
                    <Row>dof3a</Row>
                    <Row>interest</Row>
                    <Row>Invest.</Row>
                    <Row>Bank/Exit point</Row>
                  </Col>
                  {paymentPlan?.grouped &&
                    Object.keys(paymentPlan?.grouped).map((key) =>
                      // (key === "0" || key === "1") &&
                      paymentPlan?.grouped[key]?.map(
                        (record, index) =>
                          record.month == 12 && (
                            <Col key={index} sm={1}>
                              <Row>{record.year}</Row>
                              <Row>{record.month}</Row>
                              <Row>{Math.floor(record.DP)}</Row>
                              <Row>{Math.floor(record.instalment)}</Row>
                              <Row>{Math.floor(record.dof3a)}</Row>
                              <Row>{Math.floor(record.interest)}</Row>
                              <Row>{Math.floor(record.Investements)}</Row>
                              <Row>{Math.floor(record.Bank)}</Row>
                            </Col>
                          )
                      )
                    )}
                </Row>
              ))}
            </Col>
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default Investments2
