import axios from "axios"
import {toastMessages} from "./toastMessages"

import {
  CREATE_ITEM_FAIL,
  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  DECREMENT_ITEM_FAIL,
  DECREMENT_ITEM_REQUEST,
  DECREMENT_ITEM_SUCCESS,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  INCREMENT_ITEM_FAIL,
  INCREMENT_ITEM_REQUEST,
  INCREMENT_ITEM_SUCCESS
} from "../constants/spsItemConstant"

import siteMeta from "../assets/siteMeta.json"
import i18next from "i18next"
import {checkCartId, generateCartId} from "./spsCartActions"
import {
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS
} from "../constants/spsCartConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const createItem = (payload) => async (dispatch, getState) => {
  let cartId = (await localStorage.getItem("cartId")) || 0

  if (!cartId) {
    cartId = await generateCartId({
      lang: i18next.language,
      store: siteMeta.siteName.en.toLowerCase()
    })
  } else {
    cartId = await checkCartId(cartId, payload)
  }
  payload["cartId"] = cartId
  try {
    dispatch({
      type: CREATE_ITEM_REQUEST
    })
    dispatch({
      type: GET_CART_ITEMS_REQUEST
    })
    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }
    const {data} = await axios.post(`${base_url}/sps-item`, payload, config)
    dispatch({
      type: CREATE_ITEM_SUCCESS
    })
    console.log("create Item")

    dispatch({
      type: GET_CART_ITEMS_SUCCESS,
      payload: data
    })
    // dispatch(
    //   toastMessages(1, lang === "ar" ? "تم اضافة طلبك بنجاح" : "Order Created")
    // )
  } catch (error) {
    dispatch({
      type: CREATE_ITEM_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}

export const incrementItem =
  (item, index, list) => async (dispatch, getState) => {
    try {
      dispatch({
        type: INCREMENT_ITEM_REQUEST
      })

      const config = {
        headers: {
          "Content-type": "application/json"
        }
      }
      const {data} = await axios.patch(
        `${base_url}/sps-item/increment/${item._id}`,
        {productId: item.productId, cartId: item.cartId, quantity: 1},
        config
      )
      dispatch({
        type: INCREMENT_ITEM_SUCCESS
      })
      list[index] = data
      dispatch({
        type: GET_CART_ITEMS_SUCCESS,
        payload: list
      })
      // dispatch(
      //   toastMessages(1, lang === "ar" ? "تم اضافة طلبك بنجاح" : "Order Created")
      // )
    } catch (error) {
      dispatch({
        type: INCREMENT_ITEM_FAIL
      })
      dispatch(toastMessages(0, error.response.data.message))
    }
  }

export const decrementItem =
  (item, index, list) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DECREMENT_ITEM_REQUEST
      })

      const config = {
        headers: {
          "Content-type": "application/json"
        }
      }
      const {data} = await axios.patch(
        `${base_url}/sps-item/decrement/${item._id}`,
        {productId: item.productId, cartId: item.cartId, quantity: 1},
        config
      )
      dispatch({
        type: DECREMENT_ITEM_SUCCESS
      })

      list[index] = data
      dispatch({
        type: GET_CART_ITEMS_SUCCESS,
        payload: list
      })
    } catch (error) {
      dispatch({
        type: DECREMENT_ITEM_FAIL
      })
      dispatch(toastMessages(0, error.response.data.message))
    }
  }

export const deleteItem = (item, index, list) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_ITEM_REQUEST
    })

    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }
    const {data} = await axios.delete(
      `${base_url}/sps-item/remove/${item._id}`,
      {},
      config
    )
    dispatch({
      type: DELETE_ITEM_SUCCESS
    })
    list.splice(index, 1)
    dispatch({
      type: GET_CART_ITEMS_SUCCESS,
      payload: list
    })
  } catch (error) {
    dispatch({
      type: DELETE_ITEM_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}
