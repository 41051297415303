import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import "./SPSCreateProduct.scss"
import Loader from "../Loader"
import "./SPSStoreStocksLogs.scss"
import {isEmptyObject} from "jquery"
import {getStocksLogs, updateStocksLogs} from "../../actions/spsStockLogActions"

const options = ["reeshsleep"]

const SPSStoreStocksLogs = () => {
  const dispatch = useDispatch()

  const spsStockLogGet = useSelector((state) => state.spsStockLogGet)
  const {store_stock_log_list, loading} = spsStockLogGet

  const spsStockLogUpdate = useSelector((state) => state.spsStockLogUpdate)
  const {loading: spsStockLogUpdateLoading} = spsStockLogUpdate

  useEffect(() => {}, [dispatch])

  const [filters, setFilters] = useState({})

  const handleFilter = (param, value) => {
    // Update the value for the specific product
    setFilters((prevValue) => ({
      ...prevValue,
      [param]: value
    }))
  }

  const handleRemoveFilter = (param) => {
    setFilters((prevValue) => {
      // Clone the previous values
      const updatedFilters = {...prevValue}
      // Delete the parameter
      delete updatedFilters[param]
      return updatedFilters
    })
  }

  const handleApplyFilter = () => {
    console.log(filters)
    dispatch(getStocksLogs(filters))
  }

  const handleDelete = (id) => {
    window.alert(`delete ${id}`)
    // if (event?.target?.value)
    //   dispatch(getStocks(`?store=${event?.target?.value}`))
    // setSelectedOption(event?.target?.value)
  }

  const handleRunToClose = () => {
    dispatch(updateStocksLogs())
  }
  return (
    <div id="SPSStoreStocksLogs">
      {store_stock_log_list?.length > 0 && !spsStockLogUpdateLoading && (
        <Button
          size="sm"
          className="rounded"
          style={{
            backgroundColor: "green",
            margin: "2px",
            fontSize: "16px",
            width: "100%"
          }}
          onClick={() => handleRunToClose()}
        >
          Run To Close
        </Button>
      )}
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>
          {Object.keys(filters).map((key) => (
            <Button
              size="sm"
              className="rounded"
              style={{backgroundColor: "blue", margin: "2px"}}
              key={key}
              onClick={() => handleRemoveFilter(key)}
            >
              <strong>{key}</strong>: {filters[key].toString()}
            </Button>
          ))}
        </div>
        <div>
          <Button
            hidden={isEmptyObject(filters)}
            size="sm"
            className="rounded"
            style={{backgroundColor: "orange", margin: "2px"}}
            onClick={() => handleApplyFilter()}
          >
            Apply
          </Button>
          <Button
            hidden={!isEmptyObject(filters) || !store_stock_log_list?.length}
            size="sm"
            className="rounded"
            style={{backgroundColor: "orange", margin: "2px"}}
            onClick={() => handleApplyFilter()}
          >
            Refresh
          </Button>
        </div>
      </div>
      <h5 hidden={!store_stock_log_list?.length} style={{textAlign: "center"}}>
        {store_stock_log_list?.length || 0} Record
      </h5>
      {loading ? (
        <Loader />
      ) : (
        store_stock_log_list?.map((stockLog, index) => (
          <Card key={index} style={{margin: "5px", padding: "5px"}}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <img
                style={{width: "50px", margin: "5px"}}
                className="cart-image"
                src={stockLog.image}
                alt="this is image"
              />
              <div
                style={{
                  width: "100%",
                  borderLeft: "1px dashed grey",
                  padding: "5px"
                }}
              >
                <p style={{margin: "0"}}>{stockLog.lastModify}</p>
                <p style={{margin: "0"}}>{stockLog.name}</p>
                <p style={{margin: "0"}}>{`quantity:${stockLog.quantity}`}</p>
                <p style={{margin: "0"}}>{`cost:${Math.round(
                  stockLog.cost
                )}`}</p>
                <p
                  style={{margin: "0"}}
                >{`stockBefore:${stockLog.stockBefore}`}</p>
                <p
                  style={{margin: "0"}}
                >{`stockAfter:${stockLog.stockAfter}`}</p>
                <h4>Filter Parameters</h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px",
                    alignItems: "end"
                  }}
                >
                  <div>
                    {stockLog?.productId && (
                      <span
                        className="filter"
                        onClick={() =>
                          handleFilter("productId", stockLog.productId)
                        }
                      >
                        {`productId:${stockLog.productId}`}
                        <br />
                      </span>
                    )}

                    {stockLog?.orderId && (
                      <span
                        className="filter"
                        onClick={() =>
                          handleFilter("orderId", stockLog.orderId)
                        }
                      >
                        {`orderId:${stockLog.orderId}`}
                        <br />
                      </span>
                    )}

                    {stockLog?.action && (
                      <span
                        className="filter"
                        onClick={() => handleFilter("action", stockLog.action)}
                      >
                        {`action:${stockLog.action}`}
                        <br />
                      </span>
                    )}

                    {stockLog?.type && (
                      <span
                        className="filter"
                        onClick={() => handleFilter("type", stockLog.type)}
                      >
                        {`type:${stockLog.type}`}
                        <br />
                      </span>
                    )}

                    {stockLog?.store && (
                      <span
                        className="filter"
                        onClick={() => handleFilter("store", stockLog.store)}
                      >
                        {`store:${stockLog.store}`}
                        <br />
                      </span>
                    )}

                    <span
                      style={{color: `${stockLog.closed ? "green" : "blue"}`}}
                      className="filter"
                      onClick={() => handleFilter("closed", stockLog.closed)}
                    >{`closed:${stockLog.closed}`}</span>
                  </div>
                  {/* <div>
                    <Button
                      style={{borderRadius: "5px", backgroundColor: "red"}}
                      onClick={() => handleDelete(stockLog._id)}
                    >
                      Remove
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </Card>
        ))
      )}
    </div>
  )
}

export default SPSStoreStocksLogs
