import axios from "axios"
import {toastMessages} from "./toastMessages"
import {
  GET_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS
} from "../constants/spsProductConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const updateProduct =
  (list, index, payload) => async (dispatch, getState) => {
    console.log(list, index, payload)
    try {
      dispatch({
        type: UPDATE_PRODUCT_REQUEST
      })

      const {
        userLogin: {userInfo}
      } = getState()

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const {data} = await axios.patch(
        `${base_url}/sps-product/${payload._id}`,
        payload,
        config
      )
      console.log(data)
      dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: data
      })

      list[index] = data
      dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: list
      })

      dispatch(toastMessages(1, "Done"))
    } catch (error) {
      console.log(error)
      dispatch({
        type: UPDATE_PRODUCT_FAIL
      })
      dispatch(toastMessages(0, error.response))
    }
  }
