import {SET_ERROR, RESET_ERROR} from "../constants/errorAlertConstants"

export const errorAlertReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        status: true,
        message: action.payload
      }

    case RESET_ERROR:
      return {
        status: false,
        message: ""
      }

    default:
      return state
  }
}
