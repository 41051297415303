export const CREATE_ITEM_REQUEST = "CREATE_ITEM_REQUEST"
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS"
export const CREATE_ITEM_FAIL = "CREATE_ITEM_FAIL"

export const INCREMENT_ITEM_REQUEST = "INCREMENT_ITEM_REQUEST"
export const INCREMENT_ITEM_SUCCESS = "INCREMENT_ITEM_SUCCESS"
export const INCREMENT_ITEM_FAIL = "INCREMENT_ITEM_FAIL"

export const DECREMENT_ITEM_REQUEST = "DECREMENT_ITEM_REQUEST"
export const DECREMENT_ITEM_SUCCESS = "DECREMENT_ITEM_SUCCESS"
export const DECREMENT_ITEM_FAIL = "DECREMENT_ITEM_FAIL"

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST"
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS"
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL"
