import React from "react"
import {useDispatch, useSelector} from "react-redux"
import Loader from "../components/Loader"
import {getOrders} from "../actions/spsOrderGetActions"
import {useEffect} from "react"
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap"
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {HiOutlineMinusCircle, HiOutlinePlusCircle} from "react-icons/hi"
import {FaWhatsappSquare, FaSms} from "react-icons/fa"
import "./ManageToShip.scss"
import {updateOrder} from "../actions/spsOrderUpdateActions"
import {duplicateOrder} from "../actions/spsOrderDuplicateActions"
import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

import zones from "../assets/zones.json"
import couriers from "../assets/couriers.json"

const ManageToShip = () => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const [fullName, setFullName] = useState("")
  const [mobile, setMobile] = useState("")
  const [anotherMobile, setAnotherMobile] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("")
  const [floor, setFloor] = useState("")
  const [flat, setFlat] = useState("")
  const [notes, setNotes] = useState("")
  const [quantity, setQuantity] = useState(0)

  const [status, setStatus] = useState("")

  const [modalStatus, setModalStatus] = useState(false)
  const [currentOrder, setCurrentOrder] = useState("")
  const [orderIndex, setOrderIndex] = useState("")

  const [selectedTab, setSelectedTab] = useState(zones[0])
  const [selectedCourier, setSelectedCourier] = useState("")
  useEffect(() => {
    dispatch(getOrders(`?status=TOSHIP&zone=${selectedTab.filter}`))
  }, [dispatch])

  const spsOrderGet = useSelector((state) => state.spsOrderGet)
  const {loading, orders} = spsOrderGet

  const handleModal = (order, index) => {
    setFullName(order.fullName)
    setMobile(order.mobile)
    setAnotherMobile(order?.anotherMobile || "")
    setAddress(order.address)
    setCity(order?.city || "")
    setRegion(order?.region || "")
    setFloor(order?.floor || "")
    setFlat(order?.flat || "")
    setQuantity(order.quantity)
    setStatus(order.status)
    setNotes(order.notes)

    setOrderIndex(index)
    setCurrentOrder(order)
    setModalStatus(true)
  }

  const manageQuantity = (plusOrMinus) => {
    let currentQuentity = Number(quantity)
    if (plusOrMinus == "+") {
      currentQuentity++
    }
    if (plusOrMinus == "-")
      if (quantity != 1) {
        currentQuentity--
      }
    localStorage.setItem("quantity", currentQuentity)
    setQuantity(currentQuentity)
  }

  const handleFilter = (filter) => {
    setSelectedTab(filter)
    dispatch(
      getOrders(
        `?status=TOSHIP${filter.filter !== "" ? "&zone=" + filter.filter : ""}`
      )
    )
  }

  const handleCourier = (courier) => {
    setSelectedCourier(courier)
  }

  const handleUpdate = () => {
    let payload = {}
    if (fullName !== "") payload["fullName"] = fullName
    if (mobile !== "") payload["mobile"] = mobile
    payload["anotherMobile"] = anotherMobile
    if (address !== "") payload["address"] = address
    payload["city"] = city
    payload["region"] = region
    payload["floor"] = floor
    payload["flat"] = flat
    payload["notes"] = notes

    dispatch(updateOrder(currentOrder._id, payload, orderIndex, orders))
  }

  const handleConfirm = () => {
    dispatch(
      updateOrder(currentOrder._id, {status: "CONFIRMED"}, orderIndex, orders)
    )
    setModalStatus(false)
  }

  const handleDuplicate = () => {
    dispatch(duplicateOrder(currentOrder._id))
    setModalStatus(false)
  }

  const handleCancel = () => {
    dispatch(
      updateOrder(currentOrder._id, {status: "TOCANCEL"}, orderIndex, orders)
    )
    setModalStatus(false)
  }

  const handleCallsCounter = (orderId, orderIndex) => {
    dispatch(updateOrder(orderId, {callsCounter: 1}, orderIndex, orders))
  }

  const handleAsignToShipper = (order, orderIndex) => {
    dispatch(
      updateOrder(
        order._id,
        {manifestId: order?.manifestId || 0 ? "" : selectedCourier.manifestId},
        orderIndex,
        orders
      )
    )
  }

  const handleWhatsAppClick = (
    orderId,
    orderIndex,
    clientName,
    mobile,
    callsCounter
  ) => {
    if (
      selectedTab === "Shipped" ||
      selectedTab === "Returned" ||
      callsCounter > 1
    ) {
      dispatch(
        updateOrder(
          orderId,
          {whatsAppReturnAlertCounter: 1},
          orderIndex,
          orders
        )
      )
      const url = `https://api.whatsapp.com/send?phone=+2${mobile}&text=${encodeURIComponent(
        `اهلا ${clientName} تم التواصل اكثر من مره من شركة الشحن لتوصيل طلبك مخدة الرجل الطبية و لم يتم الرد برجاء ارسال رقم موبايل اخر ليتم التواصل او سيتم الغاء طلبكم و رجوعه الينا`
      )}`
      window.open(url, "_blank")
    }
  }

  const handleMessageClick = (
    orderId,
    orderIndex,
    clientName,
    mobile,
    callsCounter
  ) => {
    if (
      selectedTab === "Shipped" ||
      selectedTab === "Returned" ||
      callsCounter > 1
    ) {
      dispatch(
        updateOrder(
          orderId,
          {whatsAppReturnAlertCounter: 1},
          orderIndex,
          orders
        )
      )
      const url = `sms:${mobile}?body=${encodeURIComponent(
        `اهلا ${clientName} تم التواصل اكثر من مره من شركة الشحن لتوصيل طلبك مخدة الرجل الطبية و لم يتم الرد برجاء ارسال رقم موبايل اخر ليتم التواصل او سيتم الغاء طلبكم و رجوعه الينا`
      )}`
      window.open(url, "_blank")
    }
  }

  const viewCourierOrders = (manifestId) => {
    // const url = `http://localhost:3000/#/manage-my-manifest?manifestId=${manifestId}`
    const url = `https://reeshsleep.com/#/manage-my-manifest?manifestId=${manifestId}`
    window.open(url, "_blank")
  }

  const manageCourierOrders = (manifestId) => {
    // const url = `http://localhost:3000/#/manage-shipper-orders?manifestId=${manifestId}`
    const url = `https://reeshsleep.com/#/manage-shipper-orders?manifestId=${manifestId}`
    window.open(url, "_blank")
  }

  return (
    <div id="sps-manage-to-ship">
      <ErrorModal />
      <SuccessModal />
      <Modal
        show={modalStatus}
        onHide={() => setModalStatus(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Order {currentOrder._id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{marginTop: "5px", padding: "5px"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <HiOutlineMinusCircle onClick={() => manageQuantity("-")} />
              <div className="quantity" style={{margin: "10px"}}>
                {quantity}
              </div>
              <HiOutlinePlusCircle onClick={() => manageQuantity("+")} />
            </div>
            <Row>
              <Col>
                <a href={`tel:${mobile}`}>
                  <i
                    className="bi bi-telephone-fill"
                    style={{
                      fontSize: "20px",
                      color: "green",
                      verticalAlign: "middle"
                    }}
                  ></i>
                </a>
              </Col>
              <Col>
                <h4>{status}</h4>
              </Col>
            </Row>
            {/* <Row className="p-0 m-0" style={{color: "olivedrab"}}>
              {notes}
            </Row> */}
            <Form.Label
              htmlFor="full-name"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Full_Name")}{" "}
              <span style={{color: "red"}}>* {t("Required")}</span>
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="mobile"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Mobile_Number")}{" "}
              <span style={{color: "red"}}>* {t("Required")}</span>
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="another-mobile"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Another_Mobile_Number")}{" "}
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              type="tel"
              value={anotherMobile}
              onChange={(e) => setAnotherMobile(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="notes"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Notes")}
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              as="textarea" // Use the textarea element
              rows={2} // Specify the number of rows to display
              type="text"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="address"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Address")}{" "}
              <span style={{color: "red"}}>* {t("Required")}</span>
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              as="textarea" // Use the textarea element
              rows={3} // Specify the number of rows to display
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
            <div
              style={{
                margin: "0",
                padding: "0",
                display: "flex",
                direction: t("Dir")
              }}
            >
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="city" style={{}}>
                  {t("City")}
                </Form.Label>
                <Form.Control
                  style={{border: "1px solid grey"}}
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                ></Form.Control>
              </div>
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="region" style={{}}>
                  {t("Region")}
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    textAlign: "start",
                    direction: t("Dir")
                  }}
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                ></Form.Control>
              </div>
            </div>
            <div
              style={{
                margin: "0",
                padding: "0",
                display: "flex",
                direction: t("Dir")
              }}
            >
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="flor" style={{}}>
                  {t("Floor")}
                </Form.Label>
                <Form.Control
                  style={{border: "1px solid grey"}}
                  type="number"
                  value={floor}
                  onChange={(e) => setFloor(e.target.value)}
                ></Form.Control>
              </div>
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="flat" style={{}}>
                  {t("Flat")}
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    textAlign: "start",
                    direction: t("Dir")
                  }}
                  type="number"
                  value={flat}
                  onChange={(e) => setFlat(e.target.value)}
                ></Form.Control>
              </div>
            </div>

            {status !== "RETURNED" ? (
              <>
                <Row className="p-0 m-0">
                  <Button
                    style={{width: "100%", margin: "5px"}}
                    onClick={() => handleUpdate()}
                  >
                    Update
                  </Button>
                </Row>
                <Row className="p-0 m-0">
                  <Button
                    variant="success"
                    style={{width: "47%", margin: "5px"}}
                    onClick={() => handleConfirm()}
                  >
                    Confirm
                  </Button>

                  <Button
                    variant="danger"
                    style={{width: "47%", margin: "5px"}}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                </Row>
              </>
            ) : (
              <Row className="p-0 m-0">
                <Button
                  style={{width: "100%", margin: "5px"}}
                  onClick={() => handleDuplicate()}
                >
                  Duplicate
                </Button>
              </Row>
            )}
          </Card>
        </Modal.Body>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <Row className="p-0 m-0">
          <div className="status-bar">
            {zones.map((zone, index) => (
              <div
                key={index}
                className="status-tag"
                onClick={() => handleFilter(zone)}
                style={{
                  backgroundColor:
                    selectedTab.name === zone.name ? "goldenrod" : "white"
                }}
              >
                {zone.name}
              </div>
            ))}
          </div>
          <div className="status-bar">
            {couriers.map((courier, index) => (
              <div
                key={index}
                className="status-tag"
                onClick={() => handleCourier(courier)}
                style={{
                  width: "175px",
                  backgroundColor:
                    selectedCourier.name === courier.name ? "cyan" : "white"
                }}
              >
                <p style={{padding: "0", margin: "0"}}>{courier.name}</p>
                <p style={{padding: "0", margin: "0"}}>{courier.region1}</p>
                <p style={{padding: "0", margin: "0"}}>{courier.region2}</p>
                <p style={{padding: "0", margin: "0"}}>{courier.region3}</p>
                <p style={{padding: "0", margin: "0"}}>{courier.region4}</p>
                <Row className="p-0 m-0 justify-content-between">
                  <Col>
                    <Button
                      className="p-1 m-0 rounded"
                      onClick={() => manageCourierOrders(courier.manifestId)}
                    >
                      Manage
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="p-1 m-0 rounded"
                      onClick={() => viewCourierOrders(courier.manifestId)}
                    >
                      View
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          <Col>
            {orders?.map((order, index) => (
              <Col
                key={index}
                className="p-0 m-0"
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              >
                <div
                  className="card"
                  style={{
                    padding: "5px",
                    margin: "3px",
                    backgroundColor:
                      orders[index]?.mobile === orders[index + 1]?.mobile
                        ? "red"
                        : ""
                  }}
                >
                  <Row className="p-0 m-0">
                    <Col className="p-0 m-0" xs={6} style={{fontSize: "8px"}}>
                      {order.createdAt}
                    </Col>
                    <Col className="p-0 m-0" xs={6} style={{fontSize: "8px"}}>
                      {order._id}
                    </Col>
                    <Col
                      className="p-0 m-0"
                      xs={12}
                      style={{
                        fontSize: "10px",
                        color: "orange",
                        backgroundColor: "cyan"
                      }}
                    >
                      {order.notes}
                    </Col>
                    {order?.manifestId || 0 ? (
                      <Col
                        className="p-0 m-0"
                        xs={12}
                        style={{
                          fontSize: "10px",
                          color: "orange",
                          backgroundColor:
                            selectedCourier.manifestId === order.manifestId
                              ? "green"
                              : "greenyellow"
                        }}
                      >
                        Assigned To: {order.manifestId}
                      </Col>
                    ) : (
                      <Col
                        className="p-0 m-0"
                        xs={12}
                        style={{
                          fontSize: "10px",
                          color: "orange",
                          backgroundColor: "powderblue"
                        }}
                      >
                        Not Assigned
                      </Col>
                    )}

                    <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                      <FaWhatsappSquare
                        onClick={() =>
                          handleWhatsAppClick(
                            order._id,
                            index,
                            order.fullName,
                            order.mobile,
                            order.callsCounter
                          )
                        }
                      />
                      {order?.whatsAppReturnAlertCounter || 0}
                    </Col>
                    <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                      <FaSms
                        className="bi bi-telephone-fill"
                        onClick={() =>
                          handleMessageClick(
                            order._id,
                            index,
                            order.fullName,
                            order.mobile,
                            order.callsCounter
                          )
                        }
                      />
                      {order?.whatsAppReturnAlertCounter || 0}
                    </Col>
                    <Col
                      className="p-0 m-0"
                      xs={6}
                      onClick={() => handleCallsCounter(order._id, index)}
                    >
                      <a href={`tel:${order.mobile}`}>
                        <i
                          className="bi bi-telephone-fill"
                          style={{
                            fontSize: "14px",
                            color: "green",
                            verticalAlign: "middle"
                          }}
                        >
                          {order?.callsCounter || 0}
                        </i>
                      </a>
                    </Col>
                    {order?.anotherMobile ? (
                      <Col
                        className="p-0 m-0"
                        xs={6}
                        onClick={() => handleCallsCounter(order._id, index)}
                      >
                        <a href={`tel:${order.anotherMobile}`}>
                          <i
                            className="bi bi-telephone-fill"
                            style={{
                              fontSize: "14px",
                              color: "green",
                              verticalAlign: "middle"
                            }}
                          >
                            {order?.callsCounter || 0}
                          </i>
                        </a>
                      </Col>
                    ) : (
                      ""
                    )}
                    <hr
                      style={{
                        color: "green",
                        height: "1px",
                        backgroundColor: "green",
                        padding: "0",
                        margin: "0"
                      }}
                    />
                    <Col className="p-0 m-0" xs={6} style={{fontSize: "12px"}}>
                      {order.fullName}
                    </Col>
                    <Col className="p-0 m-0" xs={6} style={{fontSize: "12px"}}>
                      {order.mobile}
                    </Col>
                    <Col xs={12} style={{fontSize: "10px"}}>
                      {order.address}
                    </Col>

                    <Col xs={6} style={{fontSize: "12px"}}>
                      {order.region}
                    </Col>

                    {/* <Col
                    xs={3}
                    style={{
                      fontSize: order?.quantity > 1 ? "18px" : "12px",
                      backgroundColor: order?.quantity > 1 ? "orange" : "",
                      color: order?.quantity > 1 ? "white" : "",
                      borderRadius: "10px"
                    }}
                  >
                    {order.quantity}
                  </Col> */}

                    {/* <Col xs={3} style={{fontSize: "12px"}}>
                    {order?.items?.reduce(
                      (acc, item) => acc + item.quantity * item.price,
                      0
                    ) +
                      (order.shippingFee - (order?.shippingFeeDiscount || 0)) -
                      (order?.couponValue || 0)}
                  </Col> */}

                    <Col
                      xs={8}
                      onClick={() => handleModal(order, index)}
                      style={{color: "orangered"}}
                    >
                      More
                    </Col>
                    <Col xs={4}>
                      <Button
                        className="p-1 m-0 rounded"
                        onClick={() => handleAsignToShipper(order, index)}
                        disabled={selectedCourier === ""}
                      >
                        {order?.manifestId || 0 ? "De-asign" : "Asign"}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Col>
          <Col xl={1}>
            <Row>650</Row>
            <Row>650</Row>
            <Row>650</Row>
            <Row>650</Row>
            <Row>650</Row>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ManageToShip
