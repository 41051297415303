import {
  ANALYSIS_GET_FAIL,
  ANALYSIS_GET_REQUEST,
  ANALYSIS_GET_SUCCESS
} from "../constants/spsAnalysisConstants"

export const analysisGetReducer = (state = {}, action) => {
  switch (action.type) {
    case ANALYSIS_GET_REQUEST:
      return {...state, loading: true}

    case ANALYSIS_GET_SUCCESS:
      return {loading: false, analysisData: action.payload}

    case ANALYSIS_GET_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}
