export const SET_SERVANTINMODAL = "SET_SERVANTINMODAL"
export const RESET_SERVANTINMODAL = "RESET_SERVANTINMODAL"

export const SET_SERVANTINSERVICE = "SET_SERVANTINSERVICE"

///used by me
export const SERVANTIN_LIST_REQUEST = "SERVANTIN_LIST_REQUEST"
export const SERVANTIN_LIST_SUCCESS = "SERVANTIN_LIST_SUCCESS"
export const SERVANTIN_LIST_FAIL = "SERVANTIN_LIST_FAIL"
export const SERVANTIN_LIST_RESET = "SERVANTIN_LIST_RESET"

export const SERVANTIN_CREATE_REQUEST = "SERVANTIN_CREATE_REQUEST"
export const SERVANTIN_CREATE_SUCCESS = "SERVANTIN_CREATE_SUCCESS"
export const SERVANTIN_CREATE_FAIL = "SERVANTIN_CREATE_FAIL"
export const SERVANTIN_CREATE_RESET = "SERVANTIN_CREATE_RESET"

///////////////////////////////////////////////////////////////////////////////////////////

export const SERVANTIN_LIST_MY_REQUEST = "SERVANTIN_LIST_MY_REQUEST"
export const SERVANTIN_LIST_MY_SUCCESS = "SERVANTIN_LIST_MY_SUCCESS"
export const SERVANTIN_LIST_MY_FAIL = "SERVANTIN_LIST_MY_FAIL"
export const SERVANTIN_LIST_MY_RESET = "SERVANTIN_LIST_MY_RESET"
