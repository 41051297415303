import {HashRouter as Router, Route} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import Footer from "./components/Footer"
import HomeScreen from "./screens/HomeScreen"
import {useEffect, useState} from "react"
import {Container} from "react-bootstrap"
import CheckoutScreen from "./screens/CheckoutScreen"
import SuccessScreen from "./screens/SuccessScreen"
import Login from "./screens/Login"
import Manage from "./screens/Manage"
import ManageToShip from "./screens/ManageToShip"
import ManageMyManifest from "./screens/ManageMyManifest"
import ManageShipperOrders from "./screens/ManageShipperOrders"
import {Helmet} from "react-helmet"
import siteMeta from "../src/assets/siteMeta.json"
import {TermsAndCondtions} from "./screens/TermsAndCondtions"
import ReturnPolicy from "./screens/ReturnPolicy"
import ShippingPolicy from "./screens/ShippingPolicy"
import {ContactUs} from "./screens/ContactUs"
import AnalysisScreen from "./screens/AnalysisScreen"
import CreateProduct from "./screens/CreateProduct"
import TicketsScreen from "./screens/TicketsScreen"
import Sidebar from "./components/Sidebar"
import {Switch} from "react-router-dom/cjs/react-router-dom.min"
import Stocks from "./screens/Stocks"
import Investments2 from "./screens/Investments2"
import "./styles.scss"
import AdvancedTable from "./screens/AdvancedTable"
import HomeScreen2 from "./screens/HomeScreen2"

function App() {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const {error, loading, userInfo} = userLogin

  useEffect(() => {}, [dispatch])

  return (
    <div>
      <Helmet>
        <title>{siteMeta.title}</title>
        <meta name="description" content={siteMeta.description} />
        <meta name="keywords" content={siteMeta.keywords} />
        <meta
          name="theme-color"
          content={getComputedStyle(document.documentElement)
            .getPropertyValue("--Primary")
            .trim()}
        />

        <meta property="og:image" content={siteMeta.ogImage} />
        <meta
          property="og:title"
          content="ReeshSleep - Revolutionizing Your Sleep Experience"
        />
        <meta
          property="og:description"
          content="Discover the ultimate sleep solutions at ReeshSleep. Transform the way you sleep and enhance your well-being."
        />
        <meta property="og:url" content="https://www.reeshsleep.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Router>
        {/* <Header /> */}
        <Switch>
          <Route path="/" component={HomeScreen2} exact />
          <Route path="/checkout" component={CheckoutScreen} />
          <Route path="/created-successfuly" component={SuccessScreen} />
          <Route path="/login" component={Login} />
          <Route path="/manage" component={Manage} />
          <Route path="/manage-to-ship" component={ManageToShip} />
          <Route path="/manage-my-manifest" component={ManageMyManifest} />
          <Route
            path="/manage-shipper-orders"
            component={ManageShipperOrders}
          />
          <Route
            path="/terms-and-conditions"
            component={TermsAndCondtions}
            exact
          />
          <Route
            path="/return-and-refund-policy"
            component={ReturnPolicy}
            exact
          />
          <Route path="/shipping-policy" component={ShippingPolicy} exact />
          <Route path="/contact-information" component={ContactUs} exact />
          <Route path="/investments" component={Investments2} exact />
          <Route path="/tickets" component={TicketsScreen} exact />
          <Route path="/tables" component={AdvancedTable} exact />

          <Route path="/dashboard">
            <Sidebar />
            <AnalysisScreen />
          </Route>
          <Route path="/analysis">
            <Sidebar />
            <AnalysisScreen />
          </Route>
          <Route path="/create-product">
            <Sidebar />
            <CreateProduct />
          </Route>
          <Route path="/stock">
            <Sidebar />
            <Stocks />
          </Route>
          {/* <Footer /> */}
        </Switch>
      </Router>
    </div>
  )
}

export default App
