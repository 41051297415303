export const EXPENSE_GET_REQUEST = "EXPENSE_GET_REQUEST"
export const EXPENSE_GET_SUCCESS = "EXPENSE_GET_SUCCESS"
export const EXPENSE_GET_FAIL = "EXPENSE_GET_FAIL"

export const EXPENSE_POST_REQUEST = "EXPENSE_POST_REQUEST"
export const EXPENSE_POST_SUCCESS = "EXPENSE_POST_SUCCESS"
export const EXPENSE_POST_FAIL = "EXPENSE_POST_FAIL"

export const EXPENSE_DOWNLOAD_REQUEST = "EXPENSE_DOWNLOAD_REQUEST"
export const EXPENSE_DOWNLOAD_SUCCESS = "EXPENSE_DOWNLOAD_SUCCESS"
export const EXPENSE_DOWNLOAD_FAIL = "EXPENSE_DOWNLOAD_FAIL"
