import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, Card, Form, Modal} from "react-bootstrap"
import Loader from "../Loader"
import {ticketPost} from "../../actions/ticketActions"

const ShippingCompaniesModal = ({modalStatus, message, onHide}) => {
  const handleWhatsAppClick = (mobile, message) => {
    const url = `https://api.whatsapp.com/send?phone=+2${mobile}&text=${encodeURIComponent(
      message
    )}`
    window.open(url, "_blank")
  }

  return (
    <Modal
      show={modalStatus}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Send Return Request To:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card
          style={{
            width: "100%",
            alignItems: "center",
            padding: "5px",
            margin: "5px"
          }}
          onClick={() => handleWhatsAppClick("01092305812", message)}
        >
          <img
            src="https://i.postimg.cc/3RYD9nWn/fincart.png"
            style={{
              width: "200px",
              borderRadius: "5px"
            }}
          />
        </Card>
        {/* <Card
          style={{
            width: "100%",
            alignItems: "center",
            padding: "5px",
            margin: "5px"
          }}
          onClick={() => handleWhatsAppClick("01273215942", message)}
        >
          <img
            src="https://i.postimg.cc/ZRHvQpdZ/dlvago.png"
            style={{
              width: "200px",
              borderRadius: "5px"
            }}
          />
        </Card> */}
        <Card
          style={{
            width: "100%",
            alignItems: "center",
            padding: "5px",
            margin: "5px"
          }}
          onClick={() => handleWhatsAppClick("01030288001", message)}
        >
          <img
            src="https://i.postimg.cc/tJgnGJ36/asphalt-logo-copy.png"
            style={{
              width: "200px",
              borderRadius: "5px"
            }}
          />
        </Card>
      </Modal.Body>
    </Modal>
  )
}

const SPSSelectedItemTickets = ({selectedItem, selectedOrderIndex}) => {
  const dispatch = useDispatch()
  // const [selectedTicket, setSelectedTicket] = useState({})

  const [modalOpen, setModalOpen] = useState(false)

  const [message, setMessage] = useState("dnsnfm,ndmsnsmg,ndgs,gnds,")
  const closeModal = () => {
    setModalOpen(false)
  }

  const handleModal = (message) => {
    setMessage(message)
    setModalOpen(true)
  }

  const ticket = useSelector((state) => state.ticket)
  const {loading} = ticket

  const spsOrderGet = useSelector((state) => state.spsOrderGet)
  const {orders} = spsOrderGet

  const [inputValue, setInputValue] = useState("")

  // const handleSelected = (item) => {
  //   setSelectedTicket(item)
  // }

  const handleInputChange = (description) => {
    setInputValue(description)
  }

  const handleAddTicket = () => {
    dispatch(
      ticketPost(
        {description: inputValue, itemId: selectedItem._id},
        selectedOrderIndex,
        orders
      )
    )
    console.log(selectedItem._id, selectedOrderIndex)
  }

  return (
    <div style={{display: "flex"}}>
      {modalOpen && (
        <ShippingCompaniesModal
          modalStatus={modalOpen}
          message={message}
          onHide={closeModal}
        />
      )}
      {selectedItem?.tickets?.map((ticket) => (
        <Card
          onClick={() =>
            handleModal(
              `برجاء اتمام طلب ارجاع من العميل: \n ${orders[selectedOrderIndex].fullName} \nعنوان: ${orders[selectedOrderIndex].address} - ${orders[selectedOrderIndex].region} \nرقم تليفون: ${orders[selectedOrderIndex].mobile} \nكود الشحنة ${ticket?.returnTracking}`
            )
          }
          key={ticket._id}
          style={{
            margin: "3px",
            border: `${
              ticket._id === selectedItem._id
                ? `2px green solid`
                : `1px grey solid`
            }`
          }}
        >
          <p>{ticket.status}</p>
          <p>{ticket.ticketCounter}</p>
          <p>{ticket.refundAmount}</p>
          <p>{ticket.description}</p>
          <p>{ticket.createdAt}</p>
          <p>{ticket.shippingCompany}</p>
          <p>{ticket.returnTracking}</p>
        </Card>
      ))}
      {loading ? (
        <Loader />
      ) : (
        selectedItem &&
        selectedItem?.tickets?.length === 0 && (
          <Card
            key={"create-new"}
            style={{
              margin: "3px",
              padding: "5px",
              border: "1px grey solid",
              minWidth: "fit-content"
            }}
          >
            <Form.Control
              style={{minWidth: "200px"}}
              as="textarea" // Use the textarea element
              rows={2} // Specify the number of rows to display
              type="text"
              placeholder="description"
              value={inputValue}
              onChange={(e) => handleInputChange(e.target.value)}
            ></Form.Control>

            <Button
              size="sm"
              style={{borderRadius: "5px", padding: "5px", marginTop: "5px"}}
              onClick={() => handleAddTicket()}
            >
              Add
            </Button>
          </Card>
        )
      )}
    </div>
  )
}

export default SPSSelectedItemTickets
