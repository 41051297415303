export const SET_SERVEDBYMODAL = "SET_SERVEDBYMODAL"
export const RESET_SERVEDBYMODAL = "RESET_SERVEDBYMODAL"

export const SET_SERVEDBYSERVICE = "SET_SERVEDBYSERVICE"

export const SERVED_BY_REQUEST = "SERVED_BY_REQUEST"
export const SERVED_BY_SUCCESS = "SERVED_BY_SUCCESS"
export const SERVED_BY_FAIL = "SERVED_BY_FAIL"
export const SERVED_BY_CLEAR = "SERVED_BY_CLEAR"

export const SERVED_BY_DELETE_REQUEST = "SERVED_BY_DELETE_REQUEST"
export const SERVED_BY_DELETE_SUCCESS = "SERVED_BY_DELETE_SUCCESS"
export const SERVED_BY_DELETE_FAIL = "SERVED_BY_DELETE_FAIL"
