import {
  GET_MY_ORDER_FAIL,
  GET_MY_ORDER_REQUEST,
  GET_MY_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS
} from "../constants/spsOrderGetConstant"

export const spsOrderGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_REQUEST:
      return {loading: true}

    case GET_ORDER_SUCCESS:
      return {loading: false, orders: action.payload}

    case GET_ORDER_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsMyOrderGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MY_ORDER_REQUEST:
      return {loading: true}

    case GET_MY_ORDER_SUCCESS:
      return {loading: false, myOrders: action.payload}

    case GET_MY_ORDER_FAIL:
      return {loading: false}

    default:
      return state
  }
}
