import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {PRODUCT_BUILD} from "../../constants/spsProductConstant"
import "./SPSCreateProduct.scss"
import {getStocks} from "../../actions/spsStockActions"
import Loader from "../Loader"
import {HiOutlinePlusCircle, HiOutlineMinusCircle} from "react-icons/hi"
import {createProduct} from "../../actions/spsProductsCreateActions"
import {updateProduct} from "../../actions/spsProductsUpdateActions"

const options = ["reeshsleep"]

const SPSStoreStocks = () => {
  const dispatch = useDispatch()

  const spsStockGet = useSelector((state) => state.spsStockGet)
  const {store_stock_list, loading} = spsStockGet

  useEffect(() => {}, [dispatch])

  const [selectedOption, setSelectedOption] = useState("")

  const handleSelectStore = (event) => {
    if (event?.target?.value)
      dispatch(getStocks(`?store=${event?.target?.value}`))
    setSelectedOption(event?.target?.value)
  }

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        zIndex: "2",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "white",
        fontSize: "2rem",
        backgroundColor: "#333",
        margin: "0",
        padding: "10px",
        borderRadius: "5px"
      }}
    >
      <div>
        <label htmlFor="dropdown">{`Select The Store: `}</label>
        <select
          style={{margin: "5px", borderRadius: "5px", padding: "1px"}}
          id="dropdown"
          onChange={handleSelectStore}
          value={selectedOption}
        >
          <option value="">Select Store</option>
          {options?.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>{`Total Products Value: ${Math.round(
          store_stock_list?.reduce((total, item) => {
            return total + item.cost * item.quantity
          }, 0) || 0
        )} LE`}</div>
      )}
    </div>
  )
}

export default SPSStoreStocks
