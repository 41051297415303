import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {useTranslation} from "react-i18next"

import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

import "./CreateProduct.scss"

import productDetails from "./productDetails.json"

import {getCartItems, updateCart} from "../actions/spsCartActions"
import Loader from "../components/Loader"
import {PRODUCT_BUILD} from "../constants/spsProductConstant"
import {SPSCreateProductBasics} from "../components/SPS/SPSCreateProductBasics"
import SPSCreateProductCatchy from "../components/SPS/SPSCreateProductCatchy"
import SPSCreateProductBullets from "../components/SPS/SPSCreateProductBullets"
import SPSCreateProductMore from "../components/SPS/SPSCreateProductMore"
import SPSStoreProductsList from "../components/SPS/SPSStoreProductsList"

function CreateProduct() {
  const [t, i18n] = useTranslation()

  const spsProductBuild = useSelector((state) => state.spsProductBuild)
  const {product_details} = spsProductBuild

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCartItems())
  }, [dispatch])

  return (
    <div>
      <ErrorModal />
      <SuccessModal />
      <div id="SPS-create-product">
        <SPSStoreProductsList />
        <SPSCreateProductBasics />

        <SPSCreateProductCatchy catchyX={"catchy1"} />
        <SPSCreateProductCatchy catchyX={"catchy2"} />
        <SPSCreateProductBullets />
        <SPSCreateProductMore />
      </div>
    </div>
  )
}

export default CreateProduct
