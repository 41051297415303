import React, {useState, useEffect, useRef} from "react"

import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import axios from "axios"

import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

import "./HomeScreen.scss"
import reviewsData from "../assets/reviews.json"
import siteMeta from "../assets/siteMeta.json"
import productDetails from "./productDetails.json"
import Reviews from "../components/homeReviews/Reviews"

import logo from "../assets/logo.png"
import thumbUp from "../assets/thumb-up.png"
import EGYPTFlag from "../assets/Flag-of-Egypt.png"
import UAEFlag from "../assets/Flag-of-Emirates.png"

import truck from "../assets/truck.png"
import {createItem} from "../actions/spsItemActions"
import {
  generateCartId,
  getCartItems,
  updateCart
} from "../actions/spsCartActions"
import Loader from "../components/Loader"
import Footer from "../components/Footer"
import {ORDER_STATUS} from "../constants/spsOrderStatusConstant"
import OfferCard from "./OfferCard"

import {MdDirectionsBike} from "react-icons/md"
import {IoIosRefresh} from "react-icons/io"

import ReactPlayer from "react-player"

function HomeScreen({history}) {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const [muted, setMuted] = useState(true)
  const [isVisible, setIsVisible] = useState(true)
  const addToCartRef = useRef(null)

  const checkVisibility = () => {
    if (addToCartRef.current) {
      const rect = addToCartRef.current.getBoundingClientRect()
      const isInView =
        rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left <
          (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight)
      setIsVisible(!isInView)
      console.log(isInView)
    }
  }

  // const [isVisible, setIsVisible] = useState(true)
  const videoRef = useRef(null)

  const toggleSound = () => {
    const video = videoRef.current
    video.muted = !video.muted
    setMuted(!muted)
  }

  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "EGP"
  )

  const handleCurrency = (currency) => {
    localStorage.setItem("currency", currency)
    setCurrency(currency)
  }

  const spsCartItemsGet = useSelector((state) => state.spsCartItemsGet)
  const {items, loading} = spsCartItemsGet

  const [selectedProduct, setSelectedProduct] = useState(productDetails[0])
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      let cartId = (await localStorage.getItem("cartId")) || 0

      if (!cartId) {
        let trackDetails = {}
        trackDetails["userAgent"] = navigator?.userAgent || null
        // setUserAgent(navigator.userAgent)
        try {
          const response = await axios.get(
            "https://api64.ipify.org?format=json"
          )
          trackDetails["ipAddress"] = response.data.ip
          // Check if _fbc cookie exists, if not, set it to null
          const fbcCookie = await getCookie("_fbc")
          trackDetails["fbcCookieValue"] =
            typeof fbcCookie === "string" ? fbcCookie : null

          // Similar check for _fbp cookie
          const fbpCookie = await getCookie("_fbp")
          trackDetails["fbpCookieValue"] =
            typeof fbpCookie === "string" ? fbpCookie : null
          dispatch(generateCartId(trackDetails))
        } catch (error) {
          console.error("Error fetching data:", error)
        }
      }
    }

    fetchData()
  }, [])

  async function getCookie(name) {
    const cookies = document.cookie.split(";")
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=")
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue)
      }
    }
    return null
  }

  useEffect(() => {
    // window.addEventListener("scroll", checkVisibility)
    // return () => {
    //   window.removeEventListener("scroll", checkVisibility)
    // }
  }, [])

  useEffect(() => {
    dispatch(getCartItems())

    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     // setIsVisible(entry.isIntersecting)
    //     if (entry.isIntersecting) {
    //       videoRef.current.play()
    //     } else {
    //       videoRef.current.pause()
    //     }
    //   },
    //   {threshold: 0.5}
    // )
    // observer.observe(videoRef.current)

    // return () => observer.disconnect()
  }, [dispatch])

  const addToCartHandler = (path) => {
    dispatch(createItem({productId: selectedProduct._id, quantity: 1}))
    history.push({pathname: path})
    dispatch({
      type: ORDER_STATUS,
      payload: "cart"
    })
  }

  const cartClickHandler = (path) => {
    history.push({pathname: path})
    dispatch({
      type: ORDER_STATUS,
      payload: "cart"
    })
  }

  return (
    <div>
      <ErrorModal />
      <SuccessModal />
      <div id="home-screen">
        <div className="logo-lang">
          <Row
            className="p-0 m-0 justify-content-between"
            style={{backgroundColor: "orange", color: "white", width: "100%"}}
          >
            <Col
              className="p-0 mr-1"
              style={{
                justifyContent: "left",
                maxWidth: "25%"
              }}
            >
              <div
                className="country"
                style={{
                  border: currency === "AED" ? "1px solid var(--Primary)" : "0"
                }}
                // onClick={() => handleCurrency("AED")}
              >
                <img
                  src={UAEFlag}
                  style={{height: "2.5vw", margin: "3px", maxHeight: "25px"}}
                />
                {/* <p style={{padding: "0", margin: "0"}}>{t("UAE")}</p> */}
              </div>
              <div className="countries">
                <div
                  className="country"
                  style={{
                    border:
                      currency === "EGP" ? "1px solid var(--Primary)" : "0"
                  }}
                  onClick={() => handleCurrency("EGP")}
                >
                  <img
                    src={EGYPTFlag}
                    style={{height: "2.5vw", margin: "3px", maxHeight: "25px"}}
                  />
                  {/* <p style={{padding: "0", margin: "0"}}>{t("EGYPT")}</p> */}
                </div>
              </div>
            </Col>
            <Col style={{direction: t("Dir")}}>
              <Row style={{fontSize: "0.9rem", color: "var(--Primary)"}}>
                {siteMeta.freeShippingFromItemX === 1 ? (
                  t("Free_Shipping")
                ) : (
                  <div className="deal" style={{fontSize: "0.65rem"}}>
                    {/* {t("Offer")} */}
                    {t("Buy_X_Get_Free_Shipping").replaceAll(
                      "[X]",
                      t(siteMeta.freeShippingFromItemX)
                    )}
                  </div>
                )}
              </Row>
              {/* <Row style={{fontSize: "0.6rem", color: "white"}}>
                {siteMeta?.coupon_value > 0 &&
                  t("First_Order_Deal").replaceAll(
                    "[coupon_value]",
                    siteMeta.coupon_value
                  )}
              </Row> */}
              {/* <Row style={{fontSize: "0.5rem", color: "white", padding: "3px"}}>
                {t("Made_In")}
              </Row> */}
            </Col>
            <Col
              className="p-0 mr-1"
              style={{
                justifyContent: "right",
                maxWidth: "25%"
              }}
            >
              {i18n.language === "ar" && (
                <div
                  className="lang-button"
                  onClick={() => {
                    localStorage.setItem("lang", "en")
                    localStorage.setItem("dir", "ltr")
                    updateCart({lang: "en"})
                    i18n.changeLanguage("en")
                  }}
                >
                  English
                </div>
              )}
              {i18n.language === "en" && (
                <div
                  className="lang-button"
                  onClick={() => {
                    localStorage.setItem("lang", "ar")
                    localStorage.setItem("dir", "rtl")
                    updateCart({lang: "ar"})
                    i18n.changeLanguage("ar")
                  }}
                >
                  عربي
                </div>
              )}
            </Col>
          </Row>
          <div
            style={{
              padding: "0",
              margin: "0",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                width: "50%",
                padding: "10px"
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "30%",
                  minWidth: "100px"
                }}
              />
            </div>
            <div
              style={{width: "50%", textAlign: "end", padding: "0 10px"}}
              onClick={() => cartClickHandler("/checkout")}
            >
              <div className="cart-container">
                <div className={`cart-icon ${loading ? "rotate" : ""}`}>🛒</div>
                {!loading && (
                  <div className="badge">
                    {items?.reduce((acc, item) => acc + item.quantity, 0) || 0}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "100px 0 0 0"
          }}
        >
          {/* <div className="countries-section">
            <p style={{fontSize: "1rem", padding: "0", margin: "0"}}>
              {t("Available_In")}
            </p>
            <div className="countries">
              <div
                className="country"
                style={{
                  border: currency === "EGP" ? "1px solid var(--Primary)" : "0"
                }}
                onClick={() => handleCurrency("EGP")}
              >
                <img
                  src="https://i.postimg.cc/VNKVH6VM/1200px-Flag-of-Egypt-variant.png"
                  style={{height: "15vw", margin: "5px", maxHeight: "25px"}}
                />
                <p style={{padding: "0", margin: "0"}}>{t("EGYPT")}</p>
              </div>
              <div
                className="country"
                style={{
                  border: currency === "AED" ? "1px solid var(--Primary)" : "0"
                }}
                onClick={() => handleCurrency("AED")}
              >
                <img
                  src="https://i.postimg.cc/50jRcVhr/Flag-of-the-United-Arab-Emirates.png"
                  style={{height: "15vw", margin: "5px", maxHeight: "25px"}}
                />
                <p style={{padding: "0", margin: "0"}}>{t("UAE")}</p>
              </div>
            </div>
          </div> */}
          {productDetails.length > 1 && (
            <div
              style={{
                marginTop: "11.5vw",
                textAlign: "center",
                // border: "1px var(--Primary) solid",
                borderRadius: "5px"
              }}
            >
              <h5>{t("Our_Products")}</h5>
              <div className="scrollable-container">
                {productDetails.map((product, index) => (
                  <div
                    key={product._id}
                    className={
                      selectedProduct._id === product._id
                        ? "product-card selected"
                        : "product-card"
                    }
                    onClick={() => setSelectedProduct(product)}
                  >
                    <img
                      src={product.mainImage}
                      alt={product.name[t("lang")]}
                    />
                    {/* <div className="product-details">
                      <div className="product-info">
                        {product.name[t("lang")]}
                      </div>
                      <div className="pricing">
                        <span className="original-price">
                          {(
                            product.price[currency] *
                            (1 + product.discount)
                          ).toFixed(0)}{" "}
                          LE
                        </span>
                        <span className="discounted-price">
                          {product.price[currency]} LE
                        </span>
                      </div>
                    </div> */}
                  </div>

                  // <img
                  //   key={index}
                  //   onClick={() => setSelectedProduct(product)}
                  //   src={product.mainImage}
                  //   style={{
                  //     height: "7em",
                  //     margin: "3px",
                  //     padding: "3px",
                  //     border:
                  //       product._id === selectedProduct._id
                  //         ? "4px var(--Primary) solid"
                  //         : "1px var(--Primary) solid",
                  //     borderRadius: "5px"
                  //   }}
                  // />
                ))}
              </div>
            </div>
          )}
          {/* {selectedProduct?.video && (
            <div style={{position: "relative", textAlign: "center"}}>
              <video
                src={selectedProduct.video}
                ref={videoRef}
                autoPlay
                muted
                loop={true}
                style={{maxWidth: "500px", width: "100%", padding: "5px"}}
              />
              <i
                className={muted ? `fas fa-volume-mute` : `fas fa-volume-up`}
                style={{
                  color: "white",
                  fontSize: "24px",
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  cursor: "pointer"
                }}
                onClick={() => {
                  toggleSound()
                }}
              />
            </div>
          )} */}
          <Row className="margin-top">
            {/* <div className="player-wrapper">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=7vu9KHS98Dg"
              playing={true}
              controls={false}
              muted={true} // Mute the video
              width="100%"
              height="100%"
              config={{
                youtube: {
                  playerVars: {
                    controls: 0, // Hide YouTube controls
                    modestbranding: 1,
                    fs: 0,
                    iv_load_policy: 3,
                    showinfo: 0,
                    rel: 0,
                    autoplay: 1
                  }
                }
              }}
            />
          </div> */}
            {/* <OfferCard /> */}
          </Row>
          <div className="container" style={{direction: t("Dir")}}>
            <Row>
              <p
                style={{
                  color: "var(--Primary)",
                  fontSize: "2.5rem"
                }}
              >
                {selectedProduct.catchy1.title[t("lang")]}
              </p>
            </Row>
            <Row
              className="p-0 m-0"
              style={{textAlign: "center", borderRadius: "10%"}}
            >
              <img
                src={selectedProduct.catchy1.image}
                style={{width: "100%"}}
              />
            </Row>
            <Row>
              <p
                style={{
                  textAlign: "start",
                  marginTop: "5px",
                  fontSize: "1.5rem"
                }}
              >
                <span
                  style={{
                    color: "var(--Orange-color)",
                    fontSize: "1.8rem"
                  }}
                >
                  {selectedProduct.catchy1.Product_Description1[t("lang")]}
                </span>{" "}
                {selectedProduct.catchy1.Product_Description2[t("lang")]}
              </p>
            </Row>
            <Row>
              <img
                src="https://i.postimg.cc/nhBJ82yW/Why-From-Edited.gif"
                style={{width: "100%", borderRadius: "5%"}}
              />
            </Row>
            <Row
              className="p-0 m-0"
              style={{textAlign: "start", alignItems: "start"}}
            >
              <Col className="p-0 m-0" md={6}>
                {/* image before and after */}
                <Row className="p-0 m-0" style={{textAlign: "start"}}>
                  <Col className="p-1 m-0" xs={12}>
                    <img
                      src={selectedProduct.catchy1.image}
                      style={{width: "100%", borderRadius: "5%"}}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="p-0 m-0" md={6} style={{textAlign: "center"}}>
                {/* {selectedProduct?.variant2.length > 0 && (
                  <p>{t("Select_Color_Note")}</p>
                )} */}
                {/* <Row className="p-0 m-0" style={{textAlign: "start"}}>
                  {selectedProduct?.variant2?.map((variant, index) => (
                    <Col className="p-1 m-0">
                      <img
                        key={index}
                        src={variant.image}
                        style={{
                          width: "100%",
                          border: "1px solid grey",
                          borderRadius: "10%"
                        }}
                      />
                    </Col>
                  ))}
                </Row> */}
                {/* <Row className="p-0 m-0" style={{textAlign: "start"}}>
                  {selectedProduct?.variant1?.map((variant, index) => (
                    <Col
                      key={index}
                      className="p-1 m-1"
                      xs={12}
                      style={{
                        border: "1px solid green",
                        borderRadius: "5px",
                        alignItems: "center"
                      }}
                    >
                      <p
                        style={{textAlign: "center", padding: "0", margin: "0"}}
                      >
                        {variant.name[t("lang")]} (
                        {variant.description[t("lang")]})
                      </p>
                    </Col>
                  ))}
                </Row> */}
                <Row className="p-0 m-0">
                  <Col sm={12}>
                    <h4 style={{color: "var(--Primary)"}}>
                      {selectedProduct.discount * 100}% {t("Off")}
                      {/* +{" "}
                      {t("Free_Cover")} */}
                    </h4>
                    <h1 style={{color: "green"}}>
                      {t(currency)} {selectedProduct.price[currency]}
                      <span style={{color: "red", fontSize: "20px"}}>
                        {" "}
                        <del>
                          {t(currency)}
                          {(
                            selectedProduct.price[currency] *
                            (1 + selectedProduct.discount)
                          ).toFixed(0)}
                        </del>
                      </span>
                    </h1>
                  </Col>
                  <Col sm={12}>
                    <Button
                      ref={addToCartRef}
                      id="initCheckoutButton"
                      className="rounded"
                      style={{width: "100%", backgroundColor: "var(--Primary)"}}
                      onClick={() => addToCartHandler("/checkout")}
                    >
                      <div className="deal">{t("Buy_Now")}</div>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <p style={{color: "var(--Primary)", fontSize: "1.5rem"}}>
              {selectedProduct.catchy2.title[t("lang")]}
            </p>

            <p style={{textAlign: "start"}}>
              <span
                style={{
                  color: "var(--Orange-color)",
                  fontSize: "1rem"
                }}
              >
                {selectedProduct?.catchy2?.Product_Description1[t("lang")]}
              </span>
              {selectedProduct?.catchy2?.Product_Description2[t("lang")]}
            </p>

            <img
              src={selectedProduct?.catchy2?.image}
              style={{width: "100%"}}
            />

            {reviewsData?.length > 0 && (
              <Reviews
                reviews={reviewsData.reverse()}
                title={t("Customer_Reviews")}
              />
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                border: "1px var(--Primary) solid",
                borderRadius: "5%",
                width: "100%"
              }}
            >
              <div style={{display: "flex", width: "50%"}}>
                <div className="notes-icon">
                  <MdDirectionsBike />
                </div>
                <div style={{margin: "5px"}}>
                  <h3 className="notes-header">{t("Return_Note")}</h3>
                  <p className="notes-details">{t("Return_Sub_Note")}</p>
                </div>
              </div>
              <div style={{display: "flex", width: "50%"}}>
                <div className="notes-icon">
                  <IoIosRefresh />
                </div>
                <div style={{margin: "5px"}}>
                  <h3 className="notes-header">{t("Fast_Shipping")}</h3>
                  <p className="notes-details">{t("Shipping_Sub_Note")}</p>
                </div>
              </div>
            </div>

            <p style={{color: "var(--Primary)", fontSize: "1.5rem"}}>
              {t("More_Details")}
            </p>

            <Row
              className="p-0 m-0"
              style={{
                alignItems: "center"
              }}
            >
              <Col className="p-0 m-0" md={6}>
                <img
                  src={selectedProduct.bulletPoints.image}
                  style={{width: "100%"}}
                />
              </Col>
              <Col
                style={{
                  fontSize: "1.1rem",
                  direction: t("Dir"),
                  textAlign: "start"
                }}
                className="p-0 m-0"
                md={6}
              >
                <ul style={{margin: "0", padding: "30px"}}>
                  {selectedProduct?.bulletPoints?.list[t("lang")]?.map(
                    (bulletPoint, index) => (
                      <li key={index}>{bulletPoint}</li>
                    )
                  )}
                </ul>
              </Col>
            </Row>

            <p
              style={{
                color: "var(--Primary)",
                fontSize: "1.15rem"
              }}
            >
              {selectedProduct?.name[t("lang")]}
            </p>
            {selectedProduct?.moreDetails?.map((record, index) => (
              <Row
                key={index}
                className="p-0 m-0"
                style={{alignItems: "center"}}
              >
                <Col className="p-1 m-0" md={6}>
                  {record?.images?.map((image, index) => (
                    <img key={index} src={image} style={{width: "50%"}} />
                  ))}
                </Col>
                <Col
                  className="p-1 m-0"
                  md={record?.images?.length ? 6 : 12}
                  style={{textAlign: "start"}}
                >
                  <p
                    style={{
                      color: "var(--Secondary)",
                      fontSize: "1.5rem"
                    }}
                  >
                    {record?.title ? record?.title[t("lang")] : ""}
                  </p>
                  {record?.paragraphs
                    ? record?.paragraphs[t("lang")]?.map((paragraph, index) => (
                        <p
                          style={{
                            color: "var(--Primary)",
                            fontSize: "1.1rem"
                          }}
                          key={index}
                        >
                          {paragraph}
                        </p>
                      ))
                    : ""}
                </Col>
              </Row>
            ))}
          </div>
        </div>
        {/* {isVisible && (
          <div className="stickyBar">
            <div className="divisions">
              <div className="details">
                <div className="image">
                  <img
                    style={{width: "100%"}}
                    src={selectedProduct.mainImage}
                    alt={selectedProduct.name[t("lang")]}
                  />
                </div>
                <div className="name">{selectedProduct.name[t("lang")]}</div>
              </div>
              <div className="price-add">
                <div className="price">
                  {t(currency)} {selectedProduct.price[currency]}
                </div>
                <Button
                  className="button"
                  onClick={() => addToCartHandler("/checkout")}
                >
                  {t("Buy_Now")}
                </Button>
              </div>
            </div>
          </div>
        )} */}
      </div>

      <Footer />
    </div>
  )
}

export default HomeScreen
