import axios from "axios"
import {toastMessages} from "./toastMessages"
import {
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS
} from "../constants/spsOrderUpdateConstant"
import {GET_ORDER_SUCCESS} from "../constants/spsOrderGetConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const duplicateOrder = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const {data} = await axios.patch(
      `${base_url}/sps-orders/duplicate/${id}`,
      {},
      config
    )
    dispatch(toastMessages(1, "Updated Successfuly"))
  } catch (error) {
    dispatch(toastMessages(0, error.response.data.message))
  }
}
