import React from "react"
import SPSSearchOrderBy from "../components/SPS/SPSSearchOrderBy"
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap"

import "./Tickets.scss"
import {useState} from "react"
import {useSelector} from "react-redux"
import Loader from "../components/Loader"
import SPSSelectedItemTickets from "../components/SPS/SPSSelectedItemTickets"

const TicketsScreen = () => {
  const spsOrderGet = useSelector((state) => state.spsOrderGet)
  const {loading, orders} = spsOrderGet

  const [selectedOrder, setSelectedOrder] = useState({})
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(0)

  const handleSelected = (order, index) => {
    setSelectedOrder(order)
    setSelectedOrderIndex(index)
  }

  const [selectedItem, setSelectedItem] = useState({})
  const [selectedItemIndex, setSelectedItemIndex] = useState("")

  const handleSelectedItem = (item, itemIndex) => {
    setSelectedItem(item)
    setSelectedItemIndex(itemIndex)
  }

  return (
    <div className="main-content">
      <Row className="m-2 p-2" style={{width: "100%"}}>
        <Col
          sm={8}
          className="p-0 m-0"
          style={{
            border: "1px grey dashed",
            borderRadius: "5px",
            alignContent: "center"
          }}
        >
          <SPSSearchOrderBy />
          {loading ? (
            <Loader />
          ) : (
            <div style={{display: "flex"}}>
              {orders?.map((order, index) => (
                <Card
                  onClick={() => handleSelected(order, index)}
                  key={order._id}
                  style={{
                    margin: "3px",
                    border: `${
                      order._id === selectedOrder._id
                        ? `2px green solid`
                        : `1px grey solid`
                    }`
                  }}
                >
                  <p>{order._id}</p>
                  <p>{order.fullName}</p>
                  <p>
                    {order.shippingCompany}: {order.trackingNumber}
                  </p>
                  <p>{order.realCreatedAt}</p>
                </Card>
              ))}
            </div>
          )}
          <div style={{display: "flex"}}>
            {selectedOrder?.items?.map((item, index) => (
              <Card
                onClick={() => handleSelectedItem(item, index)}
                key={item._id}
                style={{
                  margin: "3px",
                  border: `${
                    item._id === selectedItem._id
                      ? `2px green solid`
                      : `1px grey solid`
                  }`
                }}
              >
                <p>{item.status}</p>
                <p>{item.name.ar}</p>
                <p>{item.quantity}</p>
              </Card>
            ))}
          </div>
          <SPSSelectedItemTickets
            selectedItem={selectedItem}
            selectedOrderIndex={selectedOrderIndex}
            selectedItemIndex={selectedItemIndex}
          />
        </Col>
        {/* <Col
          className="p-0 m-0"
          sm={4}
          style={{
            border: "1px grey dashed",
            borderRadius: "5px"
          }}
        >
          {selectedOrder?.items && selectedItem?.tickets
            ? selectedOrder?.items[selectedItemIndex].tickets?.map(
                (ticket, index) => <Card key={index}>{ticket.clientName}</Card>
              )
            : ""}
        </Col> */}
      </Row>
    </div>
  )
}

export default TicketsScreen
