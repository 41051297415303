import {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {getSLReports} from "../actions/SLReportActions"
import MaterialTable from "material-table"
import Loader from "../components/Loader"

const AdvancedTable = () => {
  const dispatch = useDispatch()

  const SLReport = useSelector((state) => state.SLReport)
  const {SLReportsLists, loading} = SLReport

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    dispatch(getSLReports("test", SLReportsLists || {}))
  }, [])
  //   "TO_first_name": "Wireless",
  //   "TO_last_name": "Vision",
  //   "TO_address": "11446 Cherry Hill Rd",
  //   "TO_address2": null,
  //   "TO_city": "Beltsville",
  //   "TO_zip_postal_code": "20705-3617",
  //   "TO_abbreviation": "MD",
  //   "Pounds": 10.0,
  //   "Ounces": 7.0,
  //   "L": 12.0,
  //   "W": "10",
  //   "H": "8",
  //   "phone num1": null,
  //   "phone num2": 6025710057.0,
  //   "Item-sku": null,
  //   "size": "4x6in",
  const columns = [
    {
      field: "FROM_first_name",
      title: "First Name"
    },
    {
      field: "FROM_last_name",
      title: "Last Name"
    },
    {
      field: "FROM_address",
      title: "Address"
    },
    {
      field: "FROM_address2",
      title: "Address2"
    },
    {
      field: "FROM_city",
      title: "City"
    },
    {
      field: "FROM_zip_postal_code",
      title: "From ZPC"
    },
    {
      field: "FROM_abbreviation",
      title: "FROM Abbreviation"
    },
    {
      field: "Shipping Service",
      title: "Shipping Service"
    },
    {
      field: "order num (Client)",
      title: "order num (Client)"
    },
    {
      field: "Tracking NO",
      title: "Tracking NO"
    },
    {
      field: "ORDER #",
      title: "ORDER #"
    },
    {
      field: "EMAIL",
      title: "EMAIL"
    },
    {
      field: "DOMAIN",
      title: "DOMAIN"
    },
    {
      field: "DATE",
      title: "DATE"
    }
  ]

  // const table = useMaterialReactTable({
  //   columns,
  //   data: SLReportsLists?.one_month_data || [],
  //   enableRowSelection: true,
  //   getRowId: (row) => row.phoneNumber,
  //   initialState: {showColumnFilters: true},
  //   manualFiltering: true,
  //   manualPagination: true,
  //   manualSorting: true,
  //   muiToolbarAlertBannerProps: isError
  //     ? {
  //         color: "error",
  //         children: "Error loading data"
  //       }
  //     : undefined,
  //   onColumnFiltersChange: setColumnFilters,
  //   onGlobalFilterChange: setGlobalFilter,
  //   onPaginationChange: setPagination,
  //   onSortingChange: setSorting,
  //   rowCount,
  //   state: {
  //     columnFilters,
  //     globalFilter,
  //     isLoading,
  //     pagination,
  //     showAlertBanner: isError,
  //     showProgressBars: isRefetching,
  //     sorting
  //   }
  // })

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <></>
        // <MaterialTable
        //   columns={columns}
        //   data={SLReportsLists?.one_month_data || []}
        // />
      )}
    </div>
  )
}

export default AdvancedTable
