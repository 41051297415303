import axios from "axios"
import {
  GET_CART_ITEMS_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS
} from "../constants/spsCartConstant"
import {toastMessages} from "./toastMessages"
import i18next from "i18next"
import siteMeta from "../assets/siteMeta.json"

export async function generateCartId(payload) {
  try {
    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }

    payload["lang"] = i18next.language
    payload["store"] = siteMeta.siteName.en.toLowerCase()

    const {data} = await axios.post(
      `https://stmarkmaadi.org/api/ar/sps-cart`,
      payload,
      config
    )
    await localStorage.setItem("cartId", data._id)
    return data._id
  } catch (error) {
    console.error(error)
  }
}

export async function checkCartId(id, payload) {
  try {
    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }

    let {data} = await axios.get(
      `https://stmarkmaadi.org/api/ar/sps-cart?_id=${id}`,
      {},
      config
    )
    if (!data?.length) {
      try {
        let cartId = await generateCartId(
          (payload = {
            lang: i18next.language,
            store: siteMeta.siteName.en.toLowerCase()
          })
        )
        await localStorage.setItem("cartId", cartId)
        return cartId
      } catch (error) {
        console.error(error)
      }
    }
    return data[0]._id
  } catch (error) {
    console.error(error)
  }
}

export async function updateCart(payload) {
  let cartId = localStorage.getItem("cartId")
  if (!cartId) {
    const payload = {
      lang: i18next.language,
      store: siteMeta.siteName.en.toLowerCase()
    }
    await generateCartId(payload)
  } else {
    cartId = await checkCartId(cartId, payload)
  }

  try {
    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }

    await axios.patch(
      `https://stmarkmaadi.org/api/ar/sps-cart/${cartId}`,
      payload,
      config
    )
  } catch (error) {
    console.error(error)
  }
}

export const getCartItems = () => async (dispatch, getState) => {
  let cartId = localStorage.getItem("cartId")
  if (cartId)
    try {
      dispatch({
        type: GET_CART_ITEMS_REQUEST
      })

      const config = {
        headers: {
          "Content-type": "application/json"
        }
      }
      const {data} = await axios.get(
        `https://stmarkmaadi.org/api/ar/sps-item?cartId=${cartId}&stage=CART`,
        {},
        config
      )
      dispatch({
        type: GET_CART_ITEMS_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_CART_ITEMS_FAIL
      })
      dispatch(toastMessages(0, error.response.data.message))
    }
}
