import {
  UPDATE_STOCK_LOG_FAIL,
  UPDATE_STOCK_LOG_REQUEST,
  UPDATE_STOCK_LOG_SUCCESS,
  GET_STOCK_LOG_FAIL,
  GET_STOCK_LOG_REQUEST,
  GET_STOCK_LOG_SUCCESS
} from "../constants/spsStockLogConstant"

export const spsStockLogGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STOCK_LOG_REQUEST:
      return {loading: true}

    case GET_STOCK_LOG_SUCCESS:
      return {loading: false, store_stock_log_list: action.payload}

    case GET_STOCK_LOG_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsStockLogUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_STOCK_LOG_REQUEST:
      return {loading: true}

    case UPDATE_STOCK_LOG_SUCCESS:
      return {loading: false}

    case UPDATE_STOCK_LOG_FAIL:
      return {loading: false}

    default:
      return state
  }
}
