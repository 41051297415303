import {
  TICKET_FAIL,
  TICKET_REQUEST,
  TICKET_SUCCESS
} from "../constants/ticketConstants"

export const ticketReducer = (state = {}, action) => {
  switch (action.type) {
    case TICKET_REQUEST:
      return {...state, loading: true}

    case TICKET_SUCCESS:
      return {...state, loading: false, item_tickets: action.payload}

    case TICKET_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}
