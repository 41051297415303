import axios from "axios"
import {toastMessages} from "./toastMessages"
import {
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS
} from "../constants/spsOrderUpdateConstant"
import {GET_ORDER_SUCCESS} from "../constants/spsOrderGetConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const updateOrder =
  (id, payload, index, ordersList) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ORDER_REQUEST
      })
      const {
        userLogin: {userInfo}
      } = getState()

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`
        }
      }
      const {data} = await axios.patch(
        `${base_url}/sps-orders/${id}`,
        payload,
        config
      )

      ordersList[index] = data
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: ordersList
      })

      dispatch({
        type: UPDATE_ORDER_SUCCESS
      })
      dispatch(toastMessages(1, "Updated Successfuly"))
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAIL
      })
      dispatch(toastMessages(0, error.response.data.message))
    }
  }

export const addComment =
  (id, payload, index, ordersList) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ORDER_REQUEST
      })

      const config = {
        headers: {
          "Content-type": "application/json"
        }
      }
      const {data} = await axios.patch(
        `${base_url}/sps-orders/shipper-note/${id}`,
        payload,
        config
      )

      ordersList[index] = data
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: ordersList
      })

      dispatch({
        type: UPDATE_ORDER_SUCCESS
      })
      dispatch(toastMessages(1, "Updated Successfuly"))
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAIL
      })
      dispatch(toastMessages(0, error.response.data.message))
    }
  }

export const delivered =
  (id, index, ordersList) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ORDER_REQUEST
      })

      const config = {
        headers: {
          "Content-type": "application/json"
        }
      }
      const {data} = await axios.patch(
        `${base_url}/sps-orders/delivered/${id}`,
        {},
        config
      )

      ordersList[index] = data
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: ordersList
      })

      dispatch({
        type: UPDATE_ORDER_SUCCESS
      })
      dispatch(toastMessages(1, "Updated Successfuly"))
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAIL
      })
      dispatch(toastMessages(0, error.response.data.message))
    }
  }

export const toPick = (id, index, ordersList) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_ORDER_REQUEST
    })

    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }
    const {data} = await axios.patch(
      `${base_url}/sps-orders/pick-up/${id}`,
      {},
      config
    )

    ordersList[index] = data
    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: ordersList
    })

    dispatch({
      type: UPDATE_ORDER_SUCCESS
    })
    dispatch(toastMessages(1, "Updated Successfuly"))
  } catch (error) {
    dispatch({
      type: UPDATE_ORDER_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}
