import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {Modal} from "react-bootstrap"

function ErrorModal() {
  const errorAlert = useSelector((state) => state.errorAlert)
  const {status, message} = errorAlert

  return (
    <>
      <Modal
        size="lg"
        show={status}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/* <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Attention</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <h3
            style={{
              fontWeight: "bold",
              color: "red",
              // fontSize: "14px",
              textAlign: "center"
            }}
          >
            {message}
          </h3>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ErrorModal
