import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {
  PRODUCT_BUILD,
  PRODUCT_BUILD_MORE
} from "../../constants/spsProductConstant"
import "./SPSCreateProduct.scss"

const SPSCreateProductMore = () => {
  const dispatch = useDispatch()

  const spsProductBuild = useSelector((state) => state.spsProductBuild)
  const {product_details} = spsProductBuild

  const spsProductBuildMore = useSelector((state) => state.spsProductBuildMore)
  const {product_more_details} = spsProductBuildMore

  useEffect(() => {}, [dispatch])

  const handleTitle = (para, parameterValue) => {
    let newProductDetails = JSON.parse(
      JSON.stringify(product_more_details || {})
    )
    if (!newProductDetails["title"]) newProductDetails["title"] = {}

    if (!newProductDetails["title"][para]) newProductDetails["title"][para] = ""

    newProductDetails["title"][para] = parameterValue
    dispatch({type: PRODUCT_BUILD_MORE, payload: newProductDetails})
  }

  const handleImages = (imageLink) => {
    let newProductDetails = JSON.parse(
      JSON.stringify(product_more_details || {})
    )
    if (!newProductDetails["images"]) {
      newProductDetails["images"] = []
    }
    if (imageLink !== "") newProductDetails.images.push(imageLink)

    dispatch({type: PRODUCT_BUILD_MORE, payload: newProductDetails})
  }

  const handleImageAction = (index, action) => {
    let newProductDetails = product_more_details
    let list = product_more_details.images

    if (action === "delete") list.splice(index, 1)

    if (action === "left") {
      let firstIndex = index - 1
      let secondIndex = index

      let firstElement = list.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = list.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      list.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      list.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    if (action === "right") {
      let firstIndex = index
      let secondIndex = index + 1

      let firstElement = list.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = list.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      list.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      list.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    newProductDetails["images"] = list
    dispatch({type: PRODUCT_BUILD_MORE, payload: newProductDetails})
  }

  const handleParagraphs = (parameterName, parameterValue) => {
    let newProductDetails = JSON.parse(
      JSON.stringify(product_more_details || {})
    )
    if (!newProductDetails["paragraphs"]) newProductDetails["paragraphs"] = {}

    if (!newProductDetails["paragraphs"][parameterName])
      newProductDetails["paragraphs"][parameterName] = []

    newProductDetails.paragraphs[parameterName].push(parameterValue)
    dispatch({type: PRODUCT_BUILD_MORE, payload: newProductDetails})
  }

  const handleParagraphsAction = (parameterName, index, action) => {
    let newProductDetails = product_more_details
    let list = product_more_details.paragraphs[parameterName]

    if (action === "delete") list.splice(index, 1)

    if (action === "left") {
      let firstIndex = index - 1
      let secondIndex = index

      let firstElement = list.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = list.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      list.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      list.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    if (action === "right") {
      let firstIndex = index
      let secondIndex = index + 1

      let firstElement = list.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = list.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      list.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      list.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    newProductDetails["images"] = list
    dispatch({type: PRODUCT_BUILD_MORE, payload: newProductDetails})
  }

  const handleGroupsAction = (index, action) => {
    let newProductDetails = product_details
    let list = product_details.moreDetails

    if (action === "delete") list.splice(index, 1)

    if (action === "left") {
      let firstIndex = index - 1
      let secondIndex = index

      let firstElement = list.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = list.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      list.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      list.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    if (action === "right") {
      let firstIndex = index
      let secondIndex = index + 1

      let firstElement = list.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = list.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      list.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      list.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }
    newProductDetails["moreDetails"] = list
    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
  }

  const handleAdd = () => {
    let newProductDetails = product_details
    let list = newProductDetails?.moreDetails || []
    // console.log(product_more_details)
    list.push(product_more_details)
    newProductDetails["moreDetails"] = list
    console.log(newProductDetails)
    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
    dispatch({type: PRODUCT_BUILD_MORE, payload: {}})
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px"
        }}
      >
        <h4>More Details</h4>{" "}
        <Button
          variant="success"
          size="md"
          style={{borderRadius: "5px", padding: "5px"}}
          onClick={() => handleAdd()}
        >
          Add
        </Button>
      </div>

      <h6>title</h6>
      <div className="section">
        <Form.Control
          placeholder="En Title"
          className="frm"
          type="text"
          defaultValue={product_more_details?.title?.en || ""}
          onChange={(e) => handleTitle("en", e.target.value)}
        ></Form.Control>
        <Form.Control
          placeholder="Ar Title"
          className="frm"
          type="text"
          defaultValue={product_more_details?.title?.ar || ""}
          onChange={(e) => handleTitle("ar", e.target.value)}
        ></Form.Control>
      </div>

      {/* Add More Image Section */}
      <h6>images</h6>
      <div className="section">
        <Form.Control
          placeholder="Image"
          className="frm"
          type="text"
          defaultValue=""
          onChange={(e) => handleImages(e.target.value)}
        ></Form.Control>
      </div>
      {/* End Add More Image Section */}

      {/* More Images List Section */}

      <div className="section">
        {product_more_details?.images?.map((image, index) => (
          <div className="image" key={index}>
            <img src={image} style={{margin: "5px", maxWidth: "50px"}} />
            <div style={{display: "flex"}}>
              {index !== 0 && (
                <i
                  className="fas fa-arrow-left ico"
                  onClick={() => handleImageAction(index, "left")}
                ></i>
              )}
              {index + 1 !== product_more_details?.images?.length && (
                <i
                  className="fas fa-arrow-right ico"
                  onClick={() => handleImageAction(index, "right")}
                ></i>
              )}
              <i
                className="fas fa-trash-alt ico"
                onClick={() => handleImageAction(index, "delete")}
              ></i>
            </div>
          </div>
        ))}
      </div>

      <h6>paragraphs</h6>

      <div className="section">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            padding: "5px"
          }}
        >
          <Form.Control
            placeholder="En paragraph"
            className="frm"
            type="text"
            onChange={(e) => handleParagraphs("en", e.target.value)}
          ></Form.Control>

          {product_more_details?.paragraphs?.en?.map((item, index) => (
            <div style={{display: "flex", alignItems: "center"}} key={index}>
              <Form.Control
                placeholder={`En paragraphs ${index + 1}`}
                className="frm"
                type="text"
                readOnly
                defaultValue={item}
              ></Form.Control>

              {index !== 0 && (
                <i
                  className="fas fa-arrow-up ico"
                  onClick={() => handleParagraphsAction("en", index, "left")}
                ></i>
              )}
              {index + 1 !==
                product_more_details?.bulletPoints?.list?.en?.length && (
                <i
                  className="fas fa-arrow-down ico"
                  onClick={() => handleParagraphsAction("en", index, "right")}
                ></i>
              )}
              <i
                className="fas fa-trash-alt ico"
                onClick={() => handleParagraphsAction("en", index, "delete")}
              ></i>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            padding: "5px"
          }}
        >
          <Form.Control
            placeholder="Ar Paragraph"
            className="frm"
            type="text"
            onChange={(e) => handleParagraphs("ar", e.target.value)}
          ></Form.Control>

          {product_more_details?.paragraphs?.ar?.map((item, index) => (
            <div style={{display: "flex", alignItems: "center"}} key={index}>
              <Form.Control
                placeholder={`Ar paragraph ${index + 1}`}
                className="frm"
                type="text"
                readOnly
                defaultValue={item}
              ></Form.Control>
              {index !== 0 && (
                <i
                  className="fas fa-arrow-up ico"
                  onClick={() => handleParagraphsAction("ar", index, "left")}
                ></i>
              )}
              {index + 1 !== product_more_details?.paragraphs?.ar?.length && (
                <i
                  className="fas fa-arrow-down ico"
                  onClick={() => handleParagraphsAction("ar", index, "right")}
                ></i>
              )}
              <i
                className="fas fa-trash-alt ico"
                onClick={() => handleParagraphsAction("ar", index, "delete")}
              ></i>
            </div>
          ))}
        </div>
      </div>
      {/* End of More Images List Section */}

      <hr className="line" />

      <h4>View More Details</h4>
      {product_details?.moreDetails?.map((record, index) => (
        <Row key={index} className="p-0 m-0" style={{alignItems: "center"}}>
          <Col className="p-1 m-0" md={5}>
            {record?.images?.map((image, index) => (
              <img key={index} src={image} style={{width: "50%"}} />
            ))}
          </Col>
          <Col
            className="p-1 m-0"
            md={record?.images?.length ? 5 : 10}
            style={{textAlign: "start"}}
          >
            <p
              style={{
                color: "var(--Secondary)",
                fontSize: "0.9rem"
              }}
            >
              {record?.title?.en} / {record?.title?.ar}
            </p>
            {record?.paragraphs?.en.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            {record?.paragraphs?.ar.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </Col>
          <Col className="p-1 m-0" md={2}>
            {index !== 0 && (
              <i
                style={{color: "orange"}}
                className="fas fa-arrow-up ico"
                onClick={() => handleGroupsAction(index, "left")}
              ></i>
            )}
            {index + 1 !== product_details?.moreDetails?.length && (
              <i
                style={{color: "orange"}}
                className="fas fa-arrow-down ico"
                onClick={() => handleGroupsAction(index, "right")}
              ></i>
            )}
            <i
              style={{color: "orange"}}
              className="fas fa-trash-alt ico"
              onClick={() => handleGroupsAction(index, "delete")}
            ></i>
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default SPSCreateProductMore
