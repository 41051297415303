import React from "react"
import "./Stocks.scss"
import SPSStoreStocks from "../components/SPS/SPSStoreStocks"
import SPSStoreStocksDetails from "../components/SPS/SPSStoreStocksDetails"
import SPSStoreStocksLogs from "../components/SPS/SPSStoreStocksLogs"

const Stocks = () => {
  return (
    <div id="stocks">
      <SPSStoreStocks />
      <div style={{display: "flex"}}>
        <SPSStoreStocksDetails style={{}} />
        <SPSStoreStocksLogs />
      </div>
    </div>
  )
}
export default Stocks
