import React, {useState} from "react"
import {FaShoppingCart, FaCogs, FaShippingFast} from "react-icons/fa"
import "./OrderStatus.scss"
import {ORDER_STATUS} from "../../constants/spsOrderStatusConstant"
import {useDispatch, useSelector} from "react-redux"
import {getMyOrders} from "../../actions/spsOrderGetActions"
import {getCartItems} from "../../actions/spsCartActions"

function OrderStatus() {
  const dispatch = useDispatch()

  const spsOrderStatus = useSelector((state) => state.spsOrderStatus)
  const {status} = spsOrderStatus

  const handleStatus = (newStatus) => {
    if (
      status != newStatus &&
      newStatus === "cart" &&
      (localStorage.getItem("cartId") || 0)
    )
      dispatch(getCartItems())

    if (
      status != newStatus &&
      newStatus === "preparation" &&
      (localStorage.getItem("cartId") || 0)
    )
      dispatch(
        getMyOrders(
          `?status=PENDING,CONFIRMED,TOSHIP&cartId=${localStorage.getItem(
            "cartId"
          )}`
        )
      )

    if (
      status != newStatus &&
      newStatus === "shipped" &&
      (localStorage.getItem("cartId") || 0)
    )
      dispatch(
        getMyOrders(`?status=SHIPPED&cartId=${localStorage.getItem("cartId")}`)
      )

    dispatch({
      type: ORDER_STATUS,
      payload: newStatus
    })
  }
  return (
    <div className="order-status">
      <div
        className={`circle ${status === "cart" ? "selected" : ""}`}
        onClick={() => handleStatus("cart")}
      >
        <FaShoppingCart className="icons" />
      </div>
      <div className="line"></div>
      <div
        className={`circle ${status === "preparation" ? "selected" : ""}`}
        onClick={() => handleStatus("preparation")}
      >
        <FaCogs className="icons" />
      </div>
      <div className="line"></div>
      <div
        className={`circle ${status === "shipped" ? "selected" : ""}`}
        onClick={() => handleStatus("shipped")}
      >
        <FaShippingFast className="icons" />
      </div>
    </div>
  )
}

export default OrderStatus
