import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {PRODUCT_BUILD} from "../../constants/spsProductConstant"
import "./SPSCreateProduct.scss"
const SPSCreateProductBullets = () => {
  const dispatch = useDispatch()

  useEffect(() => {}, [dispatch])

  const spsProductBuild = useSelector((state) => state.spsProductBuild)
  const {product_details} = spsProductBuild

  const handleBulletPoints = (
    parameterName1,
    parameterName2,
    parameterValue
  ) => {
    let newProductDetails = JSON.parse(JSON.stringify(product_details || {}))
    if (!newProductDetails["bulletPoints"]) {
      newProductDetails["bulletPoints"] = {}
    }

    if (parameterName2) {
      if (!newProductDetails["bulletPoints"][parameterName1]) {
        newProductDetails["bulletPoints"][parameterName1] = {}
      }
      if (!newProductDetails["bulletPoints"][parameterName1][parameterName2]) {
        newProductDetails["bulletPoints"][parameterName1][parameterName2] = []
      }
      if (parameterValue !== "")
        newProductDetails["bulletPoints"][parameterName1][parameterName2].push(
          parameterValue
        )
    } else newProductDetails["bulletPoints"][parameterName1] = parameterValue
    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
  }

  const handleBulletsAction = (lang, index, action) => {
    let newProductDetails = product_details
    let bulletsList = product_details.bulletPoints.list[lang]

    if (action === "delete") bulletsList.splice(index, 1)

    if (action === "left") {
      let firstIndex = index - 1
      let secondIndex = index

      let firstElement = bulletsList.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = bulletsList.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      bulletsList.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      bulletsList.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    if (action === "right") {
      let firstIndex = index
      let secondIndex = index + 1

      let firstElement = bulletsList.splice(firstIndex, 1)[0] // Remove element at firstIndex and store it
      let secondElement = bulletsList.splice(secondIndex - 1, 1)[0] // Remove element at secondIndex and store it (note: we use secondIndex - 1 because the array length has decreased by 1 after the first splice)

      bulletsList.splice(firstIndex, 0, secondElement) // Insert secondElement at firstIndex
      bulletsList.splice(secondIndex, 0, firstElement) // Insert firstElement at secondIndex
    }

    newProductDetails["bulletPoints"]["list"][lang] = bulletsList
    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
  }
  return (
    <div>
      <h4>Bullets</h4>
      <div className="section">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            padding: "5px"
          }}
        >
          <Form.Control
            placeholder="En List Point"
            className="frm"
            type="text"
            defaultValue=""
            onChange={(e) => handleBulletPoints("list", "en", e.target.value)}
          ></Form.Control>

          {product_details?.bulletPoints?.list?.en?.map((item, index) => (
            <div style={{display: "flex", alignItems: "center"}} key={index}>
              <Form.Control
                placeholder={`En item ${index + 1}`}
                className="frm"
                type="text"
                defaultValue={item}
                onChange={(e) =>
                  handleBulletPoints("list", "en", e.target.value)
                }
              ></Form.Control>

              {index !== 0 && (
                <i
                  className="fas fa-arrow-up ico"
                  onClick={() => handleBulletsAction("en", index, "left")}
                ></i>
              )}
              {index + 1 !==
                product_details?.bulletPoints?.list?.en?.length && (
                <i
                  className="fas fa-arrow-down ico"
                  onClick={() => handleBulletsAction("en", index, "right")}
                ></i>
              )}
              <i
                className="fas fa-trash-alt ico"
                onClick={() => handleBulletsAction("en", index, "delete")}
              ></i>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            padding: "5px"
          }}
        >
          <Form.Control
            placeholder="Ar List Point"
            className="frm"
            type="text"
            defaultValue=""
            onChange={(e) => handleBulletPoints("list", "ar", e.target.value)}
          ></Form.Control>

          {product_details?.bulletPoints?.list?.ar?.map((item, index) => (
            <div style={{display: "flex", alignItems: "center"}} key={index}>
              <Form.Control
                placeholder={`Ar item ${index + 1}`}
                className="frm"
                type="text"
                defaultValue={item}
                onChange={(e) =>
                  handleBulletPoints("list", "ar", e.target.value)
                }
              ></Form.Control>
              {index !== 0 && (
                <i
                  className="fas fa-arrow-up ico"
                  onClick={() => handleBulletsAction("ar", index, "left")}
                ></i>
              )}
              {index + 1 !==
                product_details?.bulletPoints?.list?.ar?.length && (
                <i
                  className="fas fa-arrow-down ico"
                  onClick={() => handleBulletsAction("ar", index, "right")}
                ></i>
              )}
              <i
                className="fas fa-trash-alt ico"
                onClick={() => handleBulletsAction("ar", index, "delete")}
              ></i>
            </div>
          ))}
        </div>
      </div>

      <div className="section">
        <Form.Control
          placeholder="image"
          className="frm"
          type="text"
          defaultValue={product_details?.bulletPoints?.image}
          onChange={(e) => handleBulletPoints("image", "", e.target.value)}
        ></Form.Control>
        <img
          src={product_details?.bulletPoints?.image}
          style={{margin: "5px", width: "20%"}}
        />
      </div>
      <hr className="line" />
    </div>
  )
}

export default SPSCreateProductBullets
