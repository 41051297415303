import React from "react"
import {FaArrowLeft, FaWindowClose} from "react-icons/fa"
import {Link} from "react-router-dom"
import "./StickyTopBackAndTitle.scss"

function StickyTopBackAndTitle({title, handleBackAction}) {
  return (
    <div id="template1-sticky-top-back-title">
      <div className="topbar">
        <div className="back-icon">
          <FaArrowLeft onClick={() => handleBackAction()} />
        </div>

        <div className="title">{title}</div>
      </div>
    </div>
  )
}

export default StickyTopBackAndTitle
