import {GOTO_LOCATION} from "../constants/gotoLocationConstants"

export const gotoLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case GOTO_LOCATION:
      return {
        location: action.payload
      }

    default:
      return state
  }
}
