import React from "react"
import {Container, Row, Col} from "react-bootstrap"

import siteMeta from "../assets/siteMeta.json"

import "./Footer.scss"
import {useTranslation} from "react-i18next"
import {updateCart} from "../actions/spsCartActions"
function Footer() {
  const [t, i18n] = useTranslation()

  return (
    <footer id="footer">
      {/* <Row className="m-0 p-0">
        <Col
          style={{
            border: "1px var(--Primary) solid",
            borderRadius: "5px",
            maxWidth: "150px"
          }}
        >
          {i18n.language === "ar" && (
            <div
              className="lang-button"
              onClick={() => {
                localStorage.setItem("lang", "en")
                localStorage.setItem("dir", "ltr")
                updateCart({lang: "en"})
                i18n.changeLanguage("en")
              }}
            >
              English
            </div>
          )}
          {i18n.language === "en" && (
            <div
              className="lang-button"
              onClick={() => {
                localStorage.setItem("lang", "ar")
                localStorage.setItem("dir", "rtl")
                updateCart({lang: "ar"})
                i18n.changeLanguage("ar")
              }}
            >
              عربي
            </div>
          )}
        </Col>
      </Row> */}
      <div className="footer-container">
        <div className="footer-item">
          <a href="/#/contact-information">{t("Contact_Us")}</a>
        </div>
        <div className="footer-item">
          <a href="/#/terms-and-conditions">{t("Terms_&_Conditions")}</a>
        </div>
        <div className="footer-item">
          <a href="/#/return-and-refund-policy">
            {t("Return_And_Refund_Policy")}
          </a>
        </div>
        <div className="footer-item">
          <a href="/#/shipping-policy">{t("Shipping_Policy")}</a>
        </div>
      </div>
      <Row className="m-0 p-0">
        <Col className="text-center py-3" style={{fontSize: "0.7rem"}}>
          {t("Copyright")} &copy; {siteMeta.siteName[t("lang")]}
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
