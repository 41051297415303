import {
  GET_CART_ITEMS_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS
} from "../constants/spsCartConstant"

export const spsCartItemsGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CART_ITEMS_REQUEST:
      return {loading: true}

    case GET_CART_ITEMS_SUCCESS:
      return {loading: false, items: action.payload}

    case GET_CART_ITEMS_FAIL:
      return {loading: false}

    default:
      return state
  }
}
