import {RESET_ERROR, SET_ERROR} from "../constants/errorAlertConstants"
import {RESET_SUCCESS, SET_SUCCESS} from "../constants/successAlertConstants"

export const toastMessages = (status, message) => async (dispatch) => {
  if (status) {
    dispatch({
      type: SET_SUCCESS,
      payload: message
    })
    setTimeout(
      () =>
        dispatch({
          type: RESET_SUCCESS
        }),
      500
    )
  } else {
    dispatch({
      type: SET_ERROR,
      payload: message
    })
    setTimeout(
      () =>
        dispatch({
          type: RESET_ERROR
        }),
      500
    )
  }
}
