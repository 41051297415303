import React from "react"
import {Row} from "react-bootstrap"
import {useTranslation} from "react-i18next"

import {FiThumbsUp} from "react-icons/fi"
const SuccessScreen = () => {
  const [t, i18n] = useTranslation()

  return (
    <Row
      className="m-0"
      style={{
        width: "100%",
        paddingTop: "25vh",
        paddingBottom: "200px",
        fontSize: "25px",
        color: "green"
      }}
    >
      {t("Created_Successfuly")}
      <p style={{fontSize: "100px", color: "green"}}>
        <FiThumbsUp />
      </p>
    </Row>
  )
}

export default SuccessScreen
