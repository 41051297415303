import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {getOrders} from "../../actions/spsOrderGetActions"

const SPSSearchOrderBy = () => {
  const dispatch = useDispatch()

  const spsOrderGet = useSelector((state) => state.spsOrderGet)
  const {loading, orders} = spsOrderGet

  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (e) => {
    const newValue = e.target.value
    // Remove non-numeric characters
    const numericValue = newValue.replace(/\D/g, "")

    // Check if the numericValue exceeds 11 digits
    setInputValue(numericValue)

    if (numericValue.length === 11)
      dispatch(getOrders(`?status=DELIVERED,SHIPPED&mobile=${numericValue}`))
  }
  return (
    <div>
      <input type="text" value={inputValue} onChange={handleInputChange} />
    </div>
  )
}

export default SPSSearchOrderBy
