import React from "react"
import {useDispatch, useSelector} from "react-redux"
import Loader from "../components/Loader"
import {getOrders} from "../actions/spsOrderGetActions"
import {useEffect} from "react"
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap"
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {HiOutlineMinusCircle, HiOutlinePlusCircle} from "react-icons/hi"
import {FaWhatsappSquare, FaSms} from "react-icons/fa"
import "./Manage.scss"
import {updateOrder} from "../actions/spsOrderUpdateActions"
import {duplicateOrder} from "../actions/spsOrderDuplicateActions"
import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

const Manage = () => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const {userInfo} = userLogin

  const [fullName, setFullName] = useState("")
  const [mobile, setMobile] = useState("")
  const [anotherMobile, setAnotherMobile] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("")
  const [floor, setFloor] = useState("")
  const [flat, setFlat] = useState("")
  const [notes, setNotes] = useState("")

  const [status, setStatus] = useState("")

  const [modalStatus, setModalStatus] = useState(false)
  const [currentOrder, setCurrentOrder] = useState("")
  const [orderIndex, setOrderIndex] = useState("")

  const [selectedTab, setSelectedTab] = useState("Pending")
  useEffect(() => {
    dispatch(getOrders(`?status=${selectedTab.toUpperCase()}`))
  }, [dispatch])

  const spsOrderGet = useSelector((state) => state.spsOrderGet)
  const {loading, orders} = spsOrderGet

  const handleModal = (order, index) => {
    setFullName(order.fullName)
    setMobile(order.mobile)
    setAnotherMobile(order?.anotherMobile || "")
    setAddress(order.address)
    setCity(order?.city || "")
    setRegion(order?.region || "")
    setFloor(order?.floor || "")
    setFlat(order?.flat || "")
    setStatus(order.status)
    setNotes(order.notes)

    setOrderIndex(index)
    setCurrentOrder(order)
    setModalStatus(true)
  }

  const handleFilter = (filter) => {
    setSelectedTab(filter)
    if (filter === "All")
      dispatch(
        getOrders(
          "?sortType=ASCENDING&sortProperty=mobile&status=PENDING,POSTPONED,CONFIRMED,TOSHIP,SHIPPED"
        )
      )
    else dispatch(getOrders(`?status=${filter.toUpperCase()}`))
  }

  const handleUpdate = () => {
    let payload = {}
    if (fullName !== "") payload["fullName"] = fullName
    if (mobile !== "") payload["mobile"] = mobile
    payload["anotherMobile"] = anotherMobile
    if (address !== "") payload["address"] = address
    payload["city"] = city
    payload["region"] = region
    payload["floor"] = floor
    payload["flat"] = flat
    payload["notes"] = notes

    dispatch(updateOrder(currentOrder._id, payload, orderIndex, orders))
  }

  const handleConfirm = () => {
    dispatch(
      updateOrder(currentOrder._id, {status: "CONFIRMED"}, orderIndex, orders)
    )
    setModalStatus(false)
  }

  const handleDuplicate = () => {
    dispatch(duplicateOrder(currentOrder._id))
    setModalStatus(false)
  }

  const handlePostpone = () => {
    dispatch(
      updateOrder(currentOrder._id, {status: "POSTPONED"}, orderIndex, orders)
    )
    setModalStatus(false)
  }

  const handleCancel = (status) => {
    dispatch(
      updateOrder(currentOrder._id, {status: status}, orderIndex, orders)
    )
    setModalStatus(false)
  }

  const handleCallsCounter = (orderId, orderIndex) => {
    dispatch(updateOrder(orderId, {callsCounter: 1}, orderIndex, orders))
  }

  const handleWhatsAppClick = (
    orderId,
    orderIndex,
    clientName,
    mobile,
    callsCounter
  ) => {
    if (
      selectedTab === "Shipped" ||
      selectedTab === "Returned" ||
      callsCounter > 1
    ) {
      console.log("test")
      dispatch(
        updateOrder(
          orderId,
          {whatsAppReturnAlertCounter: 1},
          orderIndex,
          orders
        )
      )
      const url = `https://api.whatsapp.com/send?phone=+2${mobile}&text=${encodeURIComponent(
        `اهلا ${clientName} تم التواصل اكثر من مره من شركة الشحن لتوصيل طلبك مخدة الرجل الطبية و لم يتم الرد برجاء ارسال رقم موبايل اخر ليتم التواصل او سيتم الغاء طلبكم و رجوعه الينا`
      )}`
      window.open(url, "_blank")
    }
  }

  const handleMessageClick = (
    orderId,
    orderIndex,
    clientName,
    mobile,
    callsCounter
  ) => {
    if (
      selectedTab === "Shipped" ||
      selectedTab === "Returned" ||
      callsCounter > 1
    ) {
      console.log("test")

      dispatch(
        updateOrder(
          orderId,
          {whatsAppReturnAlertCounter: 1},
          orderIndex,
          orders
        )
      )
      const url = `sms:${mobile}?body=${encodeURIComponent(
        `اهلا ${clientName} تم التواصل اكثر من مره من شركة الشحن لتوصيل طلبك مخدة الرجل الطبية و لم يتم الرد برجاء ارسال رقم موبايل اخر ليتم التواصل او سيتم الغاء طلبكم و رجوعه الينا`
      )}`
      window.open(url, "_blank")
    }
  }
  return (
    <div id="sps-manage">
      <ErrorModal />
      <SuccessModal />
      <Modal
        show={modalStatus}
        onHide={() => setModalStatus(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Order {currentOrder._id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{marginTop: "5px", padding: "5px"}}>
            {currentOrder?.items?.length > 0
              ? currentOrder.items.map((item, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center", // Horizontally center
                        alignItems: "center" // Vertically center (if you need it)
                        // flexDirection: "row" // Ensure children are in a row (default for flex)
                      }}
                    >
                      <img
                        src={
                          item?.variant?.color
                            ? item.variant.color.image
                            : item.image
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          margin: "0 10px"
                        }}
                      />
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            margin: "0"
                          }}
                        >
                          {item.name.ar} {" - "}
                          {item?.variant?.hardness?.name.ar}
                          {" - "}
                          {item?.variant?.color?.name}
                          {item?.extraProduct?.status === true
                            ? ` + ${item?.extraProduct?.statement?.ar}`
                            : ""}
                        </p>
                        <div style={{display: "flex", margin: "0"}}>
                          <p style={{margin: "0"}}>
                            {item.quantity} x {item.price} ={" "}
                            {item.quantity * item.price}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr style={{width: "100%", border: "1px dashed red"}} />
                  </>
                ))
              : ""}
            <Form.Label
              htmlFor="full-name"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Full_Name")}{" "}
              <span style={{color: "red"}}>* {t("Required")}</span>
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="mobile"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Mobile_Number")}{" "}
              <span style={{color: "red"}}>* {t("Required")}</span>
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="another-mobile"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Another_Mobile_Number")}{" "}
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              type="tel"
              value={anotherMobile}
              onChange={(e) => setAnotherMobile(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="notes"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Notes")}
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              as="textarea" // Use the textarea element
              rows={2} // Specify the number of rows to display
              type="text"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            ></Form.Control>

            <Form.Label
              htmlFor="address"
              style={{direction: t("Dir"), textAlign: "start"}}
            >
              {t("Address")}{" "}
              <span style={{color: "red"}}>* {t("Required")}</span>
            </Form.Label>
            <Form.Control
              style={{
                border: "1px solid grey",
                direction: t("Dir"),
                textAlign: "start"
              }}
              as="textarea" // Use the textarea element
              rows={3} // Specify the number of rows to display
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
            <div
              style={{
                margin: "0",
                padding: "0",
                display: "flex",
                direction: t("Dir")
              }}
            >
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="city" style={{}}>
                  {t("City")}
                </Form.Label>
                <Form.Control
                  style={{border: "1px solid grey"}}
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                ></Form.Control>
              </div>
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="region" style={{}}>
                  {t("Region")}
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    textAlign: "start",
                    direction: t("Dir")
                  }}
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                ></Form.Control>
              </div>
            </div>
            <div
              style={{
                margin: "0",
                padding: "0",
                display: "flex",
                direction: t("Dir")
              }}
            >
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="flor" style={{}}>
                  {t("Floor")}
                </Form.Label>
                <Form.Control
                  style={{border: "1px solid grey"}}
                  type="number"
                  value={floor}
                  onChange={(e) => setFloor(e.target.value)}
                ></Form.Control>
              </div>
              <div
                style={{
                  margin: "0",
                  padding: "3px",
                  direction: t("Dir"),
                  textAlign: "start",
                  width: "50%"
                }}
              >
                <Form.Label htmlFor="flat" style={{}}>
                  {t("Flat")}
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    textAlign: "start",
                    direction: t("Dir")
                  }}
                  type="number"
                  value={flat}
                  onChange={(e) => setFlat(e.target.value)}
                ></Form.Control>
              </div>
            </div>

            {status !== "RETURNED" ? (
              <>
                <Row className="p-0 m-0">
                  <div style={{width: "100%", padding: "5px"}}>
                    <Button
                      style={{width: "100%", borderRadius: "5px"}}
                      onClick={() => handleUpdate()}
                    >
                      Update
                    </Button>
                  </div>
                  <div style={{width: "100%", padding: "5px"}}>
                    <Button
                      style={{width: "100%", borderRadius: "5px"}}
                      variant="success"
                      onClick={() => handleConfirm()}
                    >
                      Confirm
                    </Button>
                  </div>
                  <div style={{width: "100%", padding: "5px"}}>
                    <Button
                      style={{width: "100%", borderRadius: "5px"}}
                      onClick={() => handlePostpone()}
                    >
                      Postpone
                    </Button>
                  </div>
                  <div style={{width: "100%", padding: "5px"}}>
                    <Button
                      style={{width: "100%", borderRadius: "5px"}}
                      variant="danger"
                      onClick={() => handleCancel("TOCANCEL")}
                    >
                      To Cancel
                    </Button>
                  </div>
                  {userInfo.user?.role === "admin" && (
                    <div style={{width: "100%", padding: "5px"}}>
                      <Button
                        style={{width: "100%", borderRadius: "5px"}}
                        variant="danger"
                        onClick={() => handleCancel("CANCELED")}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </Row>
              </>
            ) : (
              <Row className="p-0 m-0">
                <Button
                  style={{width: "100%", margin: "5px"}}
                  onClick={() => handleDuplicate()}
                >
                  Duplicate
                </Button>
              </Row>
            )}
          </Card>
        </Modal.Body>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <Row className="p-0 m-0">
          <h4>{orders?.length} Orders</h4>
          <div className="status-bar">
            <div
              className="status-tag"
              onClick={() => handleFilter("All")}
              style={{
                backgroundColor: selectedTab === "All" ? "goldenrod" : "white"
              }}
            >
              All
            </div>
            <div
              className="status-tag"
              onClick={() => handleFilter("Pending")}
              style={{
                backgroundColor:
                  selectedTab === "Pending" ? "goldenrod" : "white"
              }}
            >
              Pending
            </div>
            <div
              className="status-tag"
              onClick={() => handleFilter("Postponed")}
              style={{
                backgroundColor:
                  selectedTab === "Postponed" ? "goldenrod" : "white"
              }}
            >
              Postponed
            </div>
            <div
              className="status-tag"
              onClick={() => handleFilter("Confirmed")}
              style={{
                backgroundColor:
                  selectedTab === "Confirmed" ? "goldenrod" : "white"
              }}
            >
              Confirmed
            </div>
            <div
              className="status-tag"
              onClick={() => handleFilter("ToShip")}
              style={{
                backgroundColor:
                  selectedTab === "ToShip" ? "goldenrod" : "white"
              }}
            >
              To Ship
            </div>
            <div
              className="status-tag"
              onClick={() => handleFilter("Shipped")}
              style={{
                backgroundColor:
                  selectedTab === "Shipped" ? "goldenrod" : "white"
              }}
            >
              Shipped
            </div>
            <div
              className="status-tag"
              onClick={() => handleFilter("ToCancel")}
              style={{
                backgroundColor:
                  selectedTab === "ToCancel" ? "goldenrod" : "white"
              }}
            >
              To Cancel
            </div>
            {/* <div
              className="status-tag"
              onClick={() => handleFilter("Returned")}
              style={{
                backgroundColor:
                  selectedTab === "Returned" ? "goldenrod" : "white"
              }}
            >
              Returned
            </div> */}
          </div>
          {orders?.map((order, index) => (
            <Col
              key={index}
              className="p-0 m-0"
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
            >
              <div
                className="card"
                style={{
                  padding: "5px",
                  margin: "3px",
                  backgroundColor:
                    orders[index]?.mobile === orders[index + 1]?.mobile
                      ? "red"
                      : ""
                }}
              >
                <Row className="p-0 m-0">
                  <Col className="p-0 m-0" xs={6} style={{fontSize: "8px"}}>
                    {order.createdAt}
                  </Col>
                  <Col className="p-0 m-0" xs={6} style={{fontSize: "8px"}}>
                    {order._id}
                  </Col>
                  <Col className="p-0 m-0" xs={12} style={{fontSize: "8px"}}>
                    {order.returnAfterShip && (
                      <div className="orange-dot"></div>
                    )}
                  </Col>
                  <Col
                    className="p-0 m-0"
                    xs={12}
                    style={{
                      fontSize: "10px",
                      color: "orange",
                      backgroundColor: "cyan"
                    }}
                  >
                    {order.notes}
                  </Col>
                  <Col
                    className="p-0 m-0"
                    xs={12}
                    style={{fontSize: "12px", color: "blue"}}
                  >
                    {order?.shippingCompany || ""}
                  </Col>
                  <Col
                    className="p-0 m-0"
                    xs={12}
                    style={{fontSize: "12px", color: "blue"}}
                  >
                    {order?.trackingNumber || ""}
                  </Col>
                  <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                    <FaWhatsappSquare
                      onClick={() =>
                        handleWhatsAppClick(
                          order._id,
                          index,
                          order.fullName,
                          order.mobile,
                          order.callsCounter
                        )
                      }
                    />
                    {order?.whatsAppReturnAlertCounter || 0}
                  </Col>
                  <Col className="p-0 m-0" xs={2} style={{color: "green"}}>
                    <FaSms
                      className="bi bi-telephone-fill"
                      onClick={() =>
                        handleMessageClick(
                          order._id,
                          index,
                          order.fullName,
                          order.mobile,
                          order.callsCounter
                        )
                      }
                    />
                    {order?.whatsAppReturnAlertCounter || 0}
                  </Col>
                  <Col className="p-0 m-0" xs={3} style={{fontSize: "14px"}}>
                    {order.status}
                  </Col>
                  <Col className="p-0 m-0" xs={3} style={{fontSize: "14px"}}>
                    {order?.close || 0 ? "closed" : "opened"}
                  </Col>
                  <Col
                    className="p-0 m-0"
                    xs={6}
                    onClick={() => handleCallsCounter(order._id, index)}
                  >
                    <a href={`tel:${order.mobile}`}>
                      <i
                        className="bi bi-telephone-fill"
                        style={{
                          fontSize: "14px",
                          color: "green",
                          verticalAlign: "middle"
                        }}
                      >
                        {order?.callsCounter || 0}
                      </i>
                    </a>
                  </Col>
                  {order?.anotherMobile ? (
                    <Col
                      className="p-0 m-0"
                      xs={6}
                      onClick={() => handleCallsCounter(order._id, index)}
                    >
                      <a href={`tel:${order.anotherMobile}`}>
                        <i
                          className="bi bi-telephone-fill"
                          style={{
                            fontSize: "14px",
                            color: "green",
                            verticalAlign: "middle"
                          }}
                        >
                          {order?.callsCounter || 0}
                        </i>
                      </a>
                    </Col>
                  ) : (
                    ""
                  )}
                  <hr
                    style={{
                      color: "green",
                      height: "1px",
                      backgroundColor: "green",
                      padding: "0",
                      margin: "0"
                    }}
                  />
                  <Col className="p-0 m-0" xs={6} style={{fontSize: "12px"}}>
                    {order.fullName}
                  </Col>
                  <Col className="p-0 m-0" xs={6} style={{fontSize: "12px"}}>
                    {order.mobile}
                  </Col>
                  <Col xs={12} style={{fontSize: "10px"}}>
                    {order.address}
                  </Col>
                  <Col xs={6} style={{fontSize: "12px"}}>
                    {order.city}
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      fontSize:
                        order?.items?.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        ) > 1
                          ? "18px"
                          : "12px",
                      backgroundColor:
                        order?.items?.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        ) > 1
                          ? "orange"
                          : "",
                      color:
                        order?.items?.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        ) > 1
                          ? "white"
                          : "",
                      borderRadius: "10px"
                    }}
                  >
                    {order?.items?.reduce(
                      (acc, item) => acc + item.quantity,
                      0
                    )}
                  </Col>
                  <Col xs={3} style={{fontSize: "12px"}}>
                    {order?.items?.reduce(
                      (acc, item) => acc + item.quantity * item.price,
                      0
                    ) +
                      (order.shippingFee - (order?.shippingFeeDiscount || 0)) -
                      (order?.couponValue || 0)}
                  </Col>
                  <Col
                    xs={12}
                    onClick={() => handleModal(order, index)}
                    style={{color: "orangered"}}
                  >
                    more
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}

export default Manage
