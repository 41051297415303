import axios from "axios"
import {toastMessages} from "./toastMessages"

import {
  GET_STOCK_LOG_FAIL,
  GET_STOCK_LOG_REQUEST,
  GET_STOCK_LOG_SUCCESS,
  UPDATE_STOCK_LOG_FAIL,
  UPDATE_STOCK_LOG_REQUEST,
  UPDATE_STOCK_LOG_SUCCESS
} from "../constants/spsStockLogConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const getStocksLogs = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_STOCK_LOG_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      },
      params: filters
    }
    console.log(config)
    const {data} = await axios.get(`${base_url}/sps-stock-logs`, config)

    dispatch({
      type: GET_STOCK_LOG_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_STOCK_LOG_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}

export const updateStocksLogs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_STOCK_LOG_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.post(`${base_url}/sps-stock-logs`, {}, config)

    dispatch({
      type: UPDATE_STOCK_LOG_SUCCESS
    })
    dispatch(getStocksLogs(""))
  } catch (error) {
    dispatch({
      type: UPDATE_STOCK_LOG_FAIL
    })
    dispatch(toastMessages(0, error.response))
  }
}
