export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"

export const USER_FORGET_PASSWORD_REQUEST = "USER_FORGET_PASSWORD_REQUEST"
export const USER_FORGET_PASSWORD_SUCCESS = "USER_FORGET_PASSWORD_SUCCESS"
export const USER_FORGET_PASSWORD_FAIL = "USER_FORGET_PASSWORD_FAIL"

export const USER_LOGOUT = "USER_LOGOUT"

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS"
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL"
export const USER_DETAILS_RESET = "USER_DETAILS_RESET"

export const CLIENT_DETAILS_REQUEST = "CLIENT_DETAILS_REQUEST"
export const CLIENT_DETAILS_SUCCESS = "CLIENT_DETAILS_SUCCESS"
export const CLIENT_DETAILS_FAIL = "CLIENT_DETAILS_FAIL"
export const CLIENT_DETAILS_RESET = "CLIENT_DETAILS_RESET"

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST"
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS"
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL"
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET"

export const USER_CHANGE_PASSWORD_REQUEST = "USER_CHANGE_PASSWORD_REQUEST"
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS"
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL"

export const USER_CHANGE_PHONE_OTP_REQUEST = "USER_CHANGE_PHONE_OTP_REQUEST"
export const USER_CHANGE_PHONE_OTP_SUCCESS = "USER_CHANGE_PHONE_OTP_SUCCESS"
export const USER_CHANGE_PHONE_OTP_FAIL = "USER_CHANGE_PHONE_OTP_FAIL"

export const USER_CHANGE_PHONE_REQUEST = "USER_CHANGE_PHONE_REQUEST"
export const USER_CHANGE_PHONE_SUCCESS = "USER_CHANGE_PHONE_SUCCESS"
export const USER_CHANGE_PHONE_FAIL = "USER_CHANGE_PHONE_FAIL"

export const USER_LIST_REQUEST = "USER_LIST_REQUEST"
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"
export const USER_LIST_FAIL = "USER_LIST_FAIL"
export const USER_LIST_RESET = "USER_LIST_RESET"

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL = "USER_DELETE_FAIL"

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"
export const USER_UPDATE_RESET = "USER_UPDATE_RESET"

export const FIND_TO_ATTEND_REQUEST = "FIND_TO_ATTEND_REQUEST"
export const FIND_TO_ATTEND_SUCCESS = "FIND_TO_ATTEND_SUCCESS"
export const FIND_TO_ATTEND_FAIL = "FIND_TO_ATTEND_FAIL"
export const FIND_TO_ATTEND_RESET = "FIND_TO_ATTEND_RESET"

export const USER_ADD_REQUEST = "USER_ADD_REQUEST"
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS"
export const USER_ADD_FAIL = "USER_ADD_FAIL"
export const USER_ADD_RESET = "USER_ADD_RESET"

export const GET_USER_CREDENTIALS_REQUEST = "GET_USER_CREDENTIALS_REQUEST"
export const GET_USER_CREDENTIALS_SUCCESS = "GET_USER_CREDENTIALS_SUCCESS"
export const GET_USER_CREDENTIALS_FAIL = "GET_USER_CREDENTIALS_FAIL"
