import axios from "axios"
import {toastMessages} from "./toastMessages"
import {
  CREATE_ORDER_FAIL,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS
} from "../constants/spsOrderCreateConstant"
import {getMyOrders} from "./spsOrderGetActions"
import {ORDER_STATUS} from "../constants/spsOrderStatusConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const createOrder = (payload, lang) => async (dispatch, getState) => {
  payload["cartId"] = await localStorage.getItem("cartId")
  try {
    dispatch({
      type: CREATE_ORDER_REQUEST
    })

    const config = {
      headers: {
        "Content-type": "application/json"
      }
    }
    const {data} = await axios.post(`${base_url}/sps-orders`, payload, config)
    dispatch({
      type: CREATE_ORDER_SUCCESS
    })
    dispatch(
      toastMessages(1, lang === "ar" ? "تم اضافة طلبك بنجاح" : "Order Created")
    )

    dispatch({
      type: ORDER_STATUS,
      payload: "preparation"
    })

    dispatch(
      getMyOrders(
        `?status=PENDING,CONFIRMED,TOSHIP&cartId=${localStorage.getItem(
          "cartId"
        )}`
      )
    )
    // window.location.replace("/#/created-successfuly")
    // setTimeout(() => window.location.replace("/#/"), 1500)
  } catch (error) {
    dispatch({
      type: CREATE_ORDER_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}
