import React, {useState} from "react"
import {useTranslation} from "react-i18next"

import siteMeta from "../assets/siteMeta.json"

import "./ContactUs.scss"

export const ContactUs = () => {
  const [t, i18n] = useTranslation()

  const [mobileNumber, setMobileNumber] = useState("")
  const [isValidMobile, setIsValidMobile] = useState(false)

  const validateMobileNumber = (number) => {
    const pattern = /^(010|011|012|015)\d{8}$/
    return pattern.test(number)
  }

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, "") // Remove all non-numeric characters
    setMobileNumber(value)
    setIsValidMobile(validateMobileNumber(value))
  }

  return (
    <div id="contact-us">
      <h1>{t("Contact_Us")}</h1>
      <div className="contact-methods">
        <div className="method">
          <h3 style={{color: "var(--Primary)"}}>{t("For_More_Details")}</h3>
          <p>{t("If_You_Have_Questions")}:</p>
          <a
            href="https://m.me/102002919617407"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
            style={{fontSize: "16px", textAlign: "center", borderRadius: "5px"}}
          >
            {t("Contact_Us_On_Messenger")}{" "}
            <i className="fa fa-facebook-messenger"></i>
          </a>
        </div>
        <div className="method">
          <h3 style={{color: "var(--Primary)"}}>{t("Return_Requests")}</h3>
          <p>{t("If_You_Have_Any_Return")}:</p>
          <div className="mb-3">
            <small className="form-text text-muted">
              {t(
                "Please_Enter_The_Mobile_Number_You_Used_To_Create_Your_Order"
              )}
              .
            </small>
            <input
              type="tel"
              pattern="\d*"
              className="form-control"
              id="mobileNumber"
              value={mobileNumber}
              onChange={handleInputChange}
              placeholder={t("Enter_Your_Mobile_Number")}
            />
          </div>
          <a
            href={`https://api.whatsapp.com/send?phone=+2${
              siteMeta.mobile
            }&text=${encodeURIComponent(
              `محتاج اعمل طلب ارجاع لاوردر برقم تليفون ${mobileNumber} \n I need to make a return request for an order on mobile number ${mobileNumber}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`btn btn-success ${isValidMobile ? "" : "disabled"}`}
            style={{fontSize: "16px", textAlign: "center", borderRadius: "5px"}}
          >
            {t("Send_Request")} <i className="fa fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </div>
  )
}
