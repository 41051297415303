import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {useTranslation} from "react-i18next"

import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

import siteMeta from "../assets/siteMeta.json"

import {validMobileRegex} from "../myRegexp"

import "./CheckoutScreen.scss"
import StickyTopBackAndTitle from "../components/stickyTopBackAndTitle/StickyTopBackAndTitle"
import RadioButton from "../components/radioButton/RadioButton"

import {HiOutlinePlusCircle, HiOutlineMinusCircle} from "react-icons/hi"
import {toastMessages} from "../actions/toastMessages"
import {createOrder, registerEvent} from "../actions/spsOrderCreateActions"
import Loader from "../components/Loader"
import axios from "axios"
import {fbq} from "react-facebook-pixel"
import {getCartItems} from "../actions/spsCartActions"
import {
  decrementItem,
  deleteItem,
  incrementItem
} from "../actions/spsItemActions"
import OrderStatus from "../components/orderStatus/OrderStatus"
import {ORDER_STATUS} from "../constants/spsOrderStatusConstant"
import SPSPreparation from "../components/SPS/SPSPreparation"
function CheckoutScreen({history}) {
  const [t, i18n] = useTranslation()

  const dispatch = useDispatch()

  const spsOrderStatus = useSelector((state) => state.spsOrderStatus)
  const {status} = spsOrderStatus

  const spsOrderCreate = useSelector((state) => state.spsOrderCreate)
  const {loading} = spsOrderCreate

  const spsCartItemsGet = useSelector((state) => state.spsCartItemsGet)
  const {loading: spsCartItemsLoading, items} = spsCartItemsGet

  const spsItemDecrement = useSelector((state) => state.spsItemDecrement)
  const {loading: decrementLoading} = spsItemDecrement

  const spsItemIncrement = useSelector((state) => state.spsItemIncrement)
  const {loading: incrementLoading} = spsItemIncrement

  const spsItemDelete = useSelector((state) => state.spsItemDelete)
  const {loading: removeLoading} = spsItemDelete

  const [userAgent, setUserAgent] = useState("")
  const [ipAddress, setIPAddress] = useState("")
  const [fbpCookieValue, setFbpCookieValue] = useState(null)
  const [fbcCookieValue, setFbcCookieValue] = useState(null)

  const [coupon, setCoupon] = useState("")
  const [couponValue, setCouponValue] = useState(0)

  const [fullName, setFullName] = useState(localStorage.getItem("name") || "")
  const [mobile, setMobile] = useState(localStorage.getItem("mobile") || "")
  const [anotherMobile, setAnotherMobile] = useState(
    localStorage.getItem("anotherMobile") || ""
  )
  const [address, setAddress] = useState(localStorage.getItem("address") || "")
  const [email, setEmail] = useState(localStorage.getItem("email") || "")
  const [city, setCity] = useState(localStorage.getItem("city") || "")
  const [region, setRegion] = useState(localStorage.getItem("region") || "")
  const [note, setNote] = useState(localStorage.getItem("note") || "")

  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "EGP"
  )

  const handleCurrency = (currency) => {
    localStorage.setItem("currency", currency)
    setCurrency(currency)
  }

  useEffect(() => {
    setUserAgent(navigator.userAgent)
    dispatch(getCartItems())
    dispatch({
      type: ORDER_STATUS,
      payload: "cart"
    })
    async function fetchIPAddress() {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json")
        setIPAddress(response.data.ip)
      } catch (error) {
        console.error("Error fetching IP address:", error)
      }
    }

    fetchIPAddress()
    const fbcCookie = getCookie("_fbc")
    const fbpCookie = getCookie("_fbp")
    setFbpCookieValue(fbpCookie)
    setFbcCookieValue(fbcCookie)
    console.log(fbpCookie)
    console.log(fbcCookie)
  }, [])

  function getCookie(name) {
    const cookies = document.cookie.split(";")
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=")
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue)
      }
    }
    return null
  }

  const handleBackAction = () => {
    history.push("")
  }

  const manageQuantity = (item, index, plusOrMinus) => {
    if (plusOrMinus == "+") {
      dispatch(incrementItem(item, index, items))
    }
    if (plusOrMinus == "-")
      if (item.quantity !== 1) {
        dispatch(decrementItem(item, index, items))
      } else dispatch(deleteItem(item, index, items))
  }

  const handleRemove = (item, index) => {
    dispatch(deleteItem(item, index, items))
  }

  const handleName = (name) => {
    localStorage.setItem("name", name)
    setFullName(name)
  }

  const handleMobile = (mobile) => {
    localStorage.setItem("mobile", mobile)
    setMobile(mobile)
  }

  const handleAnotherMobile = (anotherMobile) => {
    localStorage.setItem("anotherMobile", anotherMobile)
    setAnotherMobile(anotherMobile)
  }

  const handleAddress = (address) => {
    localStorage.setItem("address", address)
    setAddress(address)
  }

  const handleEmail = (email) => {
    localStorage.setItem("email", email)
    setEmail(email)
  }

  const handleNote = (note) => {
    localStorage.setItem("note", note)
    setNote(note)
  }

  const handleCity = (city) => {
    localStorage.setItem("city", city)
    setCity(city)
  }

  const handleRegion = (region) => {
    localStorage.setItem("region", region)
    setRegion(region)
  }

  const handleCoupon = (coupon) => {
    setCoupon(coupon)
  }

  const handleUseCoupon = () => {
    console.log(coupon.toUpperCase())
    if (coupon.toUpperCase() === `F${siteMeta.coupon_value}`)
      setCouponValue(siteMeta.coupon_value)
    else {
      setCouponValue(0)
      window.alert(t("Not_Valid_Coupon_Code"))
    }
  }

  const handleCheckout = () => {
    let pass = true

    let payload = {
      store: siteMeta.siteName.en,
      // quantity: Number(items[0]?.quantity),
      // price: items[0]?.price["EGP"],
      fullName: fullName,
      mobile: mobile,
      address: address,
      userAgent: userAgent,
      ipAddress: ipAddress,
      fbpCookieValue: fbpCookieValue,
      fbcCookieValue: fbcCookieValue
    }

    if (fullName === "") {
      pass = false
      dispatch(toastMessages(0, t("Name_Empty")))
    }

    if (mobile === "" && pass == true) {
      pass = false
      dispatch(toastMessages(0, t("Mobile_Empty")))
    }

    if (address === "" && pass == true) {
      pass = false
      dispatch(toastMessages(0, t("Address_Empty")))
    }

    if (mobile.length < 11 && pass == true) {
      pass = false
      dispatch(toastMessages(0, t("Wrong_Mobile")))
    } else payload["mobile"] = mobile

    if (
      items?.reduce((acc, item) => acc + item.quantity, 0) <
      siteMeta.freeShippingFromItemX
    )
      payload["eligibleForFreeShipping"] = false
    if (city !== "") payload["city"] = city
    if (couponValue !== 0) payload["couponValue"] = couponValue
    if (region !== "") payload["region"] = region
    if (anotherMobile !== "") payload["anotherMobile"] = anotherMobile

    if (email !== "") payload["email"] = email
    if (note !== "") payload["notes"] = note

    if (pass) {
      dispatch(createOrder(payload, t("lang")))
    }
  }

  return (
    <div className="p-0 m-0">
      <ErrorModal />
      <SuccessModal />
      <div id="checkout">
        <StickyTopBackAndTitle
          title={t("Check_Out")}
          handleBackAction={handleBackAction}
        />

        <OrderStatus />

        {status === "cart" &&
          (spsCartItemsLoading ? (
            <Loader />
          ) : items?.length > 0 ? (
            <>
              {/* <p
              style={{
                color: "var(--Orange-color)",
                margin: "5px",
                padding: "5px",
                direction: t("Dir"),
                textAlign: "center",
                fontSize: "1.2em"
              }}
            >
              {t("Items")}
            </p> */}

              {/* Free Shipping Note From The Next Item */}
              {/* {items?.reduce((acc, item) => acc + item.quantity, 0) == 1 && (
              <h2
                style={{
                  // color: "var(--Orange-color)",
                  marginTop: "30px",
                  padding: "5px",
                  direction: t("Dir"),
                  textAlign: "center",
                  fontSize: "1.2em"
                }}
              >
                {t("Add_Another_Get_Free_Shipping1")}{" "}
                <span style={{color: "var(--Orange-color)"}}>
                  {t("Add_Another_Get_Free_Shipping2")}
                </span>
              </h2>
            )} */}
              {/* End Free Shipping Note From The Next Item */}
              {siteMeta.freeShippingFromItemX > 1 &&
                items?.reduce((acc, item) => acc + item.quantity, 0) === 1 && (
                  <h6 style={{margin: "5px", color: "orange"}}>
                    {t("Add_Get_Free_Shipping")}
                  </h6>
                )}
              <Card style={{marginTop: "10px", direction: t("Dir")}}>
                {removeLoading ? (
                  <Loader />
                ) : (
                  items?.map((item, index) => (
                    <div className="product-details" key={index + 1}>
                      <div className="left">
                        <img
                          src={
                            item?.variant?.color
                              ? item.variant.color.image
                              : item.image
                          }
                          style={{width: "50px", height: "50px"}}
                        />
                        <div style={{padding: "5px 20px"}}>
                          <p
                            style={{
                              margin: "0",
                              padding: "0",
                              fontSize: "12px",
                              textAlign: "start"
                            }}
                          >
                            {item.name[t("lang")]} -{" "}
                            {item?.variant?.hardness?.name[t("lang")] || ""} -{" "}
                            {item?.variant?.color?.name || ""}
                          </p>
                          <p
                            style={{
                              margin: "0",
                              padding: "0",
                              fontSize: "12px",
                              textAlign: "start"
                            }}
                          >
                            {`${t("Price")}: ${item.price} ${t(currency)}`}
                          </p>
                          <p
                            style={{
                              margin: "0",
                              padding: "0",
                              fontSize: "12px",
                              textAlign: "start"
                            }}
                          >
                            {t("Quantity")}: {item.quantity}
                          </p>
                        </div>
                      </div>
                      <div className="right">
                        <div
                          style={{
                            marginBottom: "15px",
                            textDecorationLine: "underline",
                            color: "red",
                            fontSize: "0.8em"
                          }}
                          onClick={() => handleRemove(item, index)}
                        >
                          {t("Remove")}
                        </div>
                        <div className="manage-quantity">
                          <HiOutlineMinusCircle
                            className="plus-minus-circle"
                            style={{color: "var(--Orange-color)"}}
                            onClick={() => manageQuantity(item, index, "-")}
                          />
                          <div
                            className={`quantity ${
                              incrementLoading || decrementLoading
                                ? "rotate"
                                : ""
                            }`}
                          >
                            {item.quantity}
                          </div>
                          <HiOutlinePlusCircle
                            className={`plus-minus-circle ${
                              items?.reduce(
                                (acc, item) => acc + item.quantity,
                                0
                              ) === 1
                                ? "selected"
                                : ""
                            }`}
                            style={{color: "var(--green-color)"}}
                            onClick={() => manageQuantity(item, index, "+")}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </Card>

              <p
                style={{
                  color: "var(--Orange-color)",
                  margin: "5px",
                  padding: "5px",
                  direction: t("Dir"),
                  textAlign: "center",
                  fontSize: "1.2em"
                }}
              >
                {t("Address_Details")}
              </p>
              <Card style={{marginTop: "5px", padding: "5px"}}>
                <Form.Label
                  htmlFor="full-name"
                  style={{direction: t("Dir"), textAlign: "start"}}
                >
                  {t("Full_Name")}{" "}
                  <span style={{color: "red"}}>* {t("Required")}</span>
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    direction: t("Dir"),
                    textAlign: "start"
                  }}
                  type="text"
                  value={fullName}
                  onChange={(e) => handleName(e.target.value)}
                ></Form.Control>

                <Form.Label
                  htmlFor="mobile"
                  style={{direction: t("Dir"), textAlign: "start"}}
                >
                  {t("Mobile_Number")}{" "}
                  <span style={{color: "red"}}>* {t("Required")}</span>
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    direction: t("Dir"),
                    textAlign: "start"
                  }}
                  type="tel"
                  value={mobile}
                  onChange={(e) => handleMobile(e.target.value)}
                ></Form.Control>

                <Form.Label
                  htmlFor="mobile"
                  style={{direction: t("Dir"), textAlign: "start"}}
                >
                  {t("Another_Mobile_Number")}
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    direction: t("Dir"),
                    textAlign: "start"
                  }}
                  type="tel"
                  value={anotherMobile}
                  onChange={(e) => handleAnotherMobile(e.target.value)}
                ></Form.Control>

                <Form.Label
                  htmlFor="address"
                  style={{direction: t("Dir"), textAlign: "start"}}
                >
                  {t("Address")}{" "}
                  <span style={{color: "red"}}>* {t("Required")}</span>
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    direction: t("Dir"),
                    textAlign: "start"
                  }}
                  as="textarea" // Use the textarea element
                  rows={2} // Specify the number of rows to display
                  type="text"
                  value={address}
                  onChange={(e) => handleAddress(e.target.value)}
                ></Form.Control>
                <div
                  style={{
                    margin: "0",
                    padding: "0",
                    display: "flex",
                    direction: t("Dir")
                  }}
                >
                  <div
                    style={{
                      margin: "0",
                      padding: "3px",
                      direction: t("Dir"),
                      textAlign: "start",
                      width: "50%"
                    }}
                  >
                    <Form.Label htmlFor="city" style={{}}>
                      {t("City")}
                    </Form.Label>
                    <Form.Control
                      style={{border: "1px solid grey"}}
                      type="text"
                      value={city}
                      onChange={(e) => handleCity(e.target.value)}
                    ></Form.Control>
                  </div>
                  <div
                    style={{
                      margin: "0",
                      padding: "3px",
                      direction: t("Dir"),
                      textAlign: "start",
                      width: "50%"
                    }}
                  >
                    <Form.Label htmlFor="region" style={{}}>
                      {t("Region")}
                    </Form.Label>
                    <Form.Control
                      style={{
                        border: "1px solid grey",
                        textAlign: "start",
                        direction: t("Dir")
                      }}
                      type="text"
                      value={region}
                      onChange={(e) => handleRegion(e.target.value)}
                    ></Form.Control>
                  </div>
                </div>

                {/* <Form.Label
                  htmlFor="address"
                  style={{direction: t("Dir"), textAlign: "start"}}
                >
                  {t("Email")}{" "}
                  <span style={{color: "var(--Primary)"}}>
                    {" "}
                    {t("Email_note")}
                  </span>
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    direction: t("Dir"),
                    textAlign: "start"
                  }}
                  type="email"
                  value={email}
                  onChange={(e) => handleEmail(e.target.value)}
                ></Form.Control> */}

                <Form.Label
                  htmlFor="notes"
                  style={{direction: t("Dir"), textAlign: "start"}}
                >
                  {t("Notes")}
                </Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid grey",
                    direction: t("Dir"),
                    textAlign: "start"
                  }}
                  type="text"
                  value={note}
                  onChange={(e) => handleNote(e.target.value)}
                ></Form.Control>
              </Card>

              <p
                style={{
                  color: "var(--Orange-color)",
                  margin: "5px",
                  padding: "5px",
                  direction: t("Dir"),
                  textAlign: "center",
                  fontSize: "1.2em"
                }}
              >
                {t("Pay_With")}
              </p>
              <Card style={{margin: "0"}}>
                <div
                  className="payment-methods"
                  style={{direction: t("Dir"), textAlign: "start"}}
                >
                  <div
                    style={{
                      paddingTop: "8px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center"
                    }}
                  >
                    <RadioButton
                      //   label="Option 1"
                      //   name="options"
                      //   value="option1"
                      //   checked={selectedOption === "option1"}
                      //   onChange={handleOptionChange}
                      checked={true}
                    />
                  </div>
                  <div style={{padding: "5px"}}>{t("Cash_On_Delivery")}</div>
                </div>
              </Card>
              <p
                style={{
                  color: "var(--Orange-color)",
                  margin: "5px",
                  padding: "5px",
                  direction: t("Dir"),
                  textAlign: "center",
                  fontSize: "1.2em"
                }}
              >
                {t("Discount_Code")}
              </p>
              <Card style={{margin: "5px 0"}}>
                <div
                  style={{
                    display: "flex",
                    direction: t("Dir"),
                    alignItems: "center"
                  }}
                >
                  <Form.Control
                    style={{
                      margin: "5px",
                      border: "1px solid grey",
                      textAlign: "start",
                      direction: t("Dir")
                    }}
                    placeholder={t("Coupon_Code")}
                    type="text"
                    value={coupon}
                    onChange={(e) => handleCoupon(e.target.value)}
                  ></Form.Control>
                  <Button
                    disabled={coupon === ""}
                    className=" m-1 rounded"
                    onClick={() => handleUseCoupon()}
                  >
                    {t("Apply")}
                  </Button>
                </div>
              </Card>

              <p
                style={{
                  color: "var(--Orange-color)",
                  margin: "5px",
                  padding: "5px",
                  direction: t("Dir"),
                  textAlign: "center",
                  fontSize: "1.2em"
                }}
              >
                {t("Payment_Summary")}
              </p>
              <Card
                style={{margin: "0", marginBottom: "5rem", direction: t("Dir")}}
              >
                <div className="payment-details">
                  <p
                    style={{
                      margin: "0",
                      padding: "0",
                      fontSize: "12px"
                    }}
                  >
                    {t("Subtotal")}
                  </p>
                  <p style={{margin: "0", padding: "0", fontSize: "12px"}}>
                    {items?.reduce(
                      (acc, item) => acc + item.quantity * item.price,
                      0
                    )}
                    {/* {t(currency)} */}
                    {/* {items[0]?.quantity * items[0]?.price} */}
                  </p>
                </div>
                <div className="payment-details">
                  <p style={{margin: "0", padding: "0", fontSize: "12px"}}>
                    {t("Delivery_Fee")}
                  </p>
                  {siteMeta.freeShippingFromItemX <=
                  items?.reduce((acc, item) => acc + item.quantity, 0) ? (
                    <p style={{margin: "0", padding: "0", fontSize: "12px"}}>
                      {/* {t(currency)}{" "} */}
                      {t("Free")}{" "}
                      <span style={{textDecorationLine: "line-through"}}>
                        {t(currency)} {siteMeta.shippingFee[currency]}
                      </span>
                    </p>
                  ) : (
                    <p style={{margin: "0", padding: "0", fontSize: "12px"}}>
                      {t(currency)} {siteMeta.shippingFee[currency]}
                    </p>
                  )}
                </div>
                {couponValue > 0 && (
                  <div className="payment-details">
                    <p style={{margin: "0", padding: "0", fontSize: "12px"}}>
                      {t("Coupon_Code")} ({coupon.toUpperCase()})
                    </p>
                    <p style={{margin: "0", padding: "0", fontSize: "12px"}}>
                      {t(currency)} {-1 * couponValue}
                    </p>
                  </div>
                )}
                <div className="payment-details">
                  <p
                    style={{
                      color: "black",
                      margin: "0",
                      fontSize: "12px",
                      padding: "0"
                    }}
                  >
                    {t("Total")}
                  </p>
                  <p
                    style={{
                      color: "black",
                      margin: "0",
                      fontSize: "12px",
                      padding: "0"
                    }}
                  >
                    {t(currency)}{" "}
                    <span>
                      {items?.reduce(
                        (acc, item) => acc + item.quantity * item.price,
                        0
                      ) +
                        (siteMeta.freeShippingFromItemX <=
                        items?.reduce((acc, item) => acc + item.quantity, 0)
                          ? 0
                          : siteMeta.shippingFee[currency]) -
                        couponValue}
                      {/* {items[0]?.quantity * items[0]?.price - couponValue} */}
                    </span>
                  </p>
                </div>
              </Card>
            </>
          ) : (
            ""
          ))}

        {status === "preparation" && <SPSPreparation />}

        {status === "shipped" && <SPSPreparation />}
      </div>

      {status === "cart" &&
        (loading ? (
          <Loader />
        ) : items?.length > 0 ? (
          <div className="place-order-button">
            <Button
              id="purchaseButton"
              disabled={
                fullName === "" ||
                mobile === "" ||
                address === "" ||
                loading ||
                mobile.length < 11
              }
              className="rounded"
              variant="outline-info"
              style={{width: "100%"}}
              onClick={() => handleCheckout()}
            >
              {t("Place_Order")}
            </Button>
          </div>
        ) : (
          <div style={{textAlign: "center", marginTop: "100px"}}>
            <h4>{t("No_Items_In_Your_Cart")}</h4>
            <p
              style={{
                textDecorationLine: "underline",
                fontSize: "1.2em",
                color: "var(--Primary)"
              }}
              onClick={() => history.push({pathname: "/"})}
            >
              {t("Back_For_Shopping")}
            </p>
          </div>
        ))}
    </div>
  )
}

export default CheckoutScreen
