import {
  UPDATE_STOCK_FAIL,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_SUCCESS,
  GET_STOCK_FAIL,
  GET_STOCK_REQUEST,
  GET_STOCK_SUCCESS
} from "../constants/spsStockConstant"

export const spsStockGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STOCK_REQUEST:
      return {loading: true}

    case GET_STOCK_SUCCESS:
      return {loading: false, store_stock_list: action.payload}

    case GET_STOCK_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsStockUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_STOCK_REQUEST:
      return {loading: true}

    case UPDATE_STOCK_SUCCESS:
      return {loading: false}

    case UPDATE_STOCK_FAIL:
      return {loading: false}

    default:
      return state
  }
}
