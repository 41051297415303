import React from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts"

const TimeChart = ({data}) => {
  return (
    <LineChart width={800} height={600} data={data?.current}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="_id.week" />

      <YAxis />
      <Tooltip />
      <Legend />

      {/* <Line
        type="monotone"
        dataKey="totalNetItems"
        stroke="#8884d8"
        activeDot={{r: 8}}
      /> */}
      <Line type="monotone" dataKey="difference" stroke="#82ca9d" />
      {/* <Line type="monotone" dataKey="totalOrders" stroke="#E633FF" /> */}
      <Line type="monotone" dataKey="totalAds" stroke="#ff7300" />
      <Line type="monotone" dataKey="soldItems" stroke="#7DF8F4" />
      {/* <Line type="monotone" dataKey="totalReturnedLoss" stroke="red" /> */}
    </LineChart>
  )
}

export default TimeChart
