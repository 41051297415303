import {
  INVESTMENT_CALCULATE_FAIL,
  INVESTMENT_CALCULATE_REQUEST,
  INVESTMENT_CALCULATE_SUCCESS
} from "../constants/investmentConstants"

export const investmentsReducer = (state = {}, action) => {
  switch (action.type) {
    case INVESTMENT_CALCULATE_REQUEST:
      return {loading: true}

    case INVESTMENT_CALCULATE_SUCCESS:
      return {loading: false, investments: action.payload}

    case INVESTMENT_CALCULATE_FAIL:
      return {loading: false, error: action.payload}

    default:
      return state
  }
}
