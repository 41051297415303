import axios from "axios"
import {toastMessages} from "./toastMessages"
import {
  ANALYSIS_GET_FAIL,
  ANALYSIS_GET_REQUEST,
  ANALYSIS_GET_SUCCESS
} from "../constants/spsAnalysisConstants"

const base_url = process.env.REACT_APP_API_BASE_URL

export const getAnalysis = (filter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ANALYSIS_GET_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const {data} = await axios.get(`${base_url}/sps-analysis${filter}`, config)
    dispatch({
      type: ANALYSIS_GET_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch(toastMessages(0, error.response.data.message))
    dispatch({
      type: ANALYSIS_GET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.response.data.message
    })
  }
}
