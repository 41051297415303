import axios from "axios"
import {toastMessages} from "./toastMessages"

import {
  GET_STOCK_FAIL,
  GET_STOCK_REQUEST,
  GET_STOCK_SUCCESS,
  UPDATE_STOCK_FAIL,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_SUCCESS
} from "../constants/spsStockConstant"
import {getStocksLogs} from "./spsStockLogActions"

const base_url = process.env.REACT_APP_API_BASE_URL

export const getStocks = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_STOCK_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const {data} = await axios.get(`${base_url}/sps-stock${filters}`, config)
    dispatch(getStocksLogs({}))
    dispatch({
      type: GET_STOCK_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_STOCK_FAIL
    })
    dispatch(toastMessages(0, error.response.data.message))
  }
}

export const updateStocks = (payload) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_STOCK_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.post(`${base_url}/sps-stock`, payload, config)

    dispatch({
      type: UPDATE_STOCK_SUCCESS
    })
    dispatch(getStocksLogs(""))
  } catch (error) {
    dispatch({
      type: UPDATE_STOCK_FAIL
    })
    dispatch(toastMessages(0, error.response))
  }
}
