import {ORDER_STATUS} from "../constants/spsOrderStatusConstant"

export const spsOrderStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_STATUS:
      return {status: action.payload}

    default:
      return state
  }
}
