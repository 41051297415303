import {
  CREATE_ORDER_FAIL,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS
} from "../constants/spsOrderCreateConstant"

export const spsOrderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
      return {loading: true}

    case CREATE_ORDER_SUCCESS:
      return {loading: false}

    case CREATE_ORDER_FAIL:
      return {loading: false}

    default:
      return state
  }
}
