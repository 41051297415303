export const SET_ROLE_MODAL = "SET_ROLE_MODAL"
export const RESET_ROLE_MODAL = "RESET_ROLE_MODAL"

export const SET_ADD_TO_SERVICE_MODAL = "SET_ADD_TO_SERVICE_MODAL"
export const RESET_ADD_TO_SERVICE_MODAL = "RESET_ADD_TO_SERVICE_MODAL"

export const SET_UPDATE_SERVANT_SERVICE_MODAL =
  "SET_UPDATE_SERVANT_SERVICE_MODAL"
export const RESET_UPDATE_SERVANT_SERVICE_MODAL =
  "RESET_UPDATE_SERVANT_SERVICE_MODAL"

export const SET_ADD_UPDATE_USER_MODAL = "SET_ADD_UPDATE_USER_MODAL"
export const RESET_ADD_UPDATE_USER_MODAL = "RESET_ADD_UPDATE_USER_MODAL"
