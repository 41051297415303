import {
  SLREPORT_FAIL,
  SLREPORT_REQUEST,
  SLREPORT_SUCCESS
} from "../constants/SLReportConstants"

export const SLReportReducer = (state = {}, action) => {
  switch (action.type) {
    case SLREPORT_REQUEST:
      return {...state, loading: true}

    case SLREPORT_SUCCESS:
      return {...state, loading: false, SLReportsLists: action.payload}

    case SLREPORT_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}
