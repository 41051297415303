import {
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  PRODUCT_BUILD,
  PRODUCT_BUILD_MORE,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS
} from "../constants/spsProductConstant"

export const spsProductCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return {loading: true}

    case CREATE_PRODUCT_SUCCESS:
      return {loading: false}

    case CREATE_PRODUCT_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsProductUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_REQUEST:
      return {loading: true}

    case UPDATE_PRODUCT_SUCCESS:
      return {loading: false}

    case UPDATE_PRODUCT_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsProductGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_REQUEST:
      return {loading: true}

    case GET_PRODUCT_SUCCESS:
      return {loading: false, store_product_list: action.payload}

    case GET_PRODUCT_FAIL:
      return {loading: false}

    default:
      return state
  }
}

export const spsProductBuildReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_BUILD:
      return {product_details: action.payload}

    default:
      return state
  }
}

export const spsProductBuildMoreReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_BUILD_MORE:
      return {product_more_details: action.payload}

    default:
      return state
  }
}
