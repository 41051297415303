import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {PRODUCT_BUILD} from "../../constants/spsProductConstant"
import "./SPSCreateProduct.scss"
const SPSCreateProductCatchy = ({catchyX}) => {
  const dispatch = useDispatch()

  const dynamicKey = catchyX
  const spsProductBuild = useSelector((state) => state.spsProductBuild)
  const {product_details} = spsProductBuild

  useEffect(() => {}, [dispatch])

  const handleCatchy = (catchNo, para1, para2, parameterValue) => {
    let newProductDetails = JSON.parse(JSON.stringify(product_details || {}))
    if (!newProductDetails[catchNo]) {
      newProductDetails[catchNo] = {}
    }

    if (!newProductDetails[catchNo][para1] && para2) {
      newProductDetails[catchNo][para1] = {}
    }

    if (para2) newProductDetails[catchNo][para1][para2] = parameterValue
    else newProductDetails[catchNo][para1] = parameterValue

    dispatch({type: PRODUCT_BUILD, payload: newProductDetails})
  }

  return (
    <div>
      <h4>{catchyX}</h4>
      <div className="section">
        <Form.Control
          placeholder="En title"
          className="frm"
          type="text"
          defaultValue={product_details?.[dynamicKey]?.title?.en || ""}
          onChange={(e) => handleCatchy(catchyX, "title", "en", e.target.value)}
        ></Form.Control>
        <Form.Control
          placeholder="Ar title"
          className="frm"
          type="text"
          defaultValue={product_details?.[dynamicKey]?.title?.ar || ""}
          onChange={(e) => handleCatchy(catchyX, "title", "ar", e.target.value)}
        ></Form.Control>
      </div>
      <div className="section">
        <Form.Control
          placeholder="En product desc. 1"
          className="frm"
          type="text"
          defaultValue={
            product_details?.[dynamicKey]?.Product_Description1?.en || ""
          }
          onChange={(e) =>
            handleCatchy(catchyX, "Product_Description1", "en", e.target.value)
          }
        ></Form.Control>
        <Form.Control
          placeholder="Ar product desc. 1"
          className="frm"
          type="text"
          defaultValue={
            product_details?.[dynamicKey]?.Product_Description1?.ar || ""
          }
          onChange={(e) =>
            handleCatchy(catchyX, "Product_Description1", "ar", e.target.value)
          }
        ></Form.Control>
      </div>
      <div className="section">
        <Form.Control
          placeholder="En product desc. 2"
          className="frm"
          type="text"
          defaultValue={
            product_details?.[dynamicKey]?.Product_Description2?.en || ""
          }
          onChange={(e) =>
            handleCatchy(catchyX, "Product_Description2", "en", e.target.value)
          }
        ></Form.Control>
        <Form.Control
          placeholder="Ar product desc. 2"
          className="frm"
          type="text"
          defaultValue={
            product_details?.[dynamicKey]?.Product_Description2?.ar || ""
          }
          onChange={(e) =>
            handleCatchy(catchyX, "Product_Description2", "ar", e.target.value)
          }
        ></Form.Control>
      </div>
      <div className="section">
        <Form.Control
          placeholder="image"
          className="frm"
          type="text"
          defaultValue={product_details?.[dynamicKey]?.image || ""}
          onChange={(e) => handleCatchy(catchyX, "image", "", e.target.value)}
        ></Form.Control>
        <img
          src={product_details?.[dynamicKey]?.image}
          style={{margin: "5px", width: "20%"}}
        />
      </div>
      <hr className="line" />
    </div>
  )
}

export default SPSCreateProductCatchy
