export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST"
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS"
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL"

export const PRODUCT_GET_REQUEST = "PRODUCT_GET_REQUEST"
export const PRODUCT_GET_SUCCESS = "PRODUCT_GET_SUCCESS"
export const PRODUCT_GET_FAIL = "PRODUCT_GET_FAIL"

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST"
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS"
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL"

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST"
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS"
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL"
