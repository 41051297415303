import {
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS
} from "../constants/spsOrderUpdateConstant"

export const spsOrderUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDER_REQUEST:
      return {loading: true}

    case UPDATE_ORDER_SUCCESS:
      return {loading: false}

    case UPDATE_ORDER_FAIL:
      return {loading: false}

    default:
      return state
  }
}
