import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Button, Navbar, Card, Form} from "react-bootstrap"
import {PRODUCT_BUILD} from "../../constants/spsProductConstant"
import "./SPSCreateProduct.scss"
import {getStocks, updateStocks} from "../../actions/spsStockActions"
import Loader from "../Loader"
import {HiOutlinePlusCircle, HiOutlineMinusCircle} from "react-icons/hi"
import {createProduct} from "../../actions/spsProductsCreateActions"
import {updateProduct} from "../../actions/spsProductsUpdateActions"

const options = ["reeshsleep"]

const SPSStoreStocksDetails = () => {
  const dispatch = useDispatch()

  const spsStockGet = useSelector((state) => state.spsStockGet)
  const {store_stock_list, loading} = spsStockGet

  useEffect(() => {}, [dispatch])

  // Initialize state to store quantities and costs for each product
  const [productValues, setProductValues] = useState({})

  const handleChange = (id, field, value) => {
    // Update the quantity/cost for the specific product
    setProductValues((prevValues) => ({
      ...prevValues,
      [id]: {
        ...prevValues[id],
        [field]: Number(value)
      }
    }))
  }

  const handleUpdate = (id) => {
    // Update the quantity/cost for the specific product
    const payload = {
      productId: id,
      cost: productValues[id].cost,
      quantity: productValues[id].quantity
    }
    dispatch(updateStocks(payload))
    setProductValues((prevValues) => {
      // Clone the previous values
      const updatedValues = {...prevValues}
      // Delete the product with the specified ID
      delete updatedValues[id]
      return updatedValues
    })
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        store_stock_list?.map((stock, index) => (
          <Card key={index} style={{margin: "5px", padding: "5px"}}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <img
                className="cart-image"
                src={stock.image}
                alt="this is car image"
              />
              <div>
                <p style={{margin: "0"}}>{stock.name}</p>
                <p style={{margin: "0"}}>productId: {stock.productId}</p>
                <p style={{margin: "0"}}>Cost: {Math.round(stock.cost)} LE</p>
                <h4>
                  Update Stock:{" "}
                  <span style={{fontSize: "2rem"}}>{stock.quantity}</span>{" "}
                  Pieces
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <input
                      type="number"
                      placeholder="Quantity"
                      value={productValues[stock?.productId]?.quantity || ""}
                      onChange={(e) =>
                        handleChange(
                          stock?.productId,
                          "quantity",
                          e.target.value
                        )
                      }
                      style={{margin: "5px"}}
                    />
                    <input
                      type="number"
                      placeholder="Cost"
                      value={productValues[stock?.productId]?.cost || ""}
                      onChange={(e) =>
                        handleChange(stock?.productId, "cost", e.target.value)
                      }
                      style={{margin: "5px"}}
                    />
                  </div>
                  <div>
                    <Button
                      disabled={
                        productValues[stock?.productId]?.quantity &&
                        productValues[stock?.productId]?.cost &&
                        productValues[stock?.productId]?.quantity !== 0 &&
                        productValues[stock?.productId]?.cost !== 0
                          ? false
                          : true
                      }
                      size="sm"
                      style={{borderRadius: "5px"}}
                      onClick={() => handleUpdate(stock?.productId)}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))
      )}
    </div>
  )
}

export default SPSStoreStocksDetails
