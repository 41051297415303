import {createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import {composeWithDevTools} from "redux-devtools-extension"

import {
  userLoginReducer,
  userForgetPasswordReducer,
  userChangePasswordReducer,
  userChangePhoneOTPReducer,
  userChangePhoneReducer,
  userRegisterReducer,
  userDetailsReducer,
  clientDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  findToAttendReducer,
  userAddReducer,
  userRequestCredentialsReducer
} from "./reducers/userReducers"

import {
  servantInListMyReducer,
  servantInListReducer
} from "./reducers/servantInReducers"

import {errorAlertReducer} from "./reducers/errorReducers"
import {authModalReducer} from "./reducers/authModalReducers"
import {announceReducer} from "./reducers/announceReducers"

import {successAlertReducer} from "./reducers/successReducers"
import {slideRotationReducer} from "./reducers/slideRotationReducers"
import {
  servedByDeleteReducer,
  servedByReducer
} from "./reducers/servedByReducers"
import {gotoLocationReducer} from "./reducers/gotoLocationReducers"
import {authScreenReducer} from "./reducers/authScreenReducers"
import {logReducer} from "./reducers/logReducers"
import {
  userFollowUpUpdateReducer,
  userServiceMetaReducer
} from "./reducers/userServiceMetaReducers"
import {adminReducer} from "./reducers/adminReducers"
import {
  addToServiceModalReducer,
  add_updateUserModalReducer,
  roleModalReducer,
  updateServantServiceModalReducer
} from "./reducers/modalsReducers"
import {servicesListReducer} from "./reducers/servicesReducers"
import {
  downloadExpenseReducer,
  getExpensesReducer,
  postExpenseReducer
} from "./reducers/expenseReducers"
import {announcementReducer} from "./reducers/announcementReducers"
import {storeReducer} from "./reducers/storeReducers"
import {
  orderCreateReducer,
  orderDeleteReducer,
  orderGetReducer,
  orderUpdateReducer
} from "./reducers/orderReducers"

import {
  productCreateReducer,
  productDeleteReducer,
  productGetReducer,
  productUpdateReducer
} from "./reducers/productReducers"
import {
  MCQCreateReducer,
  MCQDeleteReducer,
  MCQGetReducer,
  MCQUpdateReducer
} from "./reducers/MCQReducers"
import {
  bibleMCQDeleteReducer,
  bibleMCQUpdateReducer
} from "./reducers/bibleMCQReducers"

import {
  bookCreateReducer,
  booksGetReducer,
  bookUpdateReducer
} from "./reducers/booksReducers"
import {
  serviceBookCreateReducer,
  serviceBooksGetReducer,
  serviceBookUpdateReducer
} from "./reducers/serviceBooksReducers"
import {usersFiltersReducer} from "./reducers/setUsersFiltersReducers"
import {
  shababEventRegisterReducer,
  spsOrderCreateReducer
} from "./reducers/spsOrderCreateReducer"
import {
  spsMyOrderGetReducer,
  spsOrderGetReducer
} from "./reducers/spsOrderGetReducer"
import {spsOrderUpdateReducer} from "./reducers/spsOrderUpdateReducer"
import {
  spsItemCreateReducer,
  spsItemDecrementReducer,
  spsItemDeleteReducer,
  spsItemIncrementReducer
} from "./reducers/spsItemReducer"
import {spsCartItemsGetReducer} from "./reducers/spsCartReducer"
import {analysisGetReducer} from "./reducers/spsAnalysisReducers"
import {spsOrderStatusReducer} from "./reducers/spsOrderStatusReducer"
import {
  spsProductBuildMoreReducer,
  spsProductBuildReducer,
  spsProductCreateReducer,
  spsProductGetReducer,
  spsProductUpdateReducer
} from "./reducers/spsProductReducer"
import {
  spsStockGetReducer,
  spsStockUpdateReducer
} from "./reducers/spsStockReducer"
import {
  spsStockLogGetReducer,
  spsStockLogUpdateReducer
} from "./reducers/spsStockLogReducer"
import {investmentsReducer} from "./reducers/investmentReducers"
import {SLReportReducer} from "./reducers/SLReportReducers"
import {setVariantsReducer} from "./reducers/variantsReducers"
import {ticketReducer} from "./reducers/ticketReducers"

const reducer = combineReducers({
  errorAlert: errorAlertReducer,
  successAlert: successAlertReducer,

  announce: announceReducer,
  authScreen: authScreenReducer,
  gotoLocation: gotoLocationReducer,

  servedBy: servedByReducer,
  servedByDelete: servedByDeleteReducer,

  authModal: authModalReducer,

  findToAttend: findToAttendReducer,
  userLogin: userLoginReducer,
  userForgetPassword: userForgetPasswordReducer,
  userChangePassword: userChangePasswordReducer,
  userChangePhoneOTP: userChangePhoneOTPReducer,
  userChangePhone: userChangePhoneReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  clientDetails: clientDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  slideRotation: slideRotationReducer,
  servantInList: servantInListReducer,
  servantInListMy: servantInListMyReducer,
  log: logReducer,
  userServiceMeta: userServiceMetaReducer,
  admin: adminReducer,
  roleModal: roleModalReducer,
  addToServiceModal: addToServiceModalReducer,
  add_updateUserModal: add_updateUserModalReducer,
  UpdateServantServiceModal: updateServantServiceModalReducer,
  servicesList: servicesListReducer,
  getExpenses: getExpensesReducer,
  postExpense: postExpenseReducer,
  downloadExpense: downloadExpenseReducer,
  announcement: announcementReducer,
  store: storeReducer,
  orderCreate: orderCreateReducer,
  orderGet: orderGetReducer,
  orderUpdate: orderUpdateReducer,
  orderDelete: orderDeleteReducer,
  productCreate: productCreateReducer,
  productGet: productGetReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  bibleMCQUpdate: bibleMCQUpdateReducer,
  bibleMCQDelete: bibleMCQDeleteReducer,
  MCQCreate: MCQCreateReducer,
  MCQGet: MCQGetReducer,
  MCQUpdate: MCQUpdateReducer,
  MCQDelete: MCQDeleteReducer,
  booksGet: booksGetReducer,
  serviceBookCreate: serviceBookCreateReducer,
  serviceBooksGet: serviceBooksGetReducer,
  serviceBookUpdate: serviceBookUpdateReducer,
  userAdd: userAddReducer,
  usersFilters: usersFiltersReducer,
  userFollowUpUpdate: userFollowUpUpdateReducer,
  userRequestCredentials: userRequestCredentialsReducer,
  spsOrderCreate: spsOrderCreateReducer,
  spsOrderGet: spsOrderGetReducer,
  spsMyOrderGet: spsMyOrderGetReducer,
  spsOrderUpdate: spsOrderUpdateReducer,
  spsItemCreate: spsItemCreateReducer,
  spsItemIncrement: spsItemIncrementReducer,
  spsItemDecrement: spsItemDecrementReducer,
  spsItemDelete: spsItemDeleteReducer,
  spsCartItemsGet: spsCartItemsGetReducer,
  analysisGet: analysisGetReducer,
  spsOrderStatus: spsOrderStatusReducer,
  spsProductCreate: spsProductCreateReducer,
  spsProductUpdate: spsProductUpdateReducer,
  spsProductBuild: spsProductBuildReducer,
  spsProductBuildMore: spsProductBuildMoreReducer,
  spsProductGet: spsProductGetReducer,
  ticket: ticketReducer,
  spsStockGet: spsStockGetReducer,
  spsStockUpdate: spsStockUpdateReducer,
  spsStockLogGet: spsStockLogGetReducer,
  spsStockLogUpdate: spsStockLogUpdateReducer,
  invests: investmentsReducer,
  SLReport: SLReportReducer,
  setVariants: setVariantsReducer
})

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

const initialState = {
  // cart: {
  //   cartItems: cartItemsFromStorage,
  //   shippingAddress: shippingAddressFromStorage
  // },
  userLogin: {userInfo: userInfoFromStorage}
}

localStorage.setItem("authModal", false)

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
