import axios from "axios"
import {LOG_FAIL, LOG_REQUEST, LOG_SUCCESS} from "../constants/logConstants"
import {toastMessages} from "./toastMessages"
import {
  INVESTMENT_CALCULATE_FAIL,
  INVESTMENT_CALCULATE_REQUEST,
  INVESTMENT_CALCULATE_SUCCESS
} from "../constants/investmentConstants"

const base_url = process.env.REACT_APP_API_BASE_URL

function isDivisionResultInteger(x, y) {
  if (y === 0 || x === 0) {
    return false // Division by zero is not allowed
  }
  return Number.isInteger(x / y)
}

export const calculateInvestments =
  (investments) => async (dispatch, getState) => {
    console.log(investments)
    try {
      dispatch({
        type: INVESTMENT_CALCULATE_REQUEST
      })

      let result = []
      let groupValue = investments
      for (let z = 0; z < groupValue.length; z++)
        for (let j = 0; j < groupValue[z].paymentPlans.length; j++) {
          result = []
          for (
            let i = 0;
            i <= groupValue[z].paymentPlans[j].numberOfYears * 12;
            i++
          ) {
            let DP =
              i == 0
                ? (groupValue[z].paymentPlans[j].DP / 100) * groupValue[z].total
                : result[i - 1].DP +
                  result[i - 1].instalment +
                  result[i - 1].interest
            let instalment = isDivisionResultInteger(
              i,
              groupValue[z].paymentPlans[j].instalmentEachXMonthes
            )
              ? groupValue[z].paymentPlans[j].instalmentValue
              : 0
            let dof3a = isDivisionResultInteger(
              i,
              groupValue[z].paymentPlans[j].dof3aEachXMonthes
            )
              ? groupValue[z].paymentPlans[j].dof3aValue
              : 0
            let interest =
              i == 0
                ? 0
                : (result[i - 1].DP +
                    result[i - 1].instalment +
                    result[i - 1].dof3a +
                    result[i - 1].interest) *
                  (groupValue[z].bankInterest / (100 * 12))

            let Bank = DP + instalment + dof3a + interest
            let totalInvest =
              (groupValue[z].paymentPlans[j].DP * groupValue[z].total) / 100 +
              instalment +
              dof3a
            result.forEach(function (record) {
              totalInvest += record.instalment
              totalInvest += record.dof3a
            })

            result.push({
              year: i == 0 ? 0 : Math.floor((i - 1) / 12) + 1,
              month: isDivisionResultInteger(i, 12) ? 12 : i % 12,
              DP: DP,
              instalment: instalment,
              dof3a: dof3a,
              interest: interest,
              Bank: Bank,
              Investements: totalInvest,
              Diff: Bank - totalInvest
            })
          }
          let groupedByYear = result.reduce((acc, item) => {
            // If the year doesn't exist in the accumulator, create a new array for it
            if (!acc[item.year]) {
              acc[item.year] = []
            }

            // Add the current item to the array for its year
            acc[item.year].push(item)

            return acc
          }, {})

          groupValue[z].paymentPlans[j].grouped = groupedByYear
        }

      console.log(groupValue)

      dispatch({
        type: INVESTMENT_CALCULATE_SUCCESS,
        payload: groupValue
      })
    } catch (error) {
      dispatch(toastMessages(0, error))
      dispatch({
        type: INVESTMENT_CALCULATE_FAIL,
        payload: error
      })
    }
  }
