import React from "react"
import "./Sidebar.scss"

const Sidebar = () => {
  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li>
            <a href="#analysis">Analysis</a>
          </li>
          <li>
            <a href="#create-product">Create Product</a>
          </li>
          <li>
            <a href="#stock">Stocks</a>
          </li>
          {/* <li>
            <a href="#investments">Investments</a>
          </li> */}
        </ul>
      </nav>
    </div>
  )
}

export default Sidebar
