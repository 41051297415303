import axios from "axios"

import {toastMessages} from "./toastMessages"
import {
  TICKET_FAIL,
  TICKET_REQUEST,
  TICKET_SUCCESS
} from "../constants/ticketConstants"
import {GET_ORDER_SUCCESS} from "../constants/spsOrderGetConstant"

const base_url = process.env.REACT_APP_API_BASE_URL

export const ticketPost =
  (payload, orderIndex, mainList) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_REQUEST
      })

      const {
        userLogin: {userInfo}
      } = getState()

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const {data} = await axios.post(
        `${base_url}/sps-tickets`,
        payload,
        config
      )

      dispatch({
        type: TICKET_SUCCESS,
        payload: data
      })
      mainList[orderIndex] = data
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: mainList
      })

      dispatch(toastMessages(1, "ticket Added Successfully"))
    } catch (error) {
      dispatch({
        type: TICKET_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
      })
    }
  }

export const ticketGet = (payload) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const {data} = await axios.get(`${base_url}/sps-tickets`, payload, config)

    dispatch({
      type: TICKET_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: TICKET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message
    })
  }
}
