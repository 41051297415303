export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST"
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS"
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL"

export const ORDER_GET_REQUEST = "ORDER_GET_REQUEST"
export const ORDER_GET_SUCCESS = "ORDER_GET_SUCCESS"
export const ORDER_GET_FAIL = "ORDER_GET_FAIL"

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST"
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS"
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL"

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST"
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS"
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL"
